import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    HEADING: {
      color: 'grey.9',
      marginBlockStart: 3,
      paddingBlockStart: '0px',
      paddingBlockEnd: '0px',
    } as TSO,

    CONTENT: {
      p: {
        paddingBlockStart: '0px',
        paddingBlockEnd: '0px',
        marginBlockStart: 0,
        marginBlockEnd: '0px',
        fontSize: 1,
      },
    } as TSO,
  };

  return Styles;
};
