import { FC } from 'react';
import { Block, Button, Grid, H4 } from '#ui';
import { useStyles } from './horizontaltile.styles';
import type { HorizontalTileProps as Props } from './types';
import RichText from '@components/RichText';

const HorizontalTile: FC<Props> = ({
  heading,
  tileCopy,
  secondaryCopy,
  checkBulletStyle,
  cta,
}) => {
  const Styles = useStyles();

  return (
    <Grid as="article" sx={Styles.ROOT}>
      <Block sx={Styles.CONTENT} col={[null, '1/-1', '1/7']}>
        <H4 as="h3" sx={Styles.HEADING}>
          {heading}
        </H4>
        {!!tileCopy?.json && (
          <RichText
            {...tileCopy}
            checkBulletStyle={!!checkBulletStyle}
            smallCheckBullets
          />
        )}
      </Block>
      {!!tileCopy?.json && (
        <Block sx={Styles.CONTENT_TWO} col={[null, '1/-1', '7/-4']}>
          <RichText
            {...secondaryCopy}
            checkBulletStyle={!!checkBulletStyle}
            smallCheckBullets
          />
        </Block>
      )}
      {!!cta?.destination && (
        <Block sx={Styles.BUTTON_CONTAINER} col={[null, '1/-1', '-4/-1']}>
          <Button href={cta.destination}>{cta.ctaCopy}</Button>
        </Block>
      )}
    </Grid>
  );
};

export default HorizontalTile;
