/**
 * @file - Creates a function that returns the correct spacing
 * value for components based on page spacing and order of
 * content.
 *
 * This context (global state) is used to share the spacing values between all
 * the components in the `main` html block and is able to be set in
 * Contentful. The getSpacing() hook is used at the root element of
 * the component to create the spacing on the marginBlockStart.
 *
 * @file: src/consntants/page.ts
 * Spacing is used to create spacing between components. Boolean values are used
 * for header and footer spacing in Contentful. The default value is set in page constants.
 * The order is used to determine which components will need spacing values. Order is passed
 * to the getSpacing() hook in each page component.
 */

import { useContext } from 'react';
import { SpacingContext } from '@lib/hooks';

const getSpacing = (order: number): number | string => {
  const { pageSpacing, heroSpacing } = useContext(SpacingContext);

  if (!heroSpacing && order === 0) {
    return '0px';
  }

  return pageSpacing;
};

export { getSpacing };
