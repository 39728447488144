import { Grid } from '#ui';
import { useStyles } from './simple.styles';
import { propsValidator } from '#utils';

import type { FunctionComponent } from 'react';
import type { SimpleFooterProps as Props } from './types';

const SimpleFooter: FunctionComponent<Props> = ({
  colorMode,
  subfooterLinksCollection,
  ...entry
}) => {
  const valid = propsValidator(entry, { colorMode });

  if (!valid) return null;

  const links =
    subfooterLinksCollection?.items.length && subfooterLinksCollection.items;
  const Styles = useStyles({ colorMode });

  return (
    <footer sx={Styles.ROOT}>
      <Grid sx={Styles.FOOTER_CONTAINER} wrap noPadding>
        <ul sx={Styles.LINKS_LIST}>
          <li key="copyright">&copy; rev.com</li>
          {!!links &&
            links?.map((link) => {
              if (link?.destination) {
                return (
                  <li key={link.sys.id}>
                    <a href={link.destination}>{link?.ctaCopy}</a>
                  </li>
                );
              }
            })}
        </ul>
      </Grid>
    </footer>
  );
};

export default SimpleFooter;
