import type { CardStyles } from './types';
import type { ThemeUICSSObject as TSO } from 'theme-ui';

export const useStyles = (props = {} as CardStyles): typeof Styles => {
  const Styles = {
    ROOT: {
      contain: 'layout',
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
    } as TSO,

    CARD: {
      display: 'flex',
      position: 'relative',
      maxInlineSize: '392px',
      flexDirection: 'column',
      flexFlow: 'column wrap',
      flexGrow: 1,
      overflowX: 'auto',
      backgroundColor: 'surface.primary',
      gridColumn: props.col && props.col,
      borderRadius: 2,
      border: 'solid 1px',
      borderColor: 'grey.3',

      '&:focus-within': {
        outlineWidth: '1px',
        outlineColor: 'focus',
        outlineStyle: 'solid',
      },
    } as TSO,
    IMAGE: {
      maxInlineSize: '100%',
      maxBlockSize: '230px',
      objectFit: 'center',
    } as TSO,

    CONTENT: {
      padding: 3,
      paddingBlock: 4,
      lineHeight: '1.6rem',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,

      '& ul, ol': {
        paddingInlineStart: 'calc(16px + 1.4ch)',
        marginBlockStart: 2,
        lineHeight: '1.5rem',
      },
    } as TSO,

    LINK: {
      inlineSize: ['100%', '100%'],
      '&::after': {
        inlineSize: '100%',
        blockSize: '100%',
        position: 'absolute',
        top: '0px',
        insetBlockStart: '0px',
        left: '0px',
        insetInlineStart: '0px',
        zIndex: 'surface',
        content: '""',
        cursor: 'pointer',
      },
    } as TSO,

    BUTTON_CONTAINER: {
      marginBlockStart: 3,
      flexGrow: 1,
      display: 'flex',
      alignItems: 'flex-end',
    } as TSO,

    SECONDARY_CTA_CONTAINER: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: ['fit-content', 8],
    } as TSO,
    
    SECONDARY_CTA: {
      width: 'fit-content',
      marginInline: 'auto',
    } as TSO,
  };

  return Styles;
};
