import Group from './Group';
import NavCta from './CTA';
import PaneButton from './PaneButton';
import { Icon } from '#ui';
import { propsValidator, createId } from '#utils';
import { useStyles } from '../nav.styles';

import type { FC, SyntheticEvent } from 'react';
import type { PrimaryProps as Props, PrimaryContent } from '../types';
import type { NavStyles } from '../nav.styles';

const PrimaryPane: FC<Props> = ({
  heading,
  contentCollection,
  open,
  secondaryState,
  areas,
  currentPage,
  setFocus,
  backFunc,
  ...entry
}) => {
  const valid = propsValidator(entry, { heading });

  if (!valid) return null;

  // -- State
  const [secondary, setSecondary] = secondaryState;

  // -- Local Variables
  const Styles: NavStyles = useStyles({});
  const links: PrimaryContent = contentCollection.items || [];
  const id: string = createId(heading!);

  // -- State Actions
  const toggleSecondaryMenu = (e: SyntheticEvent<HTMLButtonElement>): void => {
    const section = e.currentTarget.dataset.focus || '';

    if (section === secondary) e.currentTarget.blur();

    areas[section].ref.firstElementChild!.scrollTo(0, 0);

    window.requestAnimationFrame(() => {
      setSecondary(state => section === state ? '' : section);
    });
  };

  return (
    <section
      sx={Styles.PANE}
      id={`${id}-pane`}
      className={open ? '--open' : ''}
      role="tabpanel"
      aria-label={heading!}
      aria-hidden={!open}
      data-area={heading}
    >
      <div
        sx={Styles.PANE_SCROLL_AREA}
        tabIndex={-1}
        data-scroll
      >
        <div sx={Styles.PANE_CONTENT}>
          <h2 className='global-sr'>{heading}</h2>
          {!!links.length && links.map(link => {
            switch (link.__typename) {
              case 'Cta': {
                return (
                  <NavCta
                    {...link}
                    currentPage={currentPage}
                    parent={heading}
                    key={link.sys.id}
                  />
                );
              }
              case 'NavGroup': {
                return (
                  <Group
                    {...link}
                    currentPage={currentPage}
                    parent={heading}
                    toggleFunc={toggleSecondaryMenu}
                    key={link.sys.id}
                  />
                )
              }
              case 'SecondaryNavPane': {
                return (
                  <PaneButton
                    {...link}
                    parent={heading}
                    selected={secondary === link.heading}
                    key={link.sys.id}
                    onClick={toggleSecondaryMenu}
                    onKeyPress={setFocus}
                  />
                );
              }
              default: null;
            };
          })}
          <button
            sx={Styles.BACK_BUTTON}
            type="button"
            aria-controls={`${id}-pane`}
            data-related={heading}
            data-name={`${heading} Back`}
            onClick={() => backFunc('')}
            onKeyPress={setFocus}
          >
            <Icon as="Left Arrow" />
            <span>Back to main menu</span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default PrimaryPane;
