/**
 * @file purely for styling control, providing components a cohesive
 * look and consistent styling when rendered on the page. 
 * 
 * `as` prop determines the element used for the root node
 * `wrap` prop creates a wrapper instead of a grid, allowing for 
 *    other display options while maintaining consistent max-width
 * `subwrap` prop creates the smaller child wrapper
 * `subgrid` prop creates the smaller child grid
 * `noPadding` prop removes built in padding
 * `children` prop is the content of the component
 * `sx` prop adds additional styling
 */

import { memo, FC } from 'react';

import type { ThemeUIStyleObject } from 'theme-ui';
import type { GridProps as Props } from './types';

const Grid: FC<Props> = ({
  children,
  subwrap = false,
  as = 'div',
  wrap = false,
  subgrid = false,
  noPadding = false,
  ...props
}) => {
  if (!children) return null;

  const _subwrap = typeof subwrap === 'boolean' ? {} : subwrap;

  const rootSX: ThemeUIStyleObject = {
    display: !wrap && 'grid',
    maxInlineSize: '1440px',
    paddingInlineStart: !noPadding && [10, '0px', '0px'],
    paddingInlineEnd: !noPadding && [10, '0px', '0px'],
    m: 'auto',
    grid: !wrap && [
      'auto/repeat(8,1fr)',
      'auto/repeat(10,1fr)',
      'auto/repeat(16,1fr)',
    ],
    columnGap: !wrap && [10, 11],
    '& > *': !wrap && { gridColumn: '1/-1' },
  };

  const subwrapSX: ThemeUIStyleObject = {
    ..._subwrap,
    'body &': { gridColumn: ['1/-1', '2/-2'] },
  };

  const subgridSX: ThemeUIStyleObject = {
    display: 'grid',
    grid: ['auto/repeat(8,1fr)', null, 'auto/repeat(14, 1fr)'],
    columnGap: [10, 11],
    'body &': { gridColumn: ['1/-1', '2/-2'] },
    '& > *': { gridColumn: '1/-1' },
  };

  const Child = () =>
    subwrap ? (
      <div sx={subwrapSX}>{children}</div>
    ) : subgrid ? (
      <div sx={subgridSX}>{children}</div>
    ) : (
      <>{children}</>
    );

  switch (as) {
    case 'section':
      return (
        <section sx={rootSX} {...props}>
          <Child />
        </section>
      );
    case 'main':
      return (
        <main sx={rootSX} {...props}>
          <Child />
        </main>
      );
    case 'header':
      return (
        <header sx={rootSX} {...props}>
          <Child />
        </header>
      );
    case 'footer':
      return (
        <footer sx={rootSX} {...props}>
          <Child />
        </footer>
      );
    case 'nav':
      return (
        <nav sx={rootSX} {...props}>
          <Child />
        </nav>
      );
    case 'aside':
      return (
        <aside sx={rootSX} {...props}>
          <Child />
        </aside>
      );
    case 'article':
      return (
        <article sx={rootSX} {...props}>
          <Child />
        </article>
      );
    case 'figure':
      return (
        <figure sx={rootSX} {...props}>
          <Child />
        </figure>
      );
    default:
      return (
        <div sx={rootSX} {...props}>
          <Child />
        </div>
      );
  }
};

export default memo(Grid);
