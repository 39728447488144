export const useStyles = (smallIcons: boolean): typeof Styles => {
  const backgroundSize = smallIcons ? '16px' : '20px';

  const Styles = {
    ROOT: {
      listStyle: 'none',
      marginInlineStart: '0px !important',
      li: {
        backgroundImage: 'url(/cms-assets/check.svg)',
        backgroundRepeat: 'no-repeat',
        // Center checkmark background vertically in 1st line of text
        // Assumes current line-height of 1.8; could differ depending on
        // containing element's styles, but 1.8 is most common; making this
        // respect the current line-height dynamically is non-trivial until
        // 'lh' units become standard in all browsers...
        backgroundPosition: `0px calc((1.8em - ${backgroundSize}) / 2)`,
        paddingInlineStart: '2rem',
        backgroundSize,
      },
    },
  };

  return Styles;
};
