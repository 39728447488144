import { useStyles } from './styles';
import { LegalOrderedList as Props } from './types.d';

const LegalOrderedList = ({children}: Props): JSX.Element => {
  const Styles = useStyles();
  return (
    <ol sx={Styles.ROOT}>
      {children}
    </ol>
  );
};
export default LegalOrderedList;
