import { AccountValues } from '@lib/constants';
import type {
  LoggedInString,
  UserInformation,
  LoggedInState,
} from '@components/Navigation';

export const getRole = async (): Promise<LoggedInState> => {
  let endPoint = '/app/userInformation';
  const env = process.env.NODE_ENV;

  if (env === 'development') {
    endPoint = '/user.json';
  }

  try {
    const response = await fetch(endPoint, {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      mode: 'no-cors',
    });

    if (response.ok) {
      const body: UserInformation = await response.json();

      // get the keys from the object
      const keys = Object.keys(body);
      // filter the ones that are true
      const roles: LoggedInString[] = keys.filter(
        (key): key is LoggedInString => {
          if (typeof body[key as keyof UserInformation] === 'boolean') {
            return body[key as keyof UserInformation] as boolean;
          }
          return false;
        }
      );

      // none of the roles are true, they are not logged in
      if (roles.length === 0) return null;

      const accumulator = 'isRevver' as LoggedInString;
      const role = roles.reduce(
        (prev: LoggedInString, curr: LoggedInString) => {
          // if the role is more important; set it as the role
          if (AccountValues[curr] > AccountValues[prev]) {
            return curr;
          }
          // the prev role was more important
          return prev;
        },
        accumulator as LoggedInString
      );

      return role;
    } else {
      return null;
    }
  } catch (error) {
    // as of now the endpoint triggers this catch block, so
    // we return null here to follow the pattern. Suspect that
    // when we are able to test in a real network environment
    // we can do a check on status above and just handle error here
    return null;
  }
};
