export const Seo = `#graphql
  fragment Seo on Seo {
    ...Entry
    title
    description
    keywords
    canonical
    schema
    noFollow
    noIndex
    image {
      ...Asset
    }
  }
`;
