import RichText from '@components/RichText';
import type { FunctionComponent } from 'react';
import { Link, H4 } from '#ui';
import type { IconProps as Props } from './types';

const Tile: FunctionComponent<Props> = ({
  heading,
  simpleIcon,
  tileCopy,
  cta,
}) => (
  <section
    aria-label={heading || undefined}
    sx={{
      display: 'flex',
      marginBlockStart: 5,
      marginBlockEnd: 3,
      flex: ['100%', '50%', '33.33%'],
      flexFlow: 'column wrap',
      paddingInline: 0,
    }}
  >
    {simpleIcon?.url && (
      <img
        sx={{ size: '3rem' }}
        src={simpleIcon.url}
        alt={simpleIcon.description || ''}
        loading="lazy"
      />
    )}
    {heading && (
      <H4
        as="h3"
        sx={{
          textAlign: 'left',
          marginBlockStart: 2,
        }}
      >
        {heading}
      </H4>
    )}
    {tileCopy && (
      <div sx={{ flexGrow: 1, '& ul': { marginInlineStart: 4 } }}>
        <RichText {...tileCopy} />
      </div>
    )}
    {cta?.destination && (
      <Link
        withIcon
        href={cta.destination}
        sx={cta?.ctaCopy ? { paddingBlockStart: 3 } : {}}
        openInNewTab={cta.openInNewTab}
      >
        {cta.ctaCopy}
      </Link>
    )}
  </section>
);

export default Tile;
