/**
 * @file all buttons in the project are passed through this component
 * to determine the correct button treatment based on the passed props.
 * Content is then passed on to the appropriate button component
 */

import { useEffect, useState } from 'react';
import GraphicButtonCta from '@components/GraphicButtonCta';
import { ModalButton } from '@components/Modal';
import { Button as ThemeButton } from '#ui';

import type { FC } from 'react';
import type { GraphicButtonProps, ModalCtaProps, PolyUnion } from './types';
import { getLinkClickHandlerWithTracking } from '#utils';

const isButton = (props: PolyUnion): props is GraphicButtonProps => {
  return !!props.appleButton || !!props.googleButton;
};

const isModal = (props: PolyUnion): props is ModalCtaProps => {
  return !!props.modalContent;
};

const AppButton: FC<PolyUnion> = (props) => {
  const [path, setPath] = useState<string | null>(null);

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  if (isModal(props)) {
    return (
      <ModalButton
        componentStyle={props?.componentStyle}
        contrast={props?.contrast}
        modalContent={props?.modalContent}
        ctaCopy={props?.ctaCopy}
        // does not currently support onClick
      />
    );
  }

  const handleClick = getLinkClickHandlerWithTracking(
    props.analyticsEvent,
    props.ctaCopy,
    path,
    props.destination
  );

  if (isButton(props)) {
    return (
      <GraphicButtonCta
        appleButton={props?.appleButton}
        googleButton={props?.googleButton}
        destination={props?.destination}
        ctaCopy={props?.ctaCopy}
        onClick={handleClick}
      />
    );
  }

  const { destination, children, ctaCopy, ...rest } = props;

  return (
    <ThemeButton
      href={destination || ''}
      onClick={handleClick}
      {...rest}
      type={undefined}
      color={undefined}
    >
      {children || (ctaCopy as string)}
    </ThemeButton>
  );
};

export default AppButton;
