import { forwardRef } from 'react';
import { Themed, ThemeUIStyleObject } from 'theme-ui';
import { useContrast } from '../../../lib/hooks'

import type { TextProps as Props, TextRef as Ref } from './types';

const Text = forwardRef<Ref, Props>(
  (
    {
      col,
      as = 'p',
      contrast = 'White',
      children,
      ...props
    },
    ref
  ) => {
    if (!children) return null;

    const { text: color } = useContrast(contrast);

    const sx: ThemeUIStyleObject = {
      color,
      'body &': col && { gridColumn: col },
    };

    return (
      <Themed.p
        sx={sx}
        as={as}
        ref={ref}
        {...props}
      >
        {children}
      </Themed.p>
    )
  }
);

export default Text;
