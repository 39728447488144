export const ToggleContentSection = `#graphql
  fragment ToggleContentSection on ToggleContentSection {
    ...Entry
    firstTabDefault
    firstToggleButtonText
    secondToggleButtonText
    firstTopContent{
      ...InPageBanner
    }
    secondTopContent{
      ...InPageBanner
    }
    firstMiddleContentCollection(limit:4){
      items{
        ...Tile
      }
    }
    secondMiddleContentCollection(limit:4){
      items{
        ...Tile
      }
    }
    firstBottomContent{
      ...Tile
    }
    secondBottomContent{
      ...Tile
    }
  }
`;
