import { forwardRef } from 'react';
import { FillIcon, LineIcon, IconMap } from './icons';
import { includes } from '#utils';
import { ColorHex } from '#constants';

import type { ThemeUICSSObject } from 'theme-ui';
import type { IconProps as Props, IconRef as Ref } from './types';

const Icon = forwardRef<Ref, Props>(({
  as = 'Sparkle',
  color = 'Default',
  ...props
}, ref) => {
  const svgColor: string = color === 'Default' ? 'currentColor' : ColorHex[color];

  const sx: ThemeUICSSObject = {
    display: 'block',
    fill: includes(FillIcon, as) ? svgColor : 'none',
    stroke: includes(LineIcon, as) ? svgColor : 'none',
    strokeWidth: includes(LineIcon, as) ? 2 : undefined,
    strokeLinecap: includes(LineIcon, as) ? 'round' : undefined,
    strokeLinejoin: includes(LineIcon, as) ? 'round' : undefined,
    pointerEvents: 'none',
    inlineSize: '26px'
  };

  return (
    <svg
      className="IconCore"
      viewBox="0 0 26 26"
      aria-hidden="true"
      sx={sx}
      ref={ref}
      {...props}
    >
      {IconMap[as]}
    </svg>
  );
});

export default Icon;
