import { useContrast } from '@lib/hooks';
import type { FC } from 'react';
import { Themed } from 'theme-ui';

import type { StarRatingProps as Props } from './types';

const StarRating: FC<Props> = ({ rating, ratingCopy, backgroundColor, card, link }) => {
  const ratingAsNumber = Number(rating);
  if (isNaN(ratingAsNumber)) return null;

  const percent = card ? (((ratingAsNumber / 5) * 100) -3) : (ratingAsNumber / 5) * 100;

  const starColor =
    !backgroundColor || backgroundColor === 'White'
      ? '#f8c41c'
      : backgroundColor === 'Violet' || backgroundColor === 'Black'
      ? '#9C95FE'
      : '#2E1A69';

  const copy = ratingCopy ? `${ratingAsNumber.toFixed(1)} ${ratingCopy}`: `${ratingAsNumber.toFixed(1)} star rating from over 6,000 reviews`;
  const { text } = useContrast(backgroundColor);
  const linkStars = link === null ? true : link;

  return (
    <>
    {!linkStars ? 
      <Themed.p
        sx={{
          inlineSize: 'fit-content',
          color: starColor,
          marginBlockStart: card ? 0 : '0px',
          marginBlockEnd: card ? 0 : null,
          letterSpacing: card ? 7 : null,
          '::before': {
            position: 'absolute',
            content: '"★★★★★"',
            background: `linear-gradient(90deg, ${starColor} ${percent}%, transparent ${percent}%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          },
        }}
      >
        ☆☆☆☆☆
        <span
          sx={{
            fontSize: 1,
            lineHeight: 1.8,
            paddingInlineStart: card ? '0px' : 0,
            opacity: 1,
            fontWeight: 'semiBold',
            whiteSpace: 'break-spaces',
            color: text,
            display: card ? 'block' : null,
            letterSpacing: 'heading',
          }}
        >{copy}</span>
      </Themed.p>
      :
      <Themed.a
        href='/reviews'
        tabIndex={card ? -1 : 0}
        sx={{
          inlineSize: 'fit-content',
          color: starColor,
          marginBlockStart: card ? 0 : null,
          marginBlockEnd: card ? 0 : null,
          letterSpacing: card ? 7 : null,
          '::before': {
            position: 'absolute',
            content: '"★★★★★"',
            background: `linear-gradient(90deg, ${starColor} ${percent}%, transparent ${percent}%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          },
          '&:hover span':{
            opacity: 0.75,
          },
        }}
      >
        ☆☆☆☆☆
        <span
          sx={{
            paddingInlineStart: card ? '0px' : 0,
            opacity: 1,
            fontWeight: 'semiBold',
            transition: 'opacity 200ms ease-in-out',
            whiteSpace: 'break-spaces',
            color: text,
            display: card ? 'block' : null,
            letterSpacing: 'heading',
          }}
        >{copy}</span>
      </Themed.a>
    }
    </>
  );
};

export default StarRating;
