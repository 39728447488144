import { useStyles } from './accordion.styles';
import { Themed } from 'theme-ui';
import { Link } from '../Link';
import RichText from '../../../components/RichText';

import type { FC } from 'react';
import type { AccordionProps as Props } from './types';

const Accordion: FC<Props> = ({
  heading,
  copy,
  cta,
  col,
  ...props
}) => {
  if (!heading || !copy) return null;

  const Styles = useStyles({ col });

  return (
    <details sx={Styles.ROOT} {...props}>
      <summary sx={Styles.HEADING}>
        <Themed.tr as="span">{heading}</Themed.tr>
      </summary>
      <div sx={Styles.CONTENT}>
        <div sx={Styles.TEXT}>
          <RichText {...copy} />
        </div>
        {cta?.destination && (
          <Link 
            href={cta.destination} 
            withIcon 
            sx={Styles.LINK}
            openInNewTab={cta.openInNewTab}
          >
            {cta.ctaCopy}
          </Link>
        )}
      </div>
    </details>
  );
};

export default Accordion;
