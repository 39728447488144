export const FindYourService = `#graphql
  fragment FindYourService on FindYourService {
    ...Entry
    heading
    superheading
    servicesCollection (limit: 4){
      items {
        ...Service
      }
    }
  }
`;
