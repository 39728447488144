import * as Fragments from '@lib/graphql';
import {
  ContentfulCta,
  ContentfulNavigation,
  ContentfulPrimaryNavPane,
  ContentfulQueryResult,
} from '@lib/types';
import { request } from '../request';

interface GetNextPrimaryLinkQueryResult extends ContentfulQueryResult {
  readonly navigation: {
    primaryLinksCollection: {
      items: (ContentfulPrimaryNavPane | ContentfulCta | undefined)[];
    };
  };
}

// For paginated request to avoid query complexity limit
const getNextPrimaryLink = async (navId: string, skip: number) => {
  const response = await request<GetNextPrimaryLinkQueryResult>(`#graphql
    query getNextPrimaryLink($preview: Boolean) {
      navigation(
        id: "${navId}"
        preview: $preview,
      ) {
        primaryLinksCollection(limit: 1, skip: ${skip}) {
          items {
            ...PrimaryNavPane
            ...Cta
          }
        }
      }
    }
    ${Fragments.Asset}
    ${Fragments.PrimaryNavPane}
    ${Fragments.SecondaryNavPane}
    ${Fragments.Cta}
    ${Fragments.Entry}
    ${Fragments.NavGroup}
    ${Fragments.Tile}
    ${Fragments.AnalyticsEvent}
    ${Fragments.ResponsiveMedia}
  `);

  if (response.errors) throw response.errors[0];

  return response.navigation.primaryLinksCollection.items[0];
};

interface GetNavigationQueryResult extends ContentfulQueryResult {
  readonly navigation: Omit<ContentfulNavigation, 'primaryLinksCollection'>;
}

export const getNavigation = async (
  navId: string
): Promise<ContentfulNavigation> => {
  const navigationPromise = request<GetNavigationQueryResult>(`#graphql
    query getNavigation($preview: Boolean) {
      navigation(
        id: "${navId}"
        preview: $preview,
      ) {
        ...Entry
        loggedOutCtasCollection(limit: 2) {
          items {
            ...Cta
          }
        }
        customerCtasCollection(limit: 2) {
          items {
            ...Cta
          }
        }
        adminCtasCollection(limit: 2) {
          items {
            ...Cta
          }
        }
        revverCtasCollection(limit: 2) {
          items {
            ...Cta
          }
        }
        sticky
        buttonCtas
      }
    }
    ${Fragments.Entry}
    ${Fragments.Cta}
    ${Fragments.AnalyticsEvent}
  `);

  const primaryLinkPromises = Array(5)
    .fill(null)
    .map((_, i) => getNextPrimaryLink(navId, i));

  const [response, ...primaryLinks] = await Promise.all([
    navigationPromise,
    ...primaryLinkPromises,
  ]);

  if (response.errors) throw response.errors[0];

  const isPopulatedPrimaryLink = (
    val: ContentfulPrimaryNavPane | ContentfulCta | undefined
  ): val is ContentfulPrimaryNavPane | ContentfulCta =>
    typeof val !== 'undefined';

  const filteredPrimaryLinks = primaryLinks.filter(isPopulatedPrimaryLink);

  return {
    ...response.navigation,
    primaryLinksCollection: {
      items: filteredPrimaryLinks,
    },
  };
};
