import useEmblaCarousel from 'embla-carousel-react';
import { useStyles } from './carousel.styles';
import { useEffect, useState, useCallback, Children } from 'react';
import { Grid, Block } from '#ui';
import { Progress, Prev, Next } from '.';

import type { CarouselProps as Props } from './types';
import type { FC } from 'react';

const Carousel: FC<Props> = ({
  children,
  options,
  col,
  wrap = false,
  noPadding = false,
  offset = false,
  full = false,
  ...props
}) => {
  if (!children) return null;

  const numChildren = Children.count(children);
  const Styles = useStyles({ full, offset, col, noPadding, wrap, numChildren });

  // initialize the carousel
  const [viewportRef, embla] = useEmblaCarousel(options);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<Array<number>>([]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <div sx={Styles.ROOT} {...props}>
      <div ref={viewportRef} sx={Styles.CAROUSEL_VISIBLE}>
        <Grid sx={Styles.TILE_CONTAINER_CAROUSEL_VISIBLE} noPadding>
          {children}
        </Grid>
      </div>
      <Grid sx={Styles.TILE_CONTAINER_NOCAROUSEL}>{children}</Grid>
      <Block
       col={['1/-1', '3/-3', '4/-4']}
       sx={Styles.CONTROLS}
       className='carousel-nav'
      >
        <Prev onClick={scrollPrev} enabled={prevBtnEnabled} />
        <div sx={Styles.CONTROL_NAV}>
          {scrollSnaps.map((_, index) => (
            <Progress
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
        <Next onClick={scrollNext} enabled={nextBtnEnabled} />
      </Block>
    </div>
  );
};
export default Carousel;
