export const Table = `#graphql
  fragment Table on Table {
    ...Entry
    heading
    columnHeading1
    columnHeading2
    columnHeading3
    rowsCollection (limit: 6){
      items{
        ...TableRow
      }
    }
  }
`;