/**
 * @file - Creates a function that provides an Error message
 * if the given prop(s) are falsy and provides a boolean for
 * the component to return null.
 */

import type { ContentfulBase } from '#types';

export type PropValidator = (
  entry: unknown,
  props: Record<string, unknown>
) => boolean;

export const propsValidator: PropValidator = (entry, props) => {
  const info = entry as ContentfulBase;
  const entries: [string, unknown][] = Object.entries(props);
  const type: string = info.__typename || 'unknown';
  const id: string = info.sys?.id || 'unknown';

  let valid = true;

  for (const [name, value] of entries) {
    const emptyArray: boolean = Array.isArray(value) && !value.length;
    const emptyString: boolean = typeof value === 'string' && !value.length;
    const emptyObject: boolean = typeof value === 'object'
      && value !== null
      && !Object.keys(value).length;

    const invalid: string = (emptyArray || emptyString || emptyObject)
      ? 'empty'
      : !value
      ? `${value}`
      : '';

    if (invalid) {
      valid &&= false;

      const statement =
        `\n\n\n%cThere was an error rendering ${type}%c\n\n` +
        `(Content entry ID: ${id})\n\n` +
        `> Component rendered as null because its '${name}' prop is ${invalid}\n\n` +
        'This is probably due to one of the following: \n' +
        '  1. The required field associated with this prop is empty (editors)\n' +
        '  2. The prop is not properly checked before being passed in (developers)\n\n\n\n';

      // eslint-disable-next-line no-console
      console.error(statement, 'color:white');
    }
  }

  return valid;
};