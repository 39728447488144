import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { Meta } from '#constants';

import type { FC } from 'react';
import type { HeadProps as Props } from './types';

const Head: FC<Props> = ({
  seo,
  enableZendeskChatWidget,
  pageTitle,
  noIndex,
}) => {
  const { title, description, keywords, canonical, schema, image } = seo || {};
  const router = useRouter();
  let defaultCanonical = router.asPath;

  // If the current route is a split test variation, then `router.asPath` will
  // differ between build time and client-side due to the rewrite; extract the
  // original (pre-rewrite) path from the actual path to use as the default
  // canonical; prevents rendering duplicate canonical link tags and ensures
  // that Segment tracks the same page path the user sees in the browser
  // (Segment uses canonical URL to determine page path for events)
  if (defaultCanonical.startsWith('/_variation')) {
    defaultCanonical = defaultCanonical
      .replace(/^\/_variation\//, '/')
      .replace(/\/[^/]+$/, '');
  }

  const domain = process.env.NEXT_PUBLIC_URL ?? 'http://localhost:3000';

  return (
    <NextHead>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,viewport-fit=cover"
      />
      {noIndex && <meta name="robots" content="noindex" />}
      <title>{title || pageTitle || Meta.TITLE}</title>
      <meta name="description" content={description || Meta.DESCRIPTION} />

      {/* -- General site info --  */}

      <meta name="author" content={Meta.TITLE} />
      <link
        key="canonical"
        rel="canonical"
        href={`${domain}${canonical ?? defaultCanonical}`}
      />
      {/* We should remove this; Google does not use keywords. */}
      {keywords && <meta name="keywords" content={keywords} />}

      {/* -- Favicons -- */}

      <meta name="format-detection" content="telephone=no,email=no" />
      <link rel="apple-touch-icon" href="/cms-assets/apple-touch-icon.png" />
      <link rel="icon" type="image/svg+xml" href="/cms-assets/favicon.svg" />
      <link
        rel="mask-icon"
        href="/cms-assets/pinned-tab.svg"
        color={Meta.COLOR}
      />
      <link
        rel="mask-icon"
        href="/cms-assets/pinned-tab.svg"
        color="#fff"
        media="(prefers-color-schema:dark)"
      />

      {/* -- OG and Twitter cards -- */}

      <meta name="twitter:card" content={Meta.TWITTER_CARD || 'summary'} />
      <meta name="twitter:title" content={title || Meta.TITLE || 'Rev'} />
      <meta name="twitter:site" content={Meta.TWITTER_SITE || '@rev'} />
      <meta
        name="twitter:url"
        content={`${domain}${canonical ?? defaultCanonical}`}
      />
      <meta
        name="twitter:description"
        content={description || Meta.DESCRIPTION}
      />

      <meta
        name="twitter:image"
        content={image?.url || `${domain}/cms-assets/Rev-Logo.jpg`}
      ></meta>
      <meta name="twitter:image:alt" content={title || Meta.TITLE} />
      <meta
        property="og:image"
        content={image?.url || `${domain}/cms-assets/Rev-Logo.jpg`}
      ></meta>
      <meta
        property="og:image:secure_url"
        content={image?.url || `${domain}/cms-assets/Rev-Logo.jpg`}
      ></meta>
      <meta property="og:image:width" content={`${image?.width}` || '1200'} />
      <meta property="og:image:height" content={`${image?.height}` || '630'} />
      <meta
        property="og:image:type"
        content={image?.contentType || 'image/jpeg'}
      />
      <meta property="og:image:alt" content={title || Meta.TITLE} />

      <meta property="og:type" content={Meta.OG_TYPE} />
      <meta property="og:site_name" content={Meta.OG_SITE_NAME} />
      <meta property="og:title" content={title || Meta.TITLE} />
      <meta
        property="og:description"
        content={description || Meta.DESCRIPTION}
      />
      <meta
        property="og:url"
        content={`${domain}${canonical ?? defaultCanonical}`}
      />
      <meta property="fb:app_id" content={Meta.FB_APP_ID} />

      {/* -- Misc. scripts and links -- */}

      {/* -- Structured data -- */}
      {schema && (
        <script
          defer
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
      )}

      {/* -- Zendesk Chat Widget -- */}
      {enableZendeskChatWidget && (
        <script
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.ZENDESK_CHAT_WIDGET_KEY}`}
        />
      )}
    </NextHead>
  );
};

export default Head;
