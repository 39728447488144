import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    ROOT: {
      borderRadius: 2,
      border: 'solid 1px',
      borderColor: 'grey.3',
      backgroundColor: 'violet.2',
      paddingInlineEnd: [3, 4, 5],
      paddingInlineStart: [3, 4, 5],
      paddingBlockEnd: 4,
      paddingBlockStart: 4,
      gridAutoRows: 'min-content',
    } as TSO,

    CONTENT: {
      p: {
        marginBlockStart: 0,
        paddingInlineEnd: ['0px', 2],
      },
      '& *': {
        fontSize: 1,
        lineHeight: 1.5
      }
    } as TSO,
    CONTENT_TWO: {
      display: 'flex',
      alignContent: ['start', 'center'],
      justifyContent: ['start', 'center'],
      ul: {
        marginBlockStart: [4, null, '0px'],
        marginInlineStart: 4,
        display: 'flex',
        flexDirection: ['column', 'row'],
        flexWrap: 'wrap',
        blockSize: '100%',
        inlineSize: '100%',
        columnGap: 5,
        alignContent: ['start', 'center'],
        li: {
          minInlineSize: [null, '35%'],
          '&:first-of-type': { marginBlockStart: 0 }, 
        }
      },
      '& *': {
        fontSize: 1,
        lineHeight: 1.5
      }
    } as TSO,

    HEADING: {
      fontSize: [4, null],
      fontWeight: ['semiBold', 'semiBold'],
        paddingInlineEnd: ['0px', 2],
    } as TSO,

    BUTTON_CONTAINER: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: [null, 'center', 'flex-end'],
      minInlineSize: 'fit-content',
      marginBlockStart: [4, null, '0px'],
    } as TSO,
  };

  return Styles;
}; 