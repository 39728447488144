import { propsValidator } from '@lib/utils';
import { Link, H4 } from '#ui';
import RichText from '@components/RichText';
import ResponsiveMedia from '@components/ResponsiveMedia';
import { useStyles } from './resourcetile.styles';
import type { FC } from 'react';
import type { ResourceTileProps as Props } from './types';

const ResourceTile: FC<Props> = ({
  heading,
  cta,
  tileCopy,
  responsiveMedia,
  ...entry
}) => {
  const valid = propsValidator(entry, { heading });

  if (!valid) return null;

  const Styles = useStyles();

  return (
    <article aria-label={heading!}>
      {!!responsiveMedia?.desktopAsset?.url && (
        <ResponsiveMedia {...responsiveMedia} />
      )}
      <H4 as="h3" sx={Styles.HEADING}>
        {heading}
      </H4>
      {!!tileCopy?.json && (
        <div sx={Styles.CONTENT}>
          <RichText {...tileCopy} />
        </div>
      )}
      {cta?.destination && (
        <Link
          withIcon
          href={cta.destination}
          sx={cta?.ctaCopy ? { marginBlockStart: 0 } : undefined}
          openInNewTab={cta.openInNewTab}
        >
          {cta.ctaCopy}
        </Link>
      )}
    </article>
  );
};

export default ResourceTile;
