export const FeaturedAnimationSection = `#graphql
  fragment FeaturedAnimationSection on FeaturedAnimationSection {
    ...Entry
    heading
    superheadingList: superheading
    animationCopy: copy
    cta {
      ...Cta
    }
    image {
      ...ResponsiveMedia
    }
    animation {
      ...ResponsiveMedia
    }
  }
`;