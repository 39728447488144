import { useStyles } from './styles';
import { CheckmarkUnorderedList as Props } from './types';

const CheckmarkUnorderedList = ({
  children,
  smallIcons = false,
  ...props
}: Props): JSX.Element => {
  const Styles = useStyles(smallIcons);
  return (
    <ul sx={Styles.ROOT} {...props}>
      {children}
    </ul>
  );
};
export default CheckmarkUnorderedList;
