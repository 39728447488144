export const ColorName = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  WHITE: 'White',
  VIOLET: 'Violet',
  NEUTRAL: 'Neutral',
  BLACK: 'Black',
  LILAC: 'Lilac',
  TEAL: 'Teal',
  CHARTREUSE: 'Chartreuse',
  ERROR: 'Error',
  SUCCESS: 'Success',
  DEFAULT: 'Default',
  TEXT: 'Text',
  NEON_LILAC: 'NeonLilac',
  NEON_VIOLET: 'NeonViolet',
  NEON_BLUE: 'NeonBlue',
  GRADIENT: 'Gradient',
  TWITTER_BLUE: 'TwitterBlue',
  YELLOW_GRADIENT: 'YellowGradient',
  BLUE_GRADIENT: 'BlueGradient',
  LIGHT_LILAC: 'LightLilac',
  LIGHT_GREY: 'LightGrey',
} as const;

// * Case exception for properties
export const ColorHex = {
  Primary: '#2E1A69',
  Secondary: '#F4EBDB',
  Violet: '#2E1A69',
  White: '#FFF',
  Neutral: '#F4EBDB',
  Black: '#000',
  DarkGrey: '#3D3D3D',
  Lilac: '#9C95FE',
  LightGrey: '#FAFAFA',
  LightLilac: '#EBEAFF',
  Teal: '#5BC6A4',
  Chartreuse: '#DFFF00',
  Error: '#AD1D1D',
  Success: '#10AC61',
  Default: 'inherit',
  Text: '#292929',
  NeonLilac: '#6553FD',
  NeonViolet: '#7D45FF',
  NeonBlue: '#4507FF',
  Gradient: '#3F00FF',
  TwitterBlue: '#1DA1F2',
  YellowGradient: '#F5FFD4',
  BlueGradient: '#3F00FF',
  OtherNeutral: '#FCFAF6',
} as const;

// * Case exception for properties
export const ColorTheme = {
  Primary: 'primary',
  Secondary: 'secondary',
  Violet: 'primary',
  White: 'white',
  Neutral: 'secondary',
  Black: 'black',
  Lilac: 'accent.primary',
  Teal: 'accent.secondary',
  Chartreuse: 'accent.tertiary',
  Error: 'error',
  Success: 'success',
  Default: 'surface.primary',
  Text: 'text.primary',
} as const;

export type Color = keyof typeof ColorHex;
