import { useRef } from 'react';
import { useSanitizer } from '@lib/hooks';
import { useStyles } from './responsive.styles';
import ResponsiveMediaHelper from './ResponsiveMediaHelper';

import type { FC } from 'react';
import type { ResponsiveMediaProps as Props, ToggleVideo } from './types';
import type { ContentfulAsset } from '#types';

const ResponsiveMedia: FC<Props> = ({
  desktopAsset,
  mobileAsset,
  altText,
  closedCaptionsCollection,
  posterImage,
  wistiaVideoUrl: wistiaVideoID,
  youtubeId,
  vimeoId,
  vimeoHash,
  forceHeight,
  lazy = true,
  fit = 'contain',
  sizes = [100],
  showVideoControls: showVideoControlsProp = true,
  ...props
}) => {
  const $media = useRef<HTMLPictureElement>(null);
  const attrs = useSanitizer<Props>(props);
  const Styles = useStyles({ desktopAsset, mobileAsset, fit, forceHeight });
  const responsiveMediaHelper = new ResponsiveMediaHelper(
    desktopAsset,
    mobileAsset,
    wistiaVideoID,
    youtubeId
  );
  const url = responsiveMediaHelper.url;
  const contentType = responsiveMediaHelper.contentType;
  const alt: string = !altText || /_not_needed_/i.test(altText) ? '' : altText;

  // Default to true if null (i.e. not set in Contentful); Contentful defaults
  // this field to true for new entries; this keeps behavior consistent for
  // old entries w/ no value for the field
  const showVideoControls =
    showVideoControlsProp || showVideoControlsProp === null;

  // Video Asset
  if (responsiveMediaHelper.isVideo) {
    const captions: ContentfulAsset[] = closedCaptionsCollection?.items || [];

    if (!url) return null;

    const toggleVideo: ToggleVideo = ({ currentTarget }) => {
      if (currentTarget.paused) currentTarget.play();
      else currentTarget.pause();
    };

    return (
      <video
        controls={showVideoControls}
        autoPlay
        muted
        crossOrigin="anonymous"
        loop
        playsInline
        onClick={() => toggleVideo}
        poster={posterImage?.url || undefined}
        {...attrs}
      >
        <source src={url} type={contentType} />
        {!!captions.length &&
          captions.map(
            (caption) =>
              caption.url && (
                <track
                  label="English"
                  kind="subtitles"
                  srcLang="en"
                  src={caption.url}
                  default
                  key={caption.fileName}
                />
              )
          )}
      </video>
    );
  }

  // Wistia Video Asset
  if (responsiveMediaHelper.isWistiaVideo) {
    const content: string | null | undefined = wistiaVideoID;
    if (!content) return null;

    const wistiaUrl = new URL(
      `https://fast.wistia.net/embed/iframe/${wistiaVideoID}`
    );
    const params = wistiaUrl.searchParams;
    params.append('playerColor', '7D45FF');
    params.append('playsinline', 'false');
    params.append('autoPlay', 'true');
    params.append('muted', 'true');
    params.append('preload', 'false');
    params.append('endVideoBehavior', 'loop');

    if (!showVideoControls) {
      params.append('controlsVisibleOnLoad', 'false');
      params.append('playbar', 'false');
      params.append('settingsControl', 'false');
      params.append('volumeControl', 'false');
      params.append('fullscreenButton', 'false');
      params.append('playButton', 'false');
      params.append('smallPlayButton', 'false');
      params.append('playPauseNotifier', 'false');
    }

    return (
      <>
        <script
          src="https://fast.wistia.com/assets/external/E-v1.js"
          defer
        ></script>
        <div sx={Styles.VIDEO_WRAPPER}>
          <div
            className="wistia_responsive_wrapper"
            sx={Styles.VIDEO_SUBWRAPPER}
          >
            <iframe
              src={wistiaUrl.toString()}
              className="wistia_embed"
              name="wistia_embed"
              allowFullScreen
              sx={Styles.EMBED}
            ></iframe>
          </div>
        </div>
      </>
    );
  }

  // Vimeo Video Asset
  if (vimeoId) {
    const vimeoUrl = new URL(`https://player.vimeo.com/video/${vimeoId}`);
    const params = vimeoUrl.searchParams;
    params.append('autoplay', 'true');
    params.append('color', '7D45FF');
    params.append('muted', 'true');
    params.append('byline', 'false');
    params.append('pip', 'false');
    params.append('portrait', 'false');
    params.append('h', vimeoHash || '');

    if (!showVideoControls) {
      params.append('controls', 'false');
    }

    return (
      <div sx={Styles.VIDEO_WRAPPER}>
        <div sx={Styles.VIDEO_SUBWRAPPER}>
          <iframe
            src={vimeoUrl.toString()}
            width="100%"
            height="100%"
            frameBorder="0"
            title="vimeo_embed"
            allowFullScreen
            sx={Styles.EMBED}
          ></iframe>
        </div>
      </div>
    );
  }

  // YouTube Video Asset
  if (responsiveMediaHelper.isYouTubeVideo) {
    const youtubeUrl = new URL(`https://www.youtube.com/embed/${youtubeId}`);
    const params = youtubeUrl.searchParams;
    params.append('autoplay', '1');
    params.append('mute', '1');

    if (!showVideoControls) {
      params.append('controls', '0');
      params.append('disablekb', '1');
      params.append('fs', '0');
      params.append('modestbranding', '1');
      params.append('rel', '0');
    }

    return (
      <>
        <div sx={Styles.VIDEO_WRAPPER}>
          <div sx={Styles.VIDEO_SUBWRAPPER}>
            <div sx={Styles.EMBED}>
              <iframe
                src={youtubeUrl.toString()}
                frameBorder="0"
                allowFullScreen
                sx={Styles.VIDEO}
              ></iframe>
            </div>
          </div>
        </div>
      </>
    );
  }

  // Image Asset
  if (!url) return null;

  const imageMap = [
    ['mobile', 'webp'],
    ['desktop', 'webp'],
    ['mobile', ''],
    ['desktop', ''],
  ];

  const [mobileSize, desktopSize] = sizes;

  const sourceSizes: string = desktopSize
    ? `(max-width:768px) ${mobileSize}vw, ${desktopSize}vw`
    : `${mobileSize}vw`;

  return (
    <picture ref={$media} sx={Styles.IMAGE} data-responsive {...attrs}>
      {mobileAsset?.url && (
        <>
          {responsiveMediaHelper.isStaticImage ? (
            <>
              {imageMap.map(([size, type]) => (
                <source
                  media={
                    size === 'mobile'
                      ? '(max-width: 767px)'
                      : '(min-width: 768px)'
                  }
                  srcSet={responsiveMediaHelper.srcSet(size, type)}
                  type={type === 'webp' ? 'image/webp' : undefined}
                  sizes={sourceSizes}
                  key={`${size}${type}`}
                />
              ))}
            </>
          ) : (
            <>
              <source media="(max-width:768px)" srcSet={mobileAsset.url} />
              <source media="(min-width:769px)" srcSet={url} />
            </>
          )}
        </>
      )}
      {responsiveMediaHelper.isStaticImage && !mobileAsset?.url && (
        <source
          srcSet={responsiveMediaHelper.srcSet('all', 'webp')}
          type="image/webp"
          sizes={sourceSizes}
        />
      )}
      <img
        src={url}
        loading={lazy ? 'lazy' : 'eager'}
        srcSet={
          responsiveMediaHelper.isStaticImage
            ? responsiveMediaHelper.srcSet('all')
            : undefined
        }
        sizes={sourceSizes}
        alt={alt}
      />
    </picture>
  );
};

export default ResponsiveMedia;
