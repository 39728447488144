export const TileCarousel = `#graphql
  fragment TileCarousel on TileCarousel {
    ...Entry
    heading
    tilesCollection(limit: 6) {
      items {
        ...Tile
      }
    }
  }
`;
