import type { BadgeStyles } from './types';

const useStyles: BadgeStyles = () => ({
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: 0,
  boxShadow: 2,
  color: 'grey.9',
  cursor: 'pointer',
  display: 'flex',
  top: '10px',
  insetBlockStart: '10px',
  left: '35px',
  insetInlineStart: '35px',
  maxInlineSize: '85%',
  position: 'absolute',
  transition: 'border-radius ease 400ms',
  zIndex: 3,

  span: {
    display: 'inline-block',
    fontSize: 0,
    overflow: 'hidden',
    maxInlineSize: '0px',
    transition: 'all 200ms',
    whiteSpace: 'nowrap', 
  },

  '& svg': {
    blockSize: 2,
    borderRadius: 1,
    inlineSize: 2,

    '& g': {
      transform: 'scale(.7)',
      transformOrigin: 'center',
    },
  },

  '&.show-feature': {
    borderTopRightRadius: 7,
    borderBottomRightRadius: 7,
    borderStartEndRadius: 7,
    borderEndEndRadius:7,

    span: {
      maxInlineSize: 6,
      paddingInlineEnd: 1,
      paddingInlineStart: 0,
    }
  }
});

export { useStyles };
