export const TranslationPriceSection = `#graphql
  fragment TranslationPriceSection on TranslationPriceSection {
    ...Entry
    heading
    highlightedHeading
    firstColumnPrice
    firstCtaList: firstColumnLanguageCtasCollection{
      items{
        iconsCollection(limit: 3){
          items{
            desktopAsset {
              url
            }
          }
        }
        cta{
        	...Cta
        }
      }
    }
    secondColumnPrice
    secondCtaList: secondColumnLanguageCtasCollection{
      items{
        iconsCollection(limit: 3){
          items{
            desktopAsset {
              url
            }
          }
        }
        cta{
        	...Cta
        }
      }
    }
    thirdColumnPrice
    thirdCtaList: thirdColumnLanguageCtasCollection{
      items{
        iconsCollection(limit: 3){
          items{
            desktopAsset {
              url
            }
          }
        }
        cta{
        	...Cta
        }
      }
    }
    image {
      ...ResponsiveMedia
    }
  }
`;