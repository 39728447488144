import { Icon } from '#ui';
import NavCta from './CTA';
import Tile from '@components/Tile';
import { propsValidator, createId } from '#utils';
import { useStyles } from '../nav.styles';

import type { FC } from 'react';
import type { SecondaryProps as Props, SecondaryContent } from '../types';
import type { NavStyles, TSO } from '../nav.styles';

const SecondaryPane: FC<Props> = ({
  heading,
  contentCollection,
  anchoredCta,
  open,
  backTitle,
  currentPage,
  setFocus,
  backFunc,
  ...entry
}) => {
  const valid = propsValidator(entry, { heading });

  if (!valid) return null;

  // -- Local Variables
  const Styles: NavStyles = useStyles({ cta: anchoredCta?.destination });
  const style: TSO = { ...Styles.PANE, ...Styles.SECONDARY_PANE };
  const links: SecondaryContent = contentCollection.items || [];
  const id: string = createId(heading!);

  return (
    <section
      sx={style}
      id={`${id}-pane`}
      className={open ? '--open' : ''}
      role="tabpanel"
      aria-label={heading!}
      data-area={heading}
      aria-hidden={!open}
    >
      <div
        sx={Styles.PANE_SCROLL_AREA}
        tabIndex={-1}
        data-scroll
      >
        <div sx={Styles.PANE_CONTENT}>
          <h3 className='global-sr'>{heading}</h3>
          {!!links.length && links.map(link => {
            switch (link.__typename) {
              case 'Cta': {
                return (
                  <NavCta
                    {...link}
                    currentPage={currentPage}
                    parent={heading}
                    group
                    key={link.sys.id}
                  />
                );
              }
              case 'Tile': {
                if (link.variant !== 'Feature Tile') return null;

                const section: boolean = !link.cta && !link.description;

                return (
                  <Tile
                    {...link}
                    className={section ? 'nav__Section' : 'nav__Tile'}
                    parent={heading}
                    key={link.sys.id}
                  />
                );
              }
              default: null;
            };
          })}
          <button
            type="button" 
            sx={Styles.BACK_BUTTON}
            aria-controls={`${id}-pane`}
            data-name={`${heading} Back`}
            data-related={heading}
            tabIndex={-1}
            onKeyPress={setFocus}
            onClick={() => backFunc('')}
          >
            <Icon as="Left Arrow" />
            <span>Back to {backTitle}</span>
          </button>
        </div>
      </div>
      {anchoredCta?.destination && (
        <a
          sx={Styles.ANCHORED_CTA}
          href={anchoredCta.destination}
          data-related={heading}
        >
          <span>{anchoredCta.ctaCopy}</span>
          <Icon as="Right Arrow" />
        </a>
      )}
    </section>
  );
};

export default SecondaryPane;
