import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useStyles } from './couponBanner.styles';
import { getCookies } from '#utils';
import type { CouponBannerProps as Props } from './types';
import MarkdownText from '@components/MarkdownText';
import { propsValidator } from '#utils';

const CouponBanner: FC<Props> = ({ copy, ...entry }) => {
  const valid = propsValidator(entry, { copy });
  const [showCouponBanner, setShowCouponBanner] = useState<boolean>(false);
  const [navPosition, setNavPosition] = useState<string>('');
  const Styles = useStyles({ navPosition });

  if (!valid) return null;

  useEffect(() => {
    const cookiesArr = getCookies();
    const foundCouponCookie = cookiesArr.find((item) =>
      item.includes('ft_coupon')
    );
    if (foundCouponCookie) setShowCouponBanner(true);
  }, []);

  useEffect(() => {
    const nav = document.querySelector('#nav');
    const navStyles = window.getComputedStyle(nav!);
    setNavPosition(navStyles.getPropertyValue('position'));
  }, []);

  return showCouponBanner ? (
    <aside
      id="rev-site-coupon-banner"
      sx={Styles.ROOT}
      aria-label="Announcement"
    >
      <MarkdownText>{copy!}</MarkdownText>
    </aside>
  ) : null;
};

export default CouponBanner;
