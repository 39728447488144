export const appleBadge = (
  <svg viewBox="0 0 130 40">
    <title>Button for a Rev app in the App store</title>
    <path
      d="M110.1,0H7.5L5.5.2A7,7,0,0,0,3.6.8,6.1,6.1,0,0,0,2,2,6.1,6.1,0,0,0,.8,3.6,7,7,0,0,0,.2,5.5a15,15,0,0,0-.2,2v25a13.6,13.6,0,0,0,.2,2,7,7,0,0,0,.6,1.9A6.1,6.1,0,0,0,2,38a6.1,6.1,0,0,0,1.6,1.2,7,7,0,0,0,1.9.6l2,.2H112.1l2-.2a6,6,0,0,0,1.9-.6,6.6,6.6,0,0,0,1.7-1.2,9.1,9.1,0,0,0,1.1-1.6,7.3,7.3,0,0,0,.7-1.9c.1-.7.1-1.3.2-2V7.5c-.1-.6-.1-1.3-.2-2a7.3,7.3,0,0,0-.7-1.9A5.9,5.9,0,0,0,116,.8a6,6,0,0,0-1.9-.6l-2-.2h-2Z"
      fill="#a6a6a6"
    />
    <path d="M8.4,39.1H5.7A4.9,4.9,0,0,1,4,38.4a7.1,7.1,0,0,1-1.4-1,7.1,7.1,0,0,1-1-1.4,3.4,3.4,0,0,1-.5-1.7,11,11,0,0,1-.2-1.8V7.5a11,11,0,0,1,.2-1.8A3.4,3.4,0,0,1,1.6,4a7.1,7.1,0,0,1,1-1.4A7.1,7.1,0,0,1,4,1.6a3.4,3.4,0,0,1,1.7-.5A5.5,5.5,0,0,1,7.5.9H112.1a6.2,6.2,0,0,1,1.9.2,3.7,3.7,0,0,1,1.7.5A6.2,6.2,0,0,1,118.1,4a8.4,8.4,0,0,1,.5,1.7,11.1,11.1,0,0,1,.2,1.9V32.5a11,11,0,0,1-.2,1.8,8.4,8.4,0,0,1-.5,1.7,7.1,7.1,0,0,1-1,1.4l-1.4,1a5.8,5.8,0,0,1-1.7.6l-1.9.2H8.4Z" />
    <g fill="#fff">
      <path d="M24.8,20.3a5,5,0,0,1,2.3-4.2,4.9,4.9,0,0,0-4-2.1c-1.6-.2-3.3,1-4.1,1s-2.2-1-3.6-1a5.3,5.3,0,0,0-4.5,2.8C9,20.1,10.4,25,12.3,27.7s2,2.8,3.4,2.8,1.9-.9,3.6-.9,2.1.9,3.5.9,2.5-1.4,3.4-2.7a17.7,17.7,0,0,0,1.5-3.1A4.7,4.7,0,0,1,24.8,20.3Z" />
      <path d="M22,12.2a5.5,5.5,0,0,0,1.2-3.5,5.3,5.3,0,0,0-3.3,1.7,4.4,4.4,0,0,0-1.1,3.3A3.7,3.7,0,0,0,22,12.2Z" />
      <path d="M42.3,27.1H37.6l-1.2,3.4h-2l4.5-12.4H41l4.5,12.4H43.4Zm-4.2-1.5h3.7L40,20.1h-.1Z" />
      <path d="M55.2,26c0,2.8-1.5,4.6-3.8,4.6A3.2,3.2,0,0,1,48.5,29h0v4.5H46.6V21.4h1.8v1.5h.1a3.3,3.3,0,0,1,2.8-1.6C53.6,21.3,55.2,23.2,55.2,26Zm-2,0c0-1.9-.9-3.1-2.3-3.1s-2.4,1.3-2.4,3.1.9,3,2.4,3S53.2,27.8,53.2,26Z" />
      <path d="M65.1,26c0,2.8-1.5,4.6-3.8,4.6A3.1,3.1,0,0,1,58.5,29h0v4.5H56.6V21.4h1.8v1.5h0a3.4,3.4,0,0,1,2.9-1.6C63.6,21.3,65.1,23.2,65.1,26Zm-1.9,0c0-1.9-.9-3.1-2.4-3.1s-2.4,1.3-2.4,3.1,1,3,2.4,3,2.4-1.2,2.4-3Z" />
      <path d="M71.7,27c.1,1.3,1.3,2.1,3,2.1s2.7-.8,2.7-1.9-.7-1.6-2.3-2l-1.6-.4c-2.3-.5-3.4-1.6-3.4-3.3s1.9-3.6,4.6-3.6,4.4,1.4,4.4,3.6H77.3a2.4,2.4,0,0,0-2.7-2c-1.5,0-2.5.8-2.5,1.9s.7,1.4,2.3,1.7l1.3.4c2.6.6,3.6,1.6,3.6,3.4s-1.8,3.8-4.8,3.8-4.6-1.4-4.7-3.7Z" />
      <path d="M83.3,19.3v2.1h1.8v1.5H83.3v5A1,1,0,0,0,84.4,29h.7v1.5a4.1,4.1,0,0,1-1.1,0c-1.8,0-2.5-.6-2.5-2.4V22.9H80.2V21.4h1.3V19.3Z" />
      <path d="M86.1,26c0-2.9,1.6-4.7,4.3-4.7s4.3,1.8,4.3,4.7-1.7,4.6-4.3,4.6S86.1,28.8,86.1,26Zm6.7,0c0-2-.9-3.1-2.4-3.1S88,24,88,26s.9,3.1,2.4,3.1,2.4-1.2,2.4-3.1Z" />
      <path d="M96.2,21.4H98V23h0a2.3,2.3,0,0,1,2.2-1.7h.6v1.8L100,23a2,2,0,0,0-2,1.8v5.7H96.2Z" />
      <path d="M109.4,27.8a3.7,3.7,0,0,1-3.9,2.8c-2.6,0-4.3-1.8-4.3-4.6s1.7-4.7,4.2-4.7,4.1,1.8,4.1,4.5v.6h-6.4a2.4,2.4,0,0,0,2.1,2.6h.3a2,2,0,0,0,2.1-1.3Zm-6.3-2.7h4.5a2.1,2.1,0,0,0-2-2.3h-.2a2.3,2.3,0,0,0-2.3,2.3Z" />
      <path d="M37.8,8.7a2.6,2.6,0,0,1,2.8,2.5.4.4,0,0,1,0,.5c0,1.9-1,3-2.8,3H35.7v-6Zm-1.2,5.2h1.1a2,2,0,0,0,2-1.8v-.4a1.9,1.9,0,0,0-1.6-2.1H36.6Z" />
      <path d="M41.7,12.4a2.1,2.1,0,0,1,4.2-.4v.4A2.2,2.2,0,0,1,44,14.8a2.1,2.1,0,0,1-2.3-1.9Zm3.3,0c0-.9-.4-1.5-1.2-1.5s-1.2.6-1.2,1.5S43,14,43.8,14s1.2-.6,1.2-1.6Z" />
      <path d="M51.6,14.7h-.9l-1-3.3h0l-1,3.3h-.9l-1.2-4.5h.9l.8,3.4h0l1-3.4h.8l.9,3.4h.1l.8-3.4h.9Z" />
      <path d="M53.9,10.2h.8v.7h.1a1.3,1.3,0,0,1,1.3-.8,1.5,1.5,0,0,1,1.6,1.4v3.2h-.9V12c0-.7-.3-1.1-1-1.1a1.1,1.1,0,0,0-1.1,1v2.8h-.8Z" />
      <path d="M59.1,8.4H60v6.3h-.9Z" />
      <path d="M61.2,12.4a2.2,2.2,0,0,1,1.9-2.3A2.2,2.2,0,0,1,65.5,12v.4a2.2,2.2,0,0,1-2,2.4,2.2,2.2,0,0,1-2.3-1.9Zm3.4,0a1.3,1.3,0,0,0-1.3-1.5c-.7,0-1.2.6-1.2,1.5s.5,1.6,1.2,1.6S64.6,13.4,64.6,12.4Z" />
      <path d="M66.4,13.4c0-.8.6-1.3,1.7-1.3h1.2v-.4c0-.5-.3-.7-.9-.7a1,1,0,0,0-1,.5h-.8a1.6,1.6,0,0,1,1.8-1.3c1.1,0,1.8.6,1.8,1.5v3.1h-.9v-.6h0a1.9,1.9,0,0,1-1.4.7,1.5,1.5,0,0,1-1.5-1.2Zm2.9-.4v-.3H68.2c-.6.1-.9.3-.9.7s.3.6.8.6a1,1,0,0,0,1.2-.9Z" />
      <path d="M71.3,12.4c0-1.4.8-2.3,1.9-2.3a1.6,1.6,0,0,1,1.4.8h.1V8.4h.9v6.3h-.9V14h-.1a1.6,1.6,0,0,1-1.4.8C72.1,14.8,71.3,13.9,71.3,12.4Zm1,0c0,1,.4,1.6,1.2,1.6s1.2-.6,1.2-1.6-.5-1.5-1.2-1.5-1.2.6-1.2,1.5Z" />
      <path d="M79.2,12.4a2.2,2.2,0,0,1,4.3-.4v.4a2.2,2.2,0,1,1-4.3.5Zm3.4,0c0-.9-.5-1.5-1.2-1.5a1.3,1.3,0,0,0-1.3,1.5c0,1,.5,1.6,1.3,1.6S82.6,13.4,82.6,12.4Z" />
      <path d="M84.7,10.2h.8v.7h.1a1.4,1.4,0,0,1,1.3-.8,1.5,1.5,0,0,1,1.6,1.4v3.2h-.9V12c0-.7-.3-1.1-1-1.1a1,1,0,0,0-1,1v2.8h-.9Z" />
      <path d="M93.5,9.1v1.1h1V11h-1v2.3c0,.5.2.7.7.7h.3v.8H94c-1,0-1.4-.3-1.4-1.2V11h-.7v-.8h.7V9.1Z" />
      <path d="M95.7,8.4h.9v2.5h.1a1.3,1.3,0,0,1,1.3-.8,1.6,1.6,0,0,1,1.6,1.4v3.2h-.9V12c0-.7-.3-1.1-1-1.1a1,1,0,0,0-1.1,1v2.8h-.9Z" />
      <path d="M104.8,13.5a2,2,0,0,1-2,1.3,2.1,2.1,0,0,1-2.1-2v-.3a2.2,2.2,0,0,1,1.8-2.4h.3c1.3,0,2,.9,2,2.3v.3h-3.2a1.2,1.2,0,0,0,1.1,1.3h.1a.9.9,0,0,0,1.1-.5ZM101.6,12h2.3a1,1,0,0,0-1-1.1h-.1a1.1,1.1,0,0,0-1.2,1.1Z" />
    </g>
  </svg>
);

export const googleBadge = (
  <svg viewBox="0 0 135 40">
    <title>Button for a Rev app in the google play store</title>
    <defs>
      <linearGradient
        id="lg"
        x1="22"
        y1="253"
        x2="5"
        y2="237"
        gradientTransform="matrix(1,0,0,-1,0,262)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#00a0ff" />
        <stop offset="0.01" stopColor="#00a1ff" />
        <stop offset="0.26" stopColor="#00beff" />
        <stop offset="0.51" stopColor="#00d2ff" />
        <stop offset="0.76" stopColor="#00dfff" />
        <stop offset="1" stopColor="#00e3ff" />
      </linearGradient>
      <linearGradient
        id="lg2"
        x1="34"
        y1="242"
        x2="10"
        y2="242"
        gradientTransform="matrix(1,0,0-1 0,262)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ffe000" />
        <stop offset="0.41" stopColor="#ffbd00" />
        <stop offset="0.78" stopColor="orange" />
        <stop offset="1" stopColor="#ff9c00" />
      </linearGradient>
      <linearGradient
        id="lg3"
        x1="25"
        y1="240"
        x2="2"
        y2="217"
        gradientTransform="matrix(1,0,0,-1,0,262)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ff3a44" />
        <stop offset="1" stopColor="#c31162" />
      </linearGradient>
      <linearGradient
        id="lg4"
        x1="7"
        y1="262"
        x2="17"
        y2="252"
        gradientTransform="matrix(1,0,0,-1,0,262)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#32a071" />
        <stop offset="0.07" stopColor="#2da771" />
        <stop offset="0.48" stopColor="#15cf74" />
        <stop offset="0.8" stopColor="#06e775" />
        <stop offset="1" stopColor="#00f076" />
      </linearGradient>
    </defs>
    <rect width="135" height="40" rx="5" />
    <path
      d="M130,.8A4.2,4.2,0,0,1,134.2,5V35a4.2,4.2,0,0,1-4.2,4.2H5A4.2,4.2,0,0,1,.8,35V5A4.2,4.2,0,0,1,5,.8H130m0-.8H5A5,5,0,0,0,0,5V35a5,5,0,0,0,5,5H130a5,5,0,0,0,5-5V5a5.1,5.1,0,0,0-5-5Z"
      fill="#a6a6a6"
    />
    <g fill="#fff">
      <g stroke="#fff;stroke-width:.2">
        <path d="M47.4,10.2a2.6,2.6,0,0,1-.7,2A3.1,3.1,0,0,1,41.4,10a3.1,3.1,0,0,1,3.1-3.1,2.5,2.5,0,0,1,1.2.2,2.2,2.2,0,0,1,.9.7l-.5.5a1.9,1.9,0,0,0-1.6-.7,2.2,2.2,0,0,0-1.7.7,2.4,2.4,0,0,0,0,3.4,2.2,2.2,0,0,0,1.7.7,2,2,0,0,0,1.6-.7,1.7,1.7,0,0,0,.5-1.2H44.5V9.8h2.9Z" />
        <path d="M52,7.7H49.3V9.6h2.5v.8H49.3v1.9H52V13H48.5V7H52Z" />
        <path d="M55.3,13h-.8V7.7H52.8V7H57v.7H55.3Z" />
        <path d="M59.9,13V7h.8v6Z" />
        <path d="M64.1,13h-.7V7.7H61.7V7h4.1v.7H64.1Z" />
        <path d="M73.6,12.2A3.1,3.1,0,0,1,68.3,10a3.1,3.1,0,1,1,6.2,0A2.9,2.9,0,0,1,73.6,12.2Zm-3.8-.5a2,2,0,0,0,1.6.7,1.9,1.9,0,0,0,1.6-.7,2.1,2.1,0,0,0,.7-1.7A2.1,2.1,0,0,0,73,8.3a1.9,1.9,0,0,0-1.6-.7,2,2,0,0,0-1.6.7,2.1,2.1,0,0,0-.7,1.7A2.1,2.1,0,0,0,69.8,11.7Z" />
        <path d="M75.6,13V7h.9l2.9,4.7h.1V7h.8v6h-.8L76.3,8.1h0V13Z" />
      </g>
      <path d="M68.1,21.8A4.1,4.1,0,0,0,63.9,26a4.3,4.3,0,1,0,8.5,0A4.1,4.1,0,0,0,68.1,21.8Zm0,6.8A2.6,2.6,0,1,1,70.5,26,2.5,2.5,0,0,1,68.1,28.6Zm-9.3-6.8A4.1,4.1,0,0,0,54.6,26a4.3,4.3,0,1,0,8.5,0A4.1,4.1,0,0,0,58.8,21.8Zm0,6.8A2.6,2.6,0,1,1,61.2,26,2.5,2.5,0,0,1,58.8,28.6ZM47.7,23.1v1.8h4.4a4.1,4.1,0,0,1-1,2.2,4.2,4.2,0,0,1-3.4,1.4A4.8,4.8,0,0,1,43,23.7a4.8,4.8,0,0,1,8-3.6l1.3-1.2A6.1,6.1,0,0,0,47.7,17a6.7,6.7,0,1,0,0,13.3,6.3,6.3,0,0,0,4.7-1.9,6.4,6.4,0,0,0,1.5-4.2,3.7,3.7,0,0,0-.1-1.1Zm45.4,1.4A4,4,0,0,0,85.4,26a4.2,4.2,0,0,0,4.2,4.3,4.3,4.3,0,0,0,3.6-1.9l-1.5-1a2.4,2.4,0,0,1-2.1,1.2,2.2,2.2,0,0,1-2-1.3l5.6-2.4Zm-5.8,1.4a2.2,2.2,0,0,1,2.2-2.5,1.9,1.9,0,0,1,1.6.9ZM82.6,30h1.9V17.5H82.6Zm-3-7.3h-.1a2.8,2.8,0,0,0-2.2-.9A4.1,4.1,0,0,0,73.2,26a4.2,4.2,0,0,0,4.1,4.3,2.9,2.9,0,0,0,2.2-1h.1v.6a2.3,2.3,0,0,1-4.4,1l-1.7.7a4.1,4.1,0,0,0,3.8,2.5c2.2,0,4-1.3,4-4.5V22H79.6Zm-2.2,5.9A2.4,2.4,0,0,1,75.1,26a2.3,2.3,0,1,1,4.6,0A2.4,2.4,0,0,1,77.4,28.6Zm24.4-11.1H97.3V30h1.9V25.3h2.6a3.9,3.9,0,1,0,0-7.8Zm.1,6H99.2V19.2h2.7a2.2,2.2,0,0,1,2.1,2.2A2.1,2.1,0,0,1,101.9,23.5Zm11.5-1.8a3.4,3.4,0,0,0-3.3,1.9l1.6.7a1.8,1.8,0,0,1,1.7-.9,1.8,1.8,0,0,1,2,1.6v.2a4.4,4.4,0,0,0-2-.5c-1.8,0-3.6,1-3.6,2.8a2.9,2.9,0,0,0,3.1,2.7,2.5,2.5,0,0,0,2.4-1.2h.1v1h1.8V25.2A3.5,3.5,0,0,0,113.4,21.7Zm-.2,6.9c-.7,0-1.5-.3-1.5-1.1s1.1-1.3,2-1.3a3.9,3.9,0,0,1,1.7.4A2.3,2.3,0,0,1,113.2,28.6ZM123.7,22l-2.1,5.4h-.1L119.3,22h-2l3.3,7.6-1.9,4.2h2L125.8,22Zm-16.8,8h1.9V17.5h-1.9Z" />
    </g>
    <path
      d="M10.4,7.5A2,2,0,0,0,10,8.9V31.1a2,2,0,0,0,.4,1.4h.1L22.9,20.1h0L10.5,7.5Z"
      fill="url(#lg)"
    />
    <path
      d="M27,24.3l-4.1-4.2h0L27,15.7h.1L32,18.6c1.4.8,1.4,2.1,0,2.8l-4.9,2.8Z"
      fill="url(#lg2)"
    />
    <path
      d="M27.1,24.2,22.9,20,10.4,32.5a1.6,1.6,0,0,0,2.1,0l14.6-8.3"
      fill="url(#lg3)"
    />
    <path
      d="M27.1,15.8,12.5,7.5a1.8,1.8,0,0,0-2.1,0L22.9,20.1Z"
      fill="url(#lg4)"
    />
    <path
      d="M27,24.1,12.5,32.4a1.8,1.8,0,0,1-2,0h0a1.5,1.5,0,0,0,2,0l14.6-8.3Z"
      fill="isolation:isolate;opacity:0.2"
    />
    <path
      d="M10.4,32.3a2,2,0,0,1-.4-1.4v.2a2,2,0,0,0,.4,1.4h0Z"
      fill="isolation:isolate;opacity:0.12"
    />
    <path
      d="M32,21.3l-5,2.8h.1L32,21.4c.7-.4,1.1-.9,1.1-1.4h0A2.2,2.2,0,0,1,32,21.3Z"
      fill="isolation:isolate;opacity:0.12"
    />
    <path
      d="M12.5,7.6,32,18.7A2.2,2.2,0,0,1,33.1,20h0c0-.5-.4-1-1.1-1.4L12.5,7.5C11.1,6.7,10,7.3,10,8.9v.2C10,7.5,11.1,6.8,12.5,7.6Z"
      fill="#fff;isolation:isolate;opacity:0.25"
    />
  </svg>
);
