import type { ContentfulAsset } from '#types';

export default class ResponsiveMediaHelper {
  private _url: string | null | undefined;
  private _contentType: string | undefined;

  constructor(
    private _desktopAsset: ContentfulAsset | null | undefined,
    private _mobileAsset: ContentfulAsset | null | undefined,
    private _wistiaVideoUrl: string | null | undefined,
    private _youtubeId: string | null | undefined
  ) {
    ({ url: this._url, contentType: this._contentType } = _desktopAsset || {});
  }

  get url(): string | null | undefined {
    return this._url;
  }

  get contentType(): string | undefined {
    return this._contentType;
  }

  get isVideo(): boolean {
    return !!this._contentType?.startsWith('video/');
  }

  get isWistiaVideo(): boolean {
    return !!this._wistiaVideoUrl && !this._youtubeId;
  }

  get isYouTubeVideo(): boolean {
    return !!this._youtubeId && !this._wistiaVideoUrl;
  }

  get isStaticImage(): boolean {
    return (
      this._contentType !== 'image/svg+xml' && this._contentType !== 'image/gif'
    );
  }

  srcSet = (screen: string, type = ''): string => {
    const format: string = type ? `fm=${type}&` : '';

    const mobileSets: string[] = [
      `${this._mobileAsset?.url || this._url}?${format}q=80&w=750 750w,`,
      `${this._mobileAsset?.url || this._url}?${format}q=80&w=830 830w,`,
      `${this._mobileAsset?.url || this._url}?${format}q=80&w=1080 1080w,`,
    ];

    const desktopSets: string[] = [
      `${this._url}?${
        type === 'webp' ? 'fm=webp&' : 'fm=jpg&'
      }q=90&w=1280 1280w,`,
      `${this._url}?${
        type === 'webp' ? 'fm=webp&' : 'fm=jpg&'
      }q=90&w=1920 1440w,`,
      `${this._url}?${
        type === 'webp' ? 'fm=webp&' : 'fm=jpg&'
      }q=90&w=1920 1920w,`,
    ];

    return screen === 'mobile'
      ? mobileSets.join('')
      : screen === 'desktop'
      ? desktopSets.join('')
      : mobileSets.concat(desktopSets).join('');
  };
}
