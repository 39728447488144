import dynamic from 'next/dynamic';
import Loading from '@components/Loading';

import type { ElementType } from 'react';

export const findComponent = (typename: string): ElementType => {
  switch (typename) {
    case 'TileGrid':
      return dynamic(() => import('src/components/TileGrid'), {
        loading: () => <Loading />,
      });
    case 'CopyImageSection':
      return dynamic(() => import('src/components/CopyImageSection'), {
        loading: () => <Loading />,
      });
    case 'TileBar':
      return dynamic(() => import('src/components/TileBar'), {
        loading: () => <Loading />,
      });
    case 'EmbedSection':
      return dynamic(() => import('src/components/EmbedSection'), {
        loading: () => <Loading />,
      });
    case 'FindYourService':
      return dynamic(() => import('src/components/FindYourService'), {
        loading: () => <Loading />,
      });
    case 'GatedContentSection':
      return dynamic(() => import('src/components/GatedContentSection'), {
        loading: () => <Loading />,
      });
    case 'Table':
      return dynamic(() => import('src/components/Table'), {
        loading: () => <Loading />,
      });
    case 'FeaturedAnimationSection':
      return dynamic(() => import('src/components/FeaturedAnimationSection'), {
        loading: () => <Loading />,
      });
    case 'GraphicButton':
      return dynamic(() => import('src/components/GraphicButtonCta'), {
        loading: () => <Loading />,
      });
    case 'LogoBar':
      return dynamic(() => import('src/components/LogoBar'), {
        loading: () => <Loading />,
      });
    case 'StatFeatureSection':
      return dynamic(() => import('src/components/StatFeatureSection'), {
        loading: () => <Loading />,
      });
    case 'VideoCollageSection':
      return dynamic(() => import('src/components/VideoCollageSection'), {
        loading: () => <Loading />,
      });
    case 'TileCarousel':
      return dynamic(() => import('src/components/TileCarousel'), {
        loading: () => <Loading />,
      });
    case 'ResourceFeatureSection':
      return dynamic(() => import('src/components/ResourceFeatureSection'), {
        loading: () => <Loading />,
      });
    case 'CopyContent':
      return dynamic(() => import('src/components/CopyContent'), {
        loading: () => <Loading />,
      });
    case 'TranslationPriceSection':
      return dynamic(() => import('src/components/TranslationPriceSection'), {
        loading: () => <Loading />,
      });
    case 'StepByStepSection':
      return dynamic(() => import('src/components/StepByStepSection'), {
        loading: () => <Loading />,
      });
    case 'MoreDetailsSection':
      return dynamic(() => import('src/components/MoreDetails'), {
        loading: () => <Loading />,
      });
    case 'ThreeFeatureSection':
      return dynamic(() => import('src/components/ThreeFeature'), {
        loading: () => <Loading />,
      });
    case 'LargeCtaSection':
      return dynamic(() => import('src/components/LargeCta'), {
        loading: () => <Loading />,
      });
    case 'HorizontalCta':
      return dynamic(() => import('src/components/HorizontalCta'), {
        loading: () => <Loading />,
      });
    case 'InPageNavigation':
      return dynamic(() => import('src/components/InPageNavigation'), {
        loading: () => <Loading />,
      });
    case 'InPageAnchor':
      return dynamic(() => import('src/components/InPageAnchor'), {
        loading: () => <Loading />,
      });
    case 'VideoSection':
      return dynamic(() => import('src/components/VideoSection'), {
        loading: () => <Loading />,
      });
    case 'VerticalCtaSection':
      return dynamic(() => import('src/components/VerticalCtaSection'), {
        loading: () => <Loading />,
      });
    case 'ListContentSection':
      return dynamic(() => import('src/components/ListContentSection'), {
        loading: () => <Loading />,
      });
    case 'LargeTextSection':
      return dynamic(() => import('src/components/LargeTextSection'), {
        loading: () => <Loading />,
      });
    case 'StatementSection':
      return dynamic(() => import('src/components/StatementSection'), {
        loading: () => <Loading />,
      });
    case 'TabbedSection':
      return dynamic(() => import('src/components/TabbedSection'), {
        loading: () => <Loading />,
      });
    case 'AccordionSection':
      return dynamic(() => import('src/components/AccordionSection'), {
        loading: () => <Loading />,
      });
    case 'Form':
      return dynamic(() => import('src/components/Form'), {
        loading: () => <Loading />,
      });
    case 'InPageBanner':
      return dynamic(() => import('src/components/InPageBanner'), {
        loading: () => <Loading />,
      });
    case 'ToggleContentSection':
      return dynamic(() => import('src/components/ToggleContentSection'), {
        loading: () => <Loading />,
      });

    default:
      throw Error(`Error: ${typename} is not a valid component typename.`);
  }
};
