import { Entry as EntryFragment } from '@lib/graphql';
import {
  ContentfulBase,
  ContentfulPage,
  ContentfulQueryResult,
} from '@lib/types';
import { request } from '../request';

type ContentfulPageShallow = Omit<ContentfulPage, 'pageContent'> & {
  pageContent?: ContentfulBase | null;
};

interface GetPageQueryResult extends ContentfulQueryResult {
  readonly pageCollection: {
    items: Array<ContentfulPageShallow | undefined>;
  };
}

/**
 * Fetches root page data from Contentful by slug
 * @param slug Slug of the page to fetch
 * @returns Promise; resolves to shallow page data not including pageContent
 */
export const getPage = async (
  slug: string
): Promise<ContentfulPageShallow | undefined> => {
  const response = await request<GetPageQueryResult>(`#graphql
    query getPage($preview: Boolean) {
      pageCollection (
        where: { slug: "${slug}" }
        limit: 1
        preview: $preview
      ) {
        items {
          ...Entry
          title
          slug
          enableZendeskChatWidget
          enableSiteBanner
          pageContent {
            ...on Entry {
              sys {
                id
              }
            }
          }
        }
      }
    }
    ${EntryFragment}
  `);

  if (response.errors) throw response.errors[0];

  return response.pageCollection.items[0];
};
