export const HeroVariant = Object.freeze({
  SIMPLE: 'Simple Hero',
  EXTENDED: 'Extended Hero',
  VIDEO: 'Video Hero',
  MEDIA: 'Media Hero',
  HIGHLIGHT: 'Highlight Hero',
  SIDE_BY_SIDE: 'Side By Side Hero',
  ANIMATION: 'Animation Hero',
  ICON: 'Icon Hero',
  ERROR: '404 Hero',
  FEATURE: 'Feature Hero',
  STREAMING: 'Streaming Hero',
} as const);

export const HERO_VARIANTS = Object.values(HeroVariant);

export type HeroVariants = typeof HERO_VARIANTS[number];
