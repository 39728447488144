import { theme } from '../../../theme';
import { ThemeUIStyleObject as TSO, useThemeUI } from 'theme-ui';

import type { AccordionStyles as Props } from './types';

export const useStyles = ({ col }: Props): typeof Styles => {
  const { colorMode } = useThemeUI();
  const color = colorMode ==='dark' ? 'white' : '%23292929';
  const border = `1px solid ${theme.colors!.border}`;

  const Styles = {
    ROOT: {
      contain: 'content',
      display: 'flex',
      borderBlockEnd: border,

      'body &': col && { gridColumn: col },

      '&:first-of-type': { borderBlockStart: border },

      '&:focus ': { outline: '0' },

      '&::-webkit-details-marker': { display: 'none' },

      '&[open] span::after': { transform: 'rotate(180deg)' },
    } as TSO,

    /**
     * NOTE: We need hover state from design
     */

    HEADING: {
      listStyle: 'none',
      cursor: 'pointer',

      '&::-webkit-details-marker': { display: 'none' },

      '& span': {
        display: 'flex',
        paddingInlineStart: ['0px', 2],
        paddingInlineEnd: ['0px', 2],
        paddingBlockStart: 2,
        paddingBlockEnd: 2,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontWeight: ['bold', 'semiBold'],

        '&:hover, &:focus, [open] &': {
          fontWeight: 'extraBold',
        },

        '&::after': {
          display: 'block',
          inlineSize: '24px',
          blockSize: '24px',
          minInlineSize: '24px',
          content: '""',
          background:
            `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18 9L12 15L6 9" stroke="${color}" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat`,
          transformOrigin: 'center',
        },
      },
    } as TSO,

    CONTENT: {
      paddingInlineStart: ['0px', 2],
      paddingInlineEnd: [5, 7],
      paddingBlockEnd: 5,
      marginBlockStart: '-1ex',
    } as TSO,

    TEXT: {
      '& p': { fontSize: '16px' },
      '& ul, ol': { 
        paddingInlineStart: 4,
        paddingBlockStart: 4,
      },
    } as TSO,

    LINK: {
      marginBlockStart: 2, 
    } as TSO,
  };

  return Styles;
};
