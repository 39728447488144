export const LogoBar = `#graphql
fragment LogoBar on LogoBar{
    ...Entry
    heading
    logoGroups: logoGroupsCollection(limit: 4) {
        items{
            ...LogoGroup
        }
    }
    cta{
        ...Cta
    }

}`