export const Hero = `#graphql
  fragment Hero on Hero {
    ...Entry
    variant
    heading
    headingColor
    headingRight
    subheading
    copy
    texture
    backgroundColor
    ctasCollection(limit: 3) {
      items {
        ...Cta
        ...ModalCta
      }
    }
    primaryCtaColor
    secondaryCtasCollection(limit: 3) {
      items {
        ...Cta
      }
    }
    rating
    ratingCopy
    starRatingLink
    superheading
    responsiveMedia {
      ...ResponsiveMedia
    }
    video {
      ...ResponsiveMedia
    }
    imageCaption
    highlightHeading
    simpleIcon
    animation {
      ...ResponsiveMedia
    }
    iconImage {
      ...ResponsiveMedia
    }
    tile {
      ...Tile
    }
    form{
        ...Form
    }
  }
`;
