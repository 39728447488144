export const Service = `#graphql
  fragment Service on Service {
    ...Entry
    heading
    icon {
        ...ResponsiveMedia
    }
    summary
    helperText
    animation {
        ...ResponsiveMedia
    }
    fallbackImage {
        ...ResponsiveMedia
    }
    tabbedSection {
        ...TabbedSection
    }
}
`;
