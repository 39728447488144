export const ListContentSection = `#graphql
fragment ListContentSection on ListContentSection{
    ...Entry
    heading
    listItemsCollection(limit: 3){
        items{
            ...CopyContent
        }
    }
    bgColor
}`