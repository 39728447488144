export const ThreeFeatureSection = `#graphql
  fragment ThreeFeatureSection on ThreeFeatureSection {
    ...Entry
    heading
    featureCopy: copy
    cta {
      ...Cta
    }
    featuredStoriesCollection(limit:3) {
      items {
        ...Tile
      }
    }
  }
`;
