export const Form = `#graphql
  fragment Form on Form {
    ...Entry
    variant
    formHandler
    # submitDestination
    submitButtonText
    # submitContent{
      # json
    # }
    inputsCollection(limit: 8) {
        items {
            ...FormInput
        }
    }
    title
    hideTitle
    helpText
    helpCta{
      ...Cta
    }
    analyticsEvent {
      ...AnalyticsEvent
    }
    embedForm
    setEmailCookie
  }
`;
