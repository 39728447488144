export const Step = `#graphql
  fragment Step on Step {
    ...Entry
    heading
    copy
    media {
        ...ResponsiveMedia
    }
    animation {
      ...ResponsiveMedia
    }
  }
`;
