import type { ThemeUIStyleObject } from 'theme-ui';
import type { NavStyleProps as Props } from './types';

export type TSO = ThemeUIStyleObject;
export type NavStyles = ReturnType<typeof useStyles>;

export const useStyles = ({
  cta,
  sticky = false,
  ctaStyle,
}: Props): typeof Styles => {
  const panePadding = cta ? '224px' : '174px';

  const Styles = {
    /**
     * SECTION - Base
     */

    ROOT: {
      '--nav-height': ['80px', '90px', '100px'],
      '--banner-height': '0px',
      '--scroll-pos': '0px',
      '--total-height':
        'calc((var(--nav-height) + var(--banner-height)) - var(--scroll-pos))',
      display: 'flex',
      inlineSize: '100%',
      blockSize: 'var(--nav-height)',
      paddingInlineStart: [null, null, 5],
      paddingInlineEnd: [null, null, 5],
      position: sticky ? 'sticky' : 'relative',
      top: '0px',
      insetBlockStart: '0px',
      left: '0px',
      insetInlineStart: '0px',
      zIndex: 99,
      alignItems: 'center',

      '& .__hasActiveArea': { outline: 0 },

      '& .__hasActiveArea::after': {
        inlineSize: '100%',
        blockSize: '100%',
        position: 'absolute',
        left: '0px',
        insetInlineStart: '0px',
        top: '0px',
        insetBlockStart: '0px',
        content: '""',
      },

      // Backdrop
      '&::before': {
        inlineSize: '100vw',
        blockSize: '100vh',
        position: 'fixed',
        top: '0px',
        insetBlockStart: '0px',
        left: '0px',
        insetInlineStart: '0px',
        zIndex: -1,
        content: [null, '""'],
        backgroundColor: 'rgba(0, 0, 0, 0)',
        pointerEvents: 'none',
        transition: 'background-color .4s 0s',
      },

      '&::after': {
        inlineSize: '100%',
        blockSize: '100%',
        position: 'absolute',
        left: '0px',
        insetInlineStart: '0px',
        zIndex: 0,
        content: [sticky ? '""' : null, '""'],
        backgroundColor: ['white', null, 'grey.1'],
        pointerEvents: 'none',
      },

      '&.--open::before': {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
      },

      '&.--open::after': { borderBlockEnd: [null, 'pane'] },
    } as TSO,

    LOGO: {
      boxSizing: 'content-box',
      blockSize: ['24px', null, '30px'],
      paddingBlockStart: ['11px', null, 0],
      paddingBlockEnd: ['11px', null, 0],
      marginInlineStart: [2, null, '0px'],
      position: [null, null, 'absolute'],
      left: [null, null, '50%'],
      insetInlineStart: [null, null, '50%'],
      zIndex: 1,
      transform: [null, null, 'translateX(-50%)'],

      '& svg': {
        blockSize: '100%',
        transition: 'fill .2s',
      },

      '&:focus, &:hover': { fill: 'neon.2' },
    } as TSO,

    /**
     * SECTION - Areas
     */

    PANE: {
      contain: 'layout style',
      inlineSize: ['calc(100vw + 1px)', '524px', '580px'],
      blockSize: ['100%', 'calc(100% - var(--total-height))'],
      maxBlockSize: '1000px',
      padding: [null, null, '32px'],
      position: 'fixed',
      left: ['calc(-100% - 1px)', '-524px', '-580px'],
      insetInlineStart: ['calc(-100% - 1px)', '-524px', '-580px'],
      top: ['0px', 'var(--total-height)'],
      insetBlockStart: ['0px', 'var(--total-height)'],
      zIndex: 2,
      borderInlineEnd: 'pane',
      borderBlockEnd: [null, null, 'pane'],
      backgroundColor: ['white', null, 'grey.1'],
      transition: 'transform .4s, opacity .4s, visibility 0s .4s',
      opacity: [null, null, 0],
      visibility: 'hidden',
      backfaceVisibility: 'hidden',

      /** GROUP - Main Menu */

      '&.__menu': {
        inlineSize: [null, null, 'auto'],
        blockSize: [null, null, 'auto'],
        position: [null, null, 'static'],
        padding: [null, null, '0px'],
        transform: [null, null, 'none'],
        transition: [null, null, 'none'],
        borderInlineEnd: [null, null, '0'],
        borderBlockEnd: [null, null, '0'],
        opacity: [null, null, 1],
        visibility: [null, null, 'visible'],
      },

      '&.--open': {
        opacity: 1,
        transform: 'translateX(100%)',
        transition: 'transform .4s, opacity .4s, visibility 0s 0s',
        visibility: 'visible',
      },
    } as TSO,

    PANE_SCROLL_AREA: {
      inlineSize: '100%',
      blockSize: '100%',
      position: ['absolute', null, 'static'],
      overflowX: 'hidden',
      overflowY: 'auto',
      outline: 0,
      zIndex: 0,
    } as TSO,

    PANE_CONTENT: {
      display: 'flex',
      paddingBlockStart: [null, 1, '0px'],
      paddingInlineStart: [null, 1, '0px'],
      paddingInlineEnd: [null, 1, '0px'],
      paddingBlockEnd: [panePadding, '216px', '0px'],
      flexFlow: 'column wrap',
      opacity: [null, null, 0],
      transition: [null, null, 'opacity .4s 0s'],
      backfaceVisibility: 'hidden',

      '.--open &': {
        opacity: 1,
        transition: [null, null, 'opacity .4s .4s'],
      },

      // MAIN_MENU
      '.__menu &': {
        flexFlow: [null, null, 'row'],
        opacity: 1,
        transition: [null, null, 'none'],
      },
    } as TSO,

    SECONDARY_PANE: {
      zIndex: [3, null, 1],
      backgroundColor: 'white',

      'section&.--open': {
        transform: ['translateX(100%)', null, 'translateX(200%)'],
      },

      '.--open ~ section&': {
        transitionTimingFunction: [null, null, 'ease-in'],
      },

      '.--open ~ section&.--open': {
        transitionDuration: '.5s',
        transitionTimingFunction: [null, null, 'ease-out'],
      },

      '& > div > div': {
        paddingBlockEnd: [null, null, '80px'],
      },

      /** GROUP - Nav Tile */

      '& .nav__Tile': {
        inlineSize: '100%',
        paddingBlockEnd: 4,
        marginBlockStart: [4, 6],
        backgroundColor: 'grey.1',
        order: 2,

        '& h3': {
          fontWeight: 'regular',
          fontSize: [1, 3],
          lineHeight: 1.36,
          color: 'black',
        },

        '& p': {
          fontSize: [0, 1],
          color: 'grey.8',
        },

        '& h3, & p, & a': {
          paddingInlineStart: 4,
          paddingInlineEnd: 5,
        },

        '& [data-responsive]': { blockSize: '200px' },

        '& [data-responsive]::before': { paddingBlockStart: '200px' },

        '&:first-of-type': { marginBlockStart: 1 },
      },

      /** GROUP - Nav Section */

      '& .nav__Section': {
        inlineSize: '100%',
        marginBlockStart: [5, 6],
        order: 2,

        '&:first-of-type': { marginBlockStart: 1 },

        '& > h3': {
          variant: 'styles.h5',
          display: 'flex',
          paddingInlineStart: 1,
          paddingInlineEnd: 1,
          alignItems: 'baseline',
          color: 'grey.8',
          margin: '0px',

          '& span': {
            inlineSize: 'max-content',
            paddingInlineEnd: 1,
            order: 1,
            backgroundColor: 'white',
          },

          '&::before': {
            flex: 1,
            blockSize: '1px',
            marginInlineEnd: 1,
            order: 2,
            content: '""',
            backgroundColor: '#C5C5C5',
          },
        },

        '& picture': { display: 'none' },
      },
    } as TSO,

    GROUP: {
      marginBlockStart: 5,
      order: 2,
      zIndex: 2,

      '& > h4': {
        variant: 'text.heading',
        display: 'flex',
        paddingInlineStart: 1,
        paddingInlineEnd: 1,
        alignItems: 'baseline',
        fontSize: 0,
        fontWeight: 'semiBold',
        textTransform: 'uppercase',
        color: 'grey.8',

        '& span': {
          inlineSize: 'max-content',
          paddingInlineEnd: 1,
          order: 1,
          backgroundColor: ['white', null, 'grey.1'],
        },

        '&::before': {
          flex: 1,
          blockSize: '1px',
          marginInlineEnd: 1,
          order: 2,
          content: '""',
          backgroundColor: '#C5C5C5',
        },
      },

      '& > p': {
        paddingInlineStart: 1,
        paddingInlineEnd: 1,
        marginBlockStart: '4px',
        fontSize: 0,
        color: 'grey.8',
      },

      '&:first-of-type': { marginBlockStart: 1 },
    } as TSO,

    USER_MENU: {
      contain: ['strict', null, 'content'],
      inlineSize: ['calc(100vw + 1px)', '524px', 'auto'],
      blockSize: ['144px', '126px', 2],
      position: ['fixed', null, 'static'],
      left: ['calc(-100% - 1px)', '-524px', 'auto'],
      insetInlineStart: ['calc(-100% - 1px)', '-524px', 'auto'],
      bottom: ['0px', null, 'auto'],
      insetBlockEnd: ['0px', null, 'auto'],
      marginInlineStart: [null, null, 'auto'],
      order: [null, null, 2],
      zIndex: 10,
      outline: 0,
      borderInlineEnd: [null, 'pane', '0'],
      backgroundColor: ['surface.primary', null, 'grey.1'],
      transition: ['transform .4s, visibility 0s .4s', null, 'none'],
      visibility: ['hidden', null, 'visible'],
      backfaceVisibility: 'hidden',

      '.--open + &': {
        transform: 'translateX(100%)',
        transition: ['transform .4s, visibility 0s 0s', null, 'none'],
        visibility: 'visible',
      },

      '& > div': {
        display: 'flex',
        inlineSize: ['calc(100% - 26px)', '100%'],
        blockSize: '100%',
        margin: 'auto',
        flexFlow: ['column wrap', 'row'],
        alignItems: 'center',
        justifyContent: ['space-evenly', null, 'space-between'],
        borderBlockStart: ['1px solid', null, '0px'],
      },

      '& a': {
        blockSize: [null, null, '100%'],

        '&:first-of-type': {
          marginInlineStart: [null, null, 1],
          order: [2, null, 'unset'],
        },
      },
    } as TSO,

    SIMPLE_USER_MENU: {
      contain: ['initial', null, 'content'],
      inlineSize: ['100%', 'auto'],
      blockSize: [2],
      position: ['static'],
      left: ['calc(-100% - 1px)', '-524px', 'auto'],
      insetInlineStart: ['calc(-100% - 1px)', '-524px', 'auto'],
      bottom: ['0px', null, 'auto'],
      insetBlockEnd: ['0px', null, 'auto'],
      marginInlineStart: [null, 'auto'],
      order: [null, null, 2],
      zIndex: 10,
      outline: 0,
      borderInlineEnd: ['0'],
      backgroundColor: ['surface.primary', null, 'grey.1'],
      transition: ['transform .4s, visibility 0s .4s', null, 'none'],
      visibility: ['visible'],
      backfaceVisibility: 'hidden',

      '.--open + &': {
        transform: 'translateX(100%)',
        transition: ['transform .4s, visibility 0s 0s', null, 'none'],
        visibility: 'visible',
      },

      '& > div': {
        display: 'flex',
        inlineSize: ['calc(100% - 26px)', '100%'],
        blockSize: '100%',
        margin: 'auto',
        paddingInlineEnd: [null, '20px', null],
        flexFlow: [null, 'row'],
        alignItems: 'center',
        justifyContent: ['flex-end', null, 'space-between'],
      },

      '& a': {
        blockSize: [null, null, '100%'],

        '&:first-of-type': {
          marginInlineStart: [null, null, 1],
        },
      },
    } as TSO,

    /**
     * SECTION - Buttons & Links
     */

    OPEN_MENU: {
      display: ['block', null, 'none'],
      marginInlineStart: 'auto',
      marginInlineEnd: ['5px', '10px'],
      zIndex: 1,
      outline: '0',
      color: 'black',

      '&:focus': { color: 'neon.2' },
    } as TSO,

    CLOSE_MENU: {
      display: ['block', 'none'],
      marginInlineStart: 'auto',
      marginInlineEnd: 0,
      marginBlockStart: 5,
      zIndex: 1,
      outline: '0',
      color: 'black',

      '&:focus': { color: 'neon.2' },
    } as TSO,

    ROOT_BUTTON: {
      variant: 'text.heading',
      display: 'flex',
      inlineSize: ['100%', 'auto'],
      blockSize: ['90px', '78px', 2],
      padding: [1, null, '0px'],
      position: 'relative',
      marginInlineEnd: [null, null, 4],
      order: 2,
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: ['1.75rem', 4, 1],
      fontWeight: ['heading', null, 'semiBold'],
      textAlign: 'left',
      color: ['black', null, 'grey.8'],
      outline: '0',
      background: 'none',
      transition: 'color .2s',

      '&::after': {
        content: '""',
        inlineSize: '100%',
        blockSize: '2px',
        backgroundColor: 'lilac.4',
        position: 'absolute',
        left: '0px',
        insetInlineStart: '0px',
        bottom: '6px',
        insetBlockEnd: '6px',
        opacity: 0,
        transition: 'opacity .2s',
      },

      '& svg': {
        display: [null, null, 'none'],
        inlineSize: '22px',
        blockSize: '22px',
        marginInlineEnd: '5px',
      },

      '&:first-child': { marginBlockStart: [0, 4, '0px'] },

      '&:focus, &:hover, &.--active': {
        color: ['neon.2', null, 'black'],

        '&::after': { opacity: [null, null, 1] },
      },
    } as TSO,

    TOP_LEVEL_CTA: {
      padding: 1,
      position: 'relative',
      marginBlockStart: 3,
      order: 2,

      '&:first-of-type': { marginBlockStart: [2, null, '0px'] },

      '& button, & p a': {
        display: 'inline',
        background: 'none',
        outline: 0,
      },

      '& > div': {
        paddingInlineEnd: '65px',
      },

      '& h3': {
        variant: 'text.heading',
        fontSize: ctaStyle ? 2 : 4,
        fontWeight: 'heading',
        lineHeight: 1.33,
        color: 'grey.8',
        transition: 'color .2s',
      },

      '& p': {
        fontSize: 1,
        lineHeight: 1.75,
        color: 'grey.8',
      },

      '& h3 + p': { marginBlockStart: '3px' },

      '& .pane__Super': {
        display: 'flex',
        marginBlockStart: '0px',
        marginBlockEnd: '6px',
        fontSize: 0,

        '& svg': {
          inlineSize: '22px',
          blockSize: '22px',
          marginInlineEnd: '5px',
        },
      },

      '& > svg': { transition: 'fill .2s' },

      '&:focus-within, &:hover, &.--active': {
        '& h3': { color: 'neon.2' },
        '& > svg': { stroke: 'neon.2' },
      },
    } as TSO,

    PANE_BUTTON: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& button': { outline: 0 },

      '& > div': {
        paddingInlineEnd: '38px',
      },

      '& > svg': {
        flex: '0 0 22px',
        marginInlineEnd: '5px',
      },

      '&:first-of-type': { marginBlockStart: 2 },
    } as TSO,

    GROUP_LEVEL_CTA: {
      display: 'flex',
      minBlockSize: 2,
      paddingInlineStart: 1,
      paddingInlineEnd: 1,
      position: 'relative',
      marginBlockStart: 2,
      order: 2,
      flexFlow: 'column wrap',
      justifyContent: 'center',

      '&.__hasMessage': { marginBlockStart: 4 },

      '&:first-of-type': { marginBlockStart: 4 },

      '& > div': { paddingInlineEnd: '65px' },

      '& h5': {
        variant: 'text.heading',
        fontSize: 2,
        fontWeight: 'heading',
        lineHeight: 1.44,
        color: 'grey.8',

        '& a': {
          display: 'block',
          outline: 0,
        },
      },

      '& p': {
        marginBlockStart: '0px',
        fontSize: 1,
        color: 'grey.8',
      },

      '[class*=SecondaryPane] & p': { fontSize: 0 },

      '&:focus-within, &:hover': {
        '& h5': { color: 'neon.2' },
      },
    } as TSO,

    SECOND_CTA: {
      variant: 'buttons.root',
      display: 'inline-flex',
      inlineSize: ['100%', 'auto'],
      blockSize: [3, 'auto'],
      paddingInlineStart: [5, 6, 4],
      paddingInlineEnd: [5, 6, 4],
      paddingBlockStart: [null, 3, 2],
      paddingBlockEnd: [null, 3, 2],
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      textAlign: 'center',
      backgroundImage: [
        null,
        null,
        'linear-gradient(90deg, #3F00FF, #8D57FF 60%)',
      ],
      backgroundColor: 'black',
      backgroundSize: '200% 100%',
      backgroundPositionX: '0%',
      outline: 0,
      border: '0px',
      borderRadius: 2,
      transition: 'all .2s',

      '&:focus, &:hover': {
        backgroundColor: ['grey.8', null, 'neon.1'],
        backgroundPositionX: '30%',
      },
    } as TSO,

    FIRST_CTA: {
      display: 'flex',
      inlineSize: ['100%', 'auto'],
      blockSize: [3, 'auto', 2],
      paddingInlineStart: [null, 6, '0px'],
      paddingInlineEnd: [null, 6, '0px'],
      paddingBlockStart: [null, '17px', '0px'],
      paddingBlockEnd: [null, '17px', '0px'],
      marginInlineEnd: [null, null, 4],
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'heading',
      fontWeight: ['extraBold', null, 'semiBold'],
      fontSize: 1,
      lineHeight: 'button',
      letterSpacing: 'button',
      color: ['black', null, 'grey.8'],
      border: ['cta', null, 'none'],
      outline: '0',
      borderRadius: 2,
      transition: 'all .2s',

      '&::after': {
        inlineSize: '100%',
        blockSize: '2px',
        position: 'absolute',
        left: '0px',
        insetInlineStart: '0px',
        bottom: '6px',
        insetBlockEnd: '6px',
        content: '""',
        backgroundColor: 'lilac.4',
        opacity: 0,
        transition: 'opacity .2s',
      },

      '&:focus, &:hover': {
        color: ['grey.8', null, 'black'],
        borderColor: ['grey.8', null, 'unset'],

        '&::after': { opacity: [null, null, 1] },
      },
    } as TSO,

    SIMPLE_FIRST_CTA: {
      display: 'flex',
      inlineSize: [null, 'auto'],
      blockSize: [3, 'auto', 2],
      paddingInlineStart: [null, 6, '0px'],
      paddingInlineEnd: [null, 6, '0px'],
      paddingBlockStart: [null, '17px', '0px'],
      paddingBlockEnd: [null, '17px', '0px'],
      marginInlineEnd: [1, null, 4],
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'heading',
      fontWeight: ['semiBold'],
      fontSize: 1,
      lineHeight: 'button',
      letterSpacing: 'button',
      color: ['grey.8'],
      border: ['none'],
      outline: '0',
      borderRadius: 2,
      transition: 'all .2s',

      '&::after': {
        inlineSize: '100%',
        blockSize: '2px',
        position: 'absolute',
        left: '0px',
        insetInlineStart: '0px',
        bottom: '6px',
        insetBlockEnd: '6px',
        content: '""',
        backgroundColor: 'lilac.4',
        opacity: 0,
        transition: 'opacity .2s',
      },

      '&:focus, &:hover': {
        color: ['grey.8', null, 'black'],
        borderColor: ['grey.8', null, 'unset'],

        '&::after': { opacity: [null, null, 1] },
      },
    } as TSO,

    SIMPLE_SECOND_CTA: {
      variant: 'buttons.root',
      display: 'inline-flex',
      inlineSize: [null, 'auto'],
      blockSize: [3, 'auto'],
      paddingInlineStart: [5, 6, 4],
      paddingInlineEnd: [5, 6, 4],
      paddingBlockStart: [null, 3, 2],
      paddingBlockEnd: [null, 3, 2],
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      textAlign: 'center',
      backgroundImage: ['linear-gradient(90deg, #3F00FF, #8D57FF 60%)'],
      backgroundColor: 'black',
      backgroundSize: '200% 100%',
      backgroundPositionX: '0%',
      outline: 0,
      border: '0px',
      borderRadius: 2,
      transition: 'all .2s',

      '&:focus, &:hover': {
        backgroundColor: ['grey.8', null, 'neon.1'],
        backgroundPositionX: '30%',
      },
    } as TSO,

    BACK_BUTTON: {
      display: ['inline-flex', null, 'none'],
      blockSize: 2,
      marginInlineStart: 1,
      marginBlockStart: [5, 1],
      marginInlineEnd: 'auto',
      order: 1,
      alignItems: 'center',
      fontSize: 1,
      fontWeight: 'bold',
      fontFamily: 'body',
      color: 'lilac.7',
      background: 'none',
      outline: 0,

      '& span': { marginInlineStart: '.6ch' },

      '& svg': {
        inlineSize: '1rem',
        blockSize: '1rem',
        strokeWidth: 3,
      },

      '&:focus': { color: 'primary' },
    } as TSO,

    ANCHORED_CTA: {
      variant: 'text.heading',
      display: 'flex',
      inlineSize: '100%',
      blockSize: [3, '64px', '80px'],
      paddingInlineStart: [2, 4, '32px'],
      paddingInlineEnd: [2, 4, '32px'],
      position: 'absolute',
      left: '0px',
      insetInlineStart: '0px',
      bottom: ['144px', '126px', '0px'],
      insetBlockEnd: ['144px', '126px', '0px'],
      zIndex: 5,
      alignItems: 'center',
      fontSize: [1, null, 4],
      fontWeight: 'heading',
      color: 'grey.8',
      outline: 0,
      borderBlockStart: 'pane',
      backgroundColor: 'white',
      transition: 'background-color .2s',
      backfaceVisibility: 'hidden',

      '& svg': {
        inlineSize: '1rem',
        blockSize: '1rem',
        strokeWidth: [3, null, 4],
        marginInlineStart: 1,
      },

      '&:hover, &:focus': { backgroundColor: 'lilac.2' },
    } as TSO,
  };

  return Styles;
};
