export const VerticalCtaSection = `#graphql
  fragment VerticalCtaSection on VerticalCtaSection {
    ...Entry
    heading
    supertitle
    mdCopy
    variant
    cta{
      ...Cta
    }
    linksCollection(limit: 5) {
      items {
        ...Cta
      }
    }
  }
`;
