export const StepByStepSection = `#graphql
  fragment StepByStepSection on StepByStepSection {
    ...Entry
    heading
    subheading
    stepsCollection(limit: 3) {
       items {
            ...Step
       }
    }
    backgroundColor
  }
`;
