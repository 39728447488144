import { FC, Fragment } from 'react';
import { Grid, Button, Block, Link, Logo, Icon } from '#ui';
import { useStyles } from './full.styles';
import MarkdownText from '@components/MarkdownText';
import { propsValidator } from '#utils';

import type { ColumnProps, FullFooterProps as Props } from './types';

const FullFooter: FC<Props> = ({
  colorMode,
  leftLinksCollection,
  columnOneCollection,
  columnTwoCollection,
  columnThreeCollection,
  columnFourCollection,
  rightPaneContent,
  rightLinkCta,
  rightButtonCta,
  subfooterLinksCollection,
  ...entry
}) => {
  const valid = propsValidator(entry, { colorMode });

  if (!valid) return null;

  const subfooter =
    subfooterLinksCollection?.items.length &&
    subfooterLinksCollection.items;
  const leftLinks =
    leftLinksCollection.items.length &&
    leftLinksCollection.items;
  const firstColumn =
    columnOneCollection?.items.length &&
    columnOneCollection.items;
  const secondColumn =
    columnTwoCollection?.items.length &&
    columnTwoCollection.items;
  const thirdColumn =
    columnThreeCollection?.items.length &&
    columnThreeCollection.items;
  const fourthColumn =
    columnFourCollection?.items.length &&
    columnFourCollection.items;

  const Styles = useStyles({ colorMode });

  const buildColumn = (column: ColumnProps) => {
    return(
      <Block col={['span 4', 'span 2']} sx={Styles.LINK_COLUMN} data-testid='column'>
        {column.map((navGroup, index) => (
          <ul sx={Styles.LINK_LIST} key={index} data-testid='link-list'>
            {navGroup.contentCollection.items.map((link, i) => 
              <li className={i === 0 ? 'heading' : undefined} key={i}>
                {link?.destination ? 
                  <a href={link?.destination}>{link.ctaCopy}</a> : 
                  <Fragment>{link.ctaCopy}</Fragment> }
              </li>
            )}
          </ul>
        ))}
      </Block>
    )
  };

  return (
    <footer sx={Styles.ROOT}>
      <Grid sx={Styles.MAIN_NAVIGATION} subgrid>
        <Block col={[null, 'span 4', 'span 2']} sx={Styles.LEFT_COLUMN}>
          <Logo clone/>
          {!!leftLinks && (
            <ul sx={Styles.LEFT_LINK_LIST}>
              {leftLinks.map((link) => (
                <li key={link.sys.id} >
                  <a href={link?.destination || undefined}>{link.ctaCopy}</a>
                </li>
              ))}
            </ul>
          )}
        </Block>

        {!!firstColumn && buildColumn(firstColumn)}
        {!!secondColumn && buildColumn(secondColumn)}
        {!!thirdColumn && buildColumn(thirdColumn)}
        {!!fourthColumn && buildColumn(fourthColumn)}

        <Block
          as="section"
          col={[null, 'span 4', '12/-1']}
          sx={Styles.RIGHT_COLUMN}
        >
          <Block sx={Styles.RIGHT_DATA_COLUMN}>
            {rightPaneContent?.superheading && (
              <p sx={Styles.RIGHT_LINKS_SUPERHEADER}>
                <Icon sx={Styles.RIGHT_COLUMN_ICON} />
                <span>{rightPaneContent.superheading}</span>
              </p>
            )}
            {rightPaneContent?.heading && (
              <h2 sx={Styles.RIGHT_LINKS_HEADER}>
                {rightPaneContent.heading}</h2>
            )}
            {rightPaneContent?.text && (
              <MarkdownText
                linkVariant="Simple"
                styles={Styles.RIGHT_LINKS_COPY}
              >
                {rightPaneContent.text}
              </MarkdownText>
            )}
            {!!rightLinkCta?.destination && (
              <Link
                href={rightLinkCta.destination}
                sx={Styles.RIGHT_LINKS_CTA}
                withIcon
                openInNewTab={rightLinkCta.openInNewTab}
              >
                {rightLinkCta.ctaCopy}
              </Link>
            )}
          </Block>
          {!!rightButtonCta?.destination && (
            <Button
              href={rightButtonCta.destination}
              color="Violet"
              sx={Styles.BUTTON}
            >
              {rightButtonCta?.ctaCopy}
            </Button>
          )}
        </Block>
        <Block sx={Styles.SUBLINKS}>
          <ul sx={Styles.SUBLINKS_LIST}>
            <li key="copyright">&copy; rev.com</li>
            {!!subfooter &&
              subfooter.map((link) => {
                if (link?.destination) {
                  return (
                    <li key={link.sys.id}>
                      <a href={link.destination}>{link?.ctaCopy}</a>
                    </li>
                  );
                }
              })}
          </ul>
        </Block>
      </Grid>
    </footer>
  );
};

export default FullFooter;
