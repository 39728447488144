export const CopyImageVariant = Object.freeze({
  NO_IMAGE: 'No Image',
  COLORFUL_IMAGE: 'Colorful Image',
  IMAGE_HALF_LEFT: '1/2 Left Image',
  IMAGE_HALF_RIGHT: '1/2 Right Image',
  IMAGE_THIRD_LEFT: '1/3 Left Image',
  IMAGE_THIRD_RIGHT: '1/3 Right Image',
  FEATURED_IMAGE_LEFT: 'Featured Image Left',
  FEATURED_IMAGE_RIGHT: 'Featured Image Right',
} as const);

export const LineLengthObj = Object.freeze({
  DEFAULT: 'Default',
  SHORT: 'Short',
} as const);

export const COPY_IMAGE_VARIANTS = Object.values(CopyImageVariant);

export type CopyImageVariants = typeof COPY_IMAGE_VARIANTS[number];
