/**
 * NavVariant
 */

export const NavVariant = Object.freeze({
  SIMPLE: 'simple',
  FULL: 'full',
} as const);

export const NAV_VARIANTS = Object.values(NavVariant);

export type NavVariants = typeof NAV_VARIANTS[number];


/**
 * User Types
 */

export const AccountValues = Object.freeze({
  isAdminOrSupport: 3,
  isCustomer: 2,
  isRevver: 1,
} as const);

export const AcountLevels = Object.keys(AccountValues);
