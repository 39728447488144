import { forwardRef } from 'react';
import { Themed, ThemeUIStyleObject } from 'theme-ui';

import type { QuoteProps as Props, QuoteRef as Ref } from './types';

const Quote = forwardRef<Ref, Props>(
  (
    {
      col,
      children,
      ...props
    },
    ref
  ) => {
    if (!children) return null;

    const sx: ThemeUIStyleObject = {
      '&::before': {
        content: '"\\201C"',
        fontFamily: 'heading',
        fontWeight: 'heading',
        fontStyle: 'normal',
        fontSize: '4.25rem',
        lineHeight: 0,
      },

      '& p': { marginBlockStart: '-.75em' },

      'body &': col && { gridColumn: col },
    };

    return (
      <Themed.blockquote 
        sx={sx} 
        ref={ref} 
        {...props}
      >
        <p>{children}</p>
      </Themed.blockquote>
    );
  }
);

export default Quote;
