export const Asset = `#graphql
  fragment Asset on Asset {
    sys {
      id
    }
    url
    fileName
    description
    contentType
    width
    height
  }
`;
