export const GatedContentSection = `#graphql
  fragment GatedContentSection on GatedContentSection {
    ...Entry
    heading
    duration
    form{
        ...Form
    }
    copy{
        json
    }
    recordedOn
  }
`;
