import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    MODAL: {
      display: 'flex',
      position: 'fixed',
      justifyContent: 'center',
      alignitems: ['flex-start', 'center'],
      zIndex: 700,
      minBlockSize: '100%',
      blockSize: '100%',
      inlineSize: '100%',
      padding: ['0px', '0px', '4em'],
      overflowY: 'scroll',
      backgroundColor: 'rgba(0, 0, 0, 0.80)',
      top: '50%',
      insetBlockStart: '50%',
      left: '50%',
      insetInlineStart: '50%',
      transform: `translate(-50%,-50%) !important`,
    } as TSO,

    BUTTON_BLOCK: {
      blockSize: '100%',
      maxBlockSize: ['7em', '100%'],
    } as TSO,

    BUTTON_CLOSE: {
      position: ['absolute', null],
      top: ['10px', null],
      insetBlockStart: ['10px', null],
      left: ['10px', null],
      insetInlineStart: ['10px', null],
      marginInlineStart: '2.2em',
      marginBlockStart: '3.35em',
      inlineSize: '28px',
      blockSize: '28px',
      zIndex: 800,
      background: `url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28 2.82L25.18 0L14 11.18L2.82 0L0 2.82L11.18 14L0 25.18L2.82 28L14 16.82L25.18 28L28 25.18L16.82 14L28 2.82Z' fill='black'/%3E%3C/svg%3E")`,
    } as TSO,

    CONTAINER: {
      position: 'relative',
      display: ['flex', 'grid'],
      flexFlow: ['column nowrap', null],
      minBlockSize: ['100vh', 'fit-content'],
      paddingBlockStart: [6, 0],
      inlineSize: '100%',
      blockSize: 'fit-content',
      maxInlineSize: '62.5em',
      backgroundColor: 'surface.primary',
    } as TSO,

    CONTENT: {} as TSO,
  };

  return Styles;
};
