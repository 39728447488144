export const TileVariant = Object.freeze({
  ICON: 'Icon Tile',
  RESOURCE: 'Resource Tile',
  STATS: 'Stats Tile',
  TWITTER: 'Twitter Tile',
  TESTIMONIAL: 'Testimonial Tile',
  RATING: 'Rating Tile',
  BULLET: 'Bulleted Tile',
  SIMPLE: 'Simple Tile',
  CARD: 'Image Card',
  SLIDE: 'Slide Tile',
  FEATURE: 'Feature Tile',
  HORIZONTAL: 'Horizontal Tile',
  PRICE: 'Price Tile',
  COLOR: 'Color Tile',
} as const);

export const TILE_VARIANTS = Object.values(TileVariant);

export type TileVariants = typeof TILE_VARIANTS[number];
