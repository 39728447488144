import { ContentfulAnalyticsEvent } from '@lib/types';
import { MouseEventHandler } from 'react';
import { sendEvent } from './sendEvent';

/**
 * Creates a click handler for a link that sends an analytics event and then
 * navigates to a destination (if provided); includes logic to wait for the
 * analytics event to be sent before navigating
 * @param analyticsEvent The analytics event to send
 * @param ctaCopy The CTA copy to send with the event
 * @param path  The path to send with the event
 * @param destination The destination of the link clicked (if applicable)
 * @returns A click handler to be used on an anchor element
 */
export function getLinkClickHandlerWithTracking(
  analyticsEvent: ContentfulAnalyticsEvent | null | undefined,
  ctaCopy: string | null | undefined,
  path: string | null | undefined,
  destination: string | null | undefined
): MouseEventHandler<HTMLAnchorElement> {
  return async (event) => {
    if (destination) event.preventDefault();

    if (analyticsEvent) {
      await sendEvent(analyticsEvent, ctaCopy, path, destination);
    }

    if (destination) window.location.href = destination;
  };
}
