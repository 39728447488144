import { GraphQLClient, gql } from 'graphql-request';
import { compressGraphqlDocument } from './compressGraphql';
import {
  getContentfulEnvironmentId,
  isContentfulPreview,
} from './contentfulEnvironment';

interface Response {
  status: number;
  errors: ContentfulError[];
}
interface ContentfulError {
  extensions: {
    contentful: {
      code: string;
    };
  };
}

interface Error {
  response: Response;
  request: Record<string, string>;
}

const client = new GraphQLClient(
  `https://graphql.contentful.com/content/v1/spaces/${
    process.env.CONTENTFUL_SPACE_ID
  }/environments/${getContentfulEnvironmentId()}`,
  {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${
        isContentfulPreview()
          ? process.env.CONTENTFUL_PREVIEW_ACCESS_KEY
          : process.env.CONTENTFUL_ACCESS_KEY
      }`,
    },
  }
);

const sleep = (ms = 500) => {
  return new Promise((res) => setTimeout(res, ms));
};

/** This uses the GraphQL-request client to query Contentful.
 * You pass in a GraphQL query to the function, and it returns the response.
 * Request data using the GraphQL API. The compressGraphQLDocument function strips any whitespace or extra characters to limit the size of the query.
 */
export const request = async <Q = unknown>(query: string): Promise<Q> => {
  const variables = { preview: isContentfulPreview() };
  const doRequest = async (): Promise<Q> => {
    try {
      const data = await client.request<Q>(
        compressGraphqlDocument(
          gql`
            ${query}
          `
        ),
        variables
      );

      return data;
    } catch (error) {
      const { response } = error as Error;
      if (response && response.status === 429) {
        await sleep(1000);
        // Keep trying via recursion until the response isn't 429
        return doRequest();
      }
      /* eslint-disable-next-line no-console */
      console.error(error);
      return `GraphQLClient returned: ${response}` as unknown as Q;
    }
  };

  return doRequest();
};
