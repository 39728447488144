import * as Fragments from '@lib/graphql';
import {
  ContentfulBase,
  ContentfulPageContent,
  ContentfulQueryResult,
} from '@lib/types';
import { request } from '../request';

type ContentfulPageContentShallow = Omit<
  ContentfulPageContent,
  'navigation' | 'bodyContentCollection'
> & {
  navigation?: ContentfulBase | null;
  bodyContentCollection?: {
    total?: number;
  };
};

interface GetPageContentQueryResult extends ContentfulQueryResult {
  readonly pageContent?: ContentfulPageContentShallow;
}

/**
 * Fetches pageContent entry from Contentful by ID
 * @param pageContentId ID of the pageContent entry
 * @returns Promise; resolves to shallow page content data not including full
 * navigation or body content
 */
export const getPageContent = async (
  pageContentId?: string
): Promise<ContentfulPageContentShallow | undefined> => {
  const response = await request<GetPageContentQueryResult>(`#graphql
    query getPageContent($preview: Boolean) {
      pageContent(id: "${pageContentId}", preview: $preview) {
        ...Entry
        variant
        mode
        seo {
          ...Seo
        }
        # query ID only for navigation; get full data in separate
        # request to avoid query complexity limits
        navigation {
          sys {
            id
          }
        }
        hero {
          ...Hero
        }
        heroSpace
        # query total number of items only; get full data in separate
        # paginated request to avoid query complexity limits
        bodyContentCollection {
          total
        }
        pageSpacing
        footer {
          ...Footer
        }
        footerSpace
      }
    }
    ${Fragments.Entry}
    ${Fragments.Seo}
    ${Fragments.Hero}
    ${Fragments.Footer}
    ${Fragments.Asset}
    ${Fragments.Cta}
    ${Fragments.ModalCta}
    ${Fragments.ResponsiveMedia}
    ${Fragments.Tile}
    ${Fragments.Form}
    ${Fragments.FormInput}
    ${Fragments.CopyContent}
    ${Fragments.AnalyticsEvent}
    ${Fragments.FindYourService}
    ${Fragments.Service}
    ${Fragments.TabbedSection}
  `);

  if (response.errors) throw response.errors[0];

  return response.pageContent;
};
