export const PageVariant = {
  DEFAULT: 'Default',
  TWO_COLUMN: 'Two Column',
} as const;

export const PAGE_VARIANTS = Object.values(PageVariant);

export const PageSpacing = {
  Loose: '250px',
  Medium: '100px',
  Tight: '70px',
} as const;

export type PageVariants = typeof PAGE_VARIANTS[number];
export type SpacingVariant = keyof typeof PageSpacing;

export const DefaultSpace = {
  PAGE: PageSpacing.Loose,
  HERO: true,
  FOOTER: true,
};

export const ColorModes = ['Light', 'Dark'] as const;

export type ColorMode = typeof ColorModes[number];
