import type { Color } from "@lib/constants";
import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (backgroundColor?: Color): typeof Styles => {

  const buttonHoverColor = backgroundColor === 'Violet' || backgroundColor === "Black" ? 'lilac.1' : 'lilac.3';

  const Styles = {
    PROGRESS_DOTS: {
      border: '1px solid',
      borderColor: 'lilac.2',
      backgroundColor: 'lilac.2',
      inlineSize: '100%',
      maxInlineSize: '9em',
      blockSize: '6px',
      borderRadius: 'none',
      '&:first-of-type': {},
    } as TSO,

    PROGRESS_DOTS_SELECTED: {
      border: '1px solid',
      backgroundColor: 'lilac.9',
      borderColor: 'lilac.9',
      width: '100%',
      maxWidth: '9em',
      height: '6px',
    } as TSO,

    BUTTON: {
      display: 'flex',
      flex: '0 1 auto',
      backgroundColor: 'lilac.2',
      touchAction: 'manipulation',
      borderRadius: 0,
      padding: '1.2em',
      border: 'none',
      transition: 'all 0.2s',
      '&:disabled': {
        cursor: 'default',
        opacity: 0.5,
        pointerEvents: 'none',
      },
      '&:hover, &:focus': {
        backgroundColor: buttonHoverColor,
      },
      svg: {
        fill: 'lilac.9',
        inlineSize: '20px',
        blockSize: '20px',
      },
    } as TSO,

    SCREEN_READERS: {
      inlineSize: '1px',
      blockSize: '1px',
      position: 'absolute',
      overflow: 'hidden',
    } as TSO,
  };

  return Styles;
};
