export const TableRow = `#graphql
  fragment TableRow on TableRow {
    ...Entry
    cellsCollection(limit: 4){
      items{
        ...CopyContent
      }
    }
  }
`;
