import { forwardRef } from 'react';

import type { LogoProps as Props, LogoRef as Ref } from './types';

const Logo = forwardRef<Ref, Props>(({ clone = false, ...props }, ref) => {
  if (clone) {
    return (
      <svg viewBox="0 0 996 250" ref={ref} {...props} preserveAspectRatio="xMinYMin slice"><use href="#rev-logo" /></svg>
    );
  }

  return (
    <svg id="rev-logo" viewBox="0 0 996 250" ref={ref} {...props} preserveAspectRatio="xMinYMin slice"><title>Rev.com</title><g className="logotype"><path d="M361.9,235.7V17.1h62.5v34S449.5,25,474.6,17.6c21.1-6.2,55.8-2.7,69.3,2a307.3,307.3,0,0,0-18.6,25A181.3,181.3,0,0,0,509.7,73c-14.8.1-31,2.2-43.9,9.1-15.4,8.4-34.1,28.1-34.1,28.1V235.7Z" /><path d="M645.8,240.3c-19.7,0-37.4-3.1-52.7-9.1s-28-14.1-38.2-24.2a106,106,0,0,1-23.3-35.2,112.1,112.1,0,0,1-8-42c0-21.1,4.7-40.6,14-58.2s22.9-31.5,40.9-42.1,40.6-16,67.3-16,49.4,5.3,67.4,15.8S744.9,53.7,754,70.7A116.9,116.9,0,0,1,768,127c0,4.2-.2,8.5-.6,12.8-.2,1.8-.4,3.4-.6,5H598.2l.7,8.7c.7,9.6,3.6,17.7,8.8,24a42.3,42.3,0,0,0,18.8,13.4,61.2,61.2,0,0,0,22,4c9.9,0,19.5-2.1,33.4-13.6h74.6c-10.8,16.1-17.4,24-23.9,29.6a123.7,123.7,0,0,1-37.5,21.4C680.6,237.6,664.1,240.3,645.8,240.3ZM644.5,58.4a50.1,50.1,0,0,0-22.9,5.1A44.2,44.2,0,0,0,605,78.1a48.7,48.7,0,0,0-7.9,22.2l-1.1,9.1h96.9l-1.1-9.1a48.7,48.7,0,0,0-7.9-22.2,44.2,44.2,0,0,0-16.6-14.6A50,50,0,0,0,644.5,58.4Z" /><polygon points="839 235.7 764.5 17.1 835.2 17.1 883.9 183.9 933.1 17.1 995.7 17.1 921.1 235.7 839 235.7" /></g><path className="symbol" d="M141.6,125.1h29.5A45.3,45.3,0,1,0,125.8,171H241.2a129.8,129.8,0,0,1-8.1,16.8H125.8a62,62,0,1,1,62-62h29.6a91.6,91.6,0,1,0-91.6,91.5h83.5A125,125,0,1,1,250,125a128.2,128.2,0,0,1-1.1,16.5H125.8a15.8,15.8,0,0,1,0-31.5A15.8,15.8,0,0,1,141.6,125.1Z" /></svg>
  );
});

export default Logo;
