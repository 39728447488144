import { getOptimizeExperiments } from './getOptimizeExperiments';
import type { ContentfulAnalyticsEvent } from '@lib/types';

const camelCase = (text: string): string => {
  return text
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const sendEvent = async (
  { area, location, eventType, object }: OmitBase<ContentfulAnalyticsEvent>,
  ctaLabel?: string | null,
  path?: string | null,
  destination?: string | null
): Promise<void> => {
  // accounting for Analytics Event entries that have not been updated to new options
  const linkTypes = ['Button', 'button', 'Link', 'link'];
  const action = linkTypes.includes(object)
    ? `link_clicked`
    : `${camelCase(object)}_${camelCase(eventType)}`;
  const hostName = window.location.host;

  const activeExperiments = await getOptimizeExperiments();

  return new Promise((resolve) => {
    if (ctaLabel) {
      window.analytics.track(
        action,
        {
          path,
          location,
          area,
          linkType: camelCase(object),
          linkText: ctaLabel,
          destination,
          hostName,
          source: 'site_revCom',
          activeExperiments,
        },
        {},
        resolve
      );
    }

    resolve();
  });
};
