import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    ROOT: {
      inlineSize: '100%',
      backgroundColor: 'surface.contrast',
      minBlockSize: ['fitContent', '40px'],
      display: 'flex',
      justifyContent: 'center',
    } as TSO,

    CONTAINER:{
      color: 'text.contrast',
      lineHeight: 1.2,
      paddingBlockEnd: 2,
      paddingInlineStart: 6,
      paddingInlineEnd: 4,
      textAlign: 'center',
      inlineSize: '90%',
      '& a > p, p': {
        letterSpacing: 'tiny',
        fontWeight: 'bold',
        fontSize: '.75rem',
        transition: 'color .2s'
      },

      '& a:hover, & a:focus': {
        color: 'violet.4',
      }
    } as TSO,

    CLOSE: {
      alignSelf: 'center',
      outline: '0',
      maxInlineSize: 1,
      maxBlockSize: 1,
      color: 'white',
      ':hover, :focus':{
        color: 'violet.4'
      }
    } as TSO,
  };

  return Styles;
};
