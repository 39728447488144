export const Cta = `#graphql
  fragment Cta on Cta {
    ...Entry
    ctaCopy
    destination
    message
    appleButton
    googleButton
    analyticsEvent {
      ...AnalyticsEvent
    }
    openInNewTab
  }
`;
