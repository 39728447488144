import { propsValidator } from '@lib/utils';
import { useStyles } from './pricetile.styles';
import { H3, H4 } from '#ui';
import RichText from '@components/RichText';
import AppButton from '@components/AppButton';

import type { FC } from 'react';
import type { PriceTileProps as Props } from './types';

const PriceTile: FC<Props> = ({
  heading,
  cta,
  tileCopy,
  price,
  secondaryCopy,
  checkBulletStyle,
  ...entry
}) => {
  const valid = propsValidator(entry, { heading });

  if (!valid) return null;

  const Styles = useStyles();

  return (
    <article sx={Styles.ROOT}>
      <div sx={Styles.CARD_TOP}>
        <H3 sx={Styles.HEADING}>{heading}</H3>
        {!!tileCopy?.json && (
          <div sx={Styles.COPY}>
            <RichText
              {...tileCopy}
              checkBulletStyle={!!checkBulletStyle}
              smallCheckBullets
            />
          </div>
        )}
      </div>
      <div sx={Styles.CARD_BTM}>
        {price && (
          <H4 sx={Styles.PRICE}>
            {price} <span>per minute</span>
          </H4>
        )}
        {!!secondaryCopy?.json && (
          <div sx={Styles.COPY}>
            <RichText
              {...secondaryCopy}
              checkBulletStyle={!!checkBulletStyle}
              smallCheckBullets
            />
          </div>
        )}
        {cta?.destination && (
          <div sx={Styles.BUTTON_CONTAINER}>
            <AppButton {...cta}>{cta.ctaCopy}</AppButton>
          </div>
        )}
      </div>
    </article>
  );
};

export default PriceTile;
