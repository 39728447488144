import { ColorHex } from '@lib/constants';
import type { ThemeUIStyleObject as TSO } from 'theme-ui';
import { ColorTileStyleTypes } from './types';

export const useStyles = ({backgroundColor}: ColorTileStyleTypes): typeof Styles => {
  const violetCard = backgroundColor === 'Violet';

  const Styles = {
    ROOT: {
      borderRadius: 2,
      backgroundColor: ColorHex[backgroundColor || 'LightGrey'],
      padding: [3, 4],
      color: violetCard && ColorHex.White,
    } as TSO,

    ICON: {
      maxInlineSize: '3.5rem',
      marginBlockEnd: 2,
    } as TSO,

    HEADING: {
      fontWeight: ['semiBold', 'semiBold'],
      fontSize: [5, 5],
      a: {
        color: violetCard && ColorHex.Lilac,
        textDecoration: 'none',
        transition: 'all 2s',
        ':hover' :{
          textDecoration: 'underline'
        }
      }
    } as TSO
  };

  return Styles;
};