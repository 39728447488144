import { propsValidator } from '@lib/utils';
import AppButton from '@components/AppButton';
import { Pill, H3, Link } from '#ui';
import RichText from '@components/RichText';
import type { FC } from 'react';
import type { SimpleTileProps as Props } from './types';

const SimpleTile: FC<Props> = ({ heading, cta, primaryCtaColor, pills, tileCopy, secondaryCtas, ...entry }) => {
  const valid = propsValidator(entry, { heading });

  if (!valid) return null;

  return (
    <article
      sx={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-start',
        transition: 'opacity 0.2s ease-in-out',
      }}
    >
      <H3 sx={{ m: '0em', color: 'grey.9' }}>
        {heading}
      </H3>
      <div
        sx={{
          display: 'flex',
          flexFlow: 'row wrap',
          'p:not(:last-child)': {
            marginInlineEnd: 1,
          },
        }}
      >
        {!!pills?.length &&
          pills.map((pill, index) => <Pill key={index}>{pill}</Pill>)}
      </div>
      {!!tileCopy?.json && (
        <div
          sx={{
            paddingBlockStart: 1,
            paddingBlockEnd: 1,
            '.richText': { fontSize: 2, color: 'text.primary' },
          }}
        >
          <RichText {...tileCopy} />
        </div>
      )}
      {cta?.destination && <AppButton color={primaryCtaColor} {...cta}>{cta.ctaCopy}</AppButton>}
      {!!secondaryCtas?.items.length &&
        secondaryCtas.items.map((item, key) => 
          { if (item.destination) 
            return <Link
              key={key}
              withIcon
              href={item.destination}
              sx={cta?.ctaCopy ? { paddingBlockStart: 3 } : {}}
              openInNewTab={item.openInNewTab}
            >
              {item.ctaCopy}
            </Link>
          }
      )}
    </article>
  );
};

export default SimpleTile;
