import { FC } from 'react';
import RichText from '@components/RichText';
import { H4 } from '#ui';
import { useStyles } from './slidetile.styles';
import type { SlideProps as Props } from './types';

const SlideTile: FC<Props> = ({ heading, tileCopy }) => {
  const Styles = useStyles();

  return (
    <article sx={Styles.ROOT}>
      <H4 as='h3' sx={Styles.HEADING}>{heading}</H4>
      {!!tileCopy && (
        <RichText {...tileCopy} />
      )}
    </article>
  );
};

export default SlideTile;
