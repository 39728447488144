import { HTMLAttributes } from 'react';

/**
 * @function useSanitizer
 * @description Removes unwanted props for the DOM
 */

type Props = {
  [prop: string]: unknown;
};

type Base = HTMLAttributes<HTMLElement> & Props;

export const useSanitizer = <T, H extends HTMLElement>(
  props: T,
  additional?: Array<string>
): HTMLAttributes<H> => {
  const list = [
    '__typename',
    'sys',
    'contentfulMetadata',
    'index',
    ...(additional || []),
  ];

  list.forEach((item) => {
    if (item in props) {
      delete (props as T & Base)[item];
    }
  });

  return props as HTMLAttributes<H>;
};
