export const VideoSection = `#graphql
  fragment VideoSection on VideoSection {
    ...Entry
    heading
    text: copy
    video {
      ...ResponsiveMedia
    }
    animation {
      ...ResponsiveMedia
    }
    image {
      ...ResponsiveMedia
    }
    cta {
      ...Cta
    }
  }
`;
