/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react';
import { P } from '#ui';
import { createId, getLinkClickHandlerWithTracking } from '#utils';
import { useStyles } from '../nav.styles';
import type { FC } from 'react';
import type { NavCTAProps as Props } from '../types';
import type { NavStyles, TSO } from '../nav.styles';

const CTA: FC<Props> = ({
  destination,
  message,
  ctaCopy,
  parent,
  currentPage,
  openInNewTab = false,
  group = false,
  analyticsEvent,
}) => {
  if (!destination) return null;

  const Styles: NavStyles = useStyles({});
  const style: TSO = group ? Styles.GROUP_LEVEL_CTA : Styles.TOP_LEVEL_CTA;
  const Heading = group ? 'h5' : 'h3';
  const id: string = createId(ctaCopy || '');
  const [path, setPath] = useState<string | null>(null);

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  const handleClickLink = getLinkClickHandlerWithTracking(
    analyticsEvent,
    ctaCopy,
    path,
    destination
  );

  return (
    <div sx={style} className={message ? '__hasMessage' : ''}>
      {message ? (
        <div>
          <Heading>{ctaCopy}</Heading>
          <P>
            {message}
            <a
              className="__hasActiveArea"
              target={openInNewTab ? '_blank' : undefined}
              rel={openInNewTab ? 'noopener noreferrer' : undefined}
              href={destination}
              aria-current={currentPage === destination ? 'page' : undefined}
              data-related={parent}
              data-testid={id}
              onClick={handleClickLink}
            >
              <span className="global-sr">{ctaCopy}</span>
            </a>
          </P>
        </div>
      ) : (
        <div>
          <Heading>
            <a
              className="__hasActiveArea"
              href={destination}
              target={openInNewTab ? '_blank' : undefined}
              rel={openInNewTab ? 'noopener noreferrer' : undefined}
              aria-current={currentPage === destination ? 'page' : undefined}
              data-related={parent}
              onClick={handleClickLink}
            >
              {ctaCopy}
            </a>
          </Heading>
        </div>
      )}
    </div>
  );
};

export default CTA;
