import type { FC } from 'react';
import type { ThemeUIStyleObject } from 'theme-ui';
import type { BlockProps as Props } from './types';

const Block: FC<Props> = ({ col, children, as = 'div', ...props }) => {
  const rootSX: ThemeUIStyleObject = { 'body &': col && { gridColumn: col } };

  switch (as) {
    case 'section':
      return (
        <section sx={rootSX} {...props}>
          {children}
        </section>
      );
    case 'main':
      return (
        <main sx={rootSX} {...props}>
          {children}
        </main>
      );
    case 'header':
      return (
        <header sx={rootSX} {...props}>
          {children}
        </header>
      );
    case 'footer':
      return (
        <footer sx={rootSX} {...props}>
          {children}
        </footer>
      );
    case 'nav':
      return (
        <nav sx={rootSX} {...props}>
          {children}
        </nav>
      );
    case 'aside':
      return (
        <aside sx={rootSX} {...props}>
          {children}
        </aside>
      );
    case 'article':
      return (
        <article sx={rootSX} {...props}>
          {children}
        </article>
      );
    case 'quote':
      return (
        <blockquote sx={rootSX} {...props}>
          {children}
        </blockquote>
      );
    default:
      return (
        <div sx={rootSX} {...props}>
          {children}
        </div>
      );
  }
};

export default Block;
