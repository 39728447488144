import { FC, useEffect } from 'react';
import BlackboxContent from '@components/BlackboxContent';
import { createPortal } from 'react-dom';
import { useStyles } from './modal.styles';
import { Block, Grid } from '#ui';
import type { ModalProps as Props } from './types';

const Modal: FC<Props> = ({ modalContent, onClose, ...props }) => {
  const Styles = useStyles();

  useEffect(() => {
    const close = (e: { key: string }) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', close);

    return () => window.removeEventListener('keydown', close);
  }, []);

  return createPortal(
    <dialog
      id="modalDialog"
      sx={Styles.MODAL}
      {...props}
      onClick={onClose}
      aria-modal="true"
    >
      <Grid
        as="section"
        sx={Styles.CONTAINER}
        onClick={(e) => e.stopPropagation()}
      >
        <Block sx={Styles.BUTTON_BLOCK}>
          <button
            sx={Styles.BUTTON_CLOSE}
            onClick={onClose}
            aria-label="close"
          />
        </Block>
        <Block col={[null, '2/-1']} sx={Styles.CONTENT}>
          <BlackboxContent content={modalContent} />
        </Block>
      </Grid>
    </dialog>,
    document.querySelector('#modal-root') as HTMLElement
  );
};

export default Modal;
