import { forwardRef } from 'react';
import { Pill } from '../Pill';
import { Badge } from '../Badge';
import { Link } from '../Link';
import { Button, H4, StarRating } from '#ui';
import { useStyles } from './card.styles';
import RichText from '@components/RichText';
import { useSanitizer } from '../../utils';

import type { CardProps as Props, CardRef as Ref } from './types';

const Card = forwardRef<Ref, Props>(
  (
    {
      heading,
      tileCopy,
      checkBulletStyle,
      responsiveMedia,
      pills,
      flag,
      col,
      cta,
      primaryCtaColor,
      secondaryCtas,
      rating,
      ratingCopy,
      ...props
    },
    ref
  ) => {
    if (!heading) return null;

    const Styles = useStyles({ col });
    const attrs = useSanitizer(props, [
      'testimonialJobTitle',
      'simpleIcon',
      'twitterSource',
      'resourceType'
    ]);
    const img = responsiveMedia?.desktopAsset || responsiveMedia?.mobileAsset;

    return (
      <article sx={{ ...Styles.ROOT }} ref={ref} {...attrs}>
        <div sx={Styles.CARD}>
          {img?.url && 
            <img
              src={img.url}
              alt={img.description || ''}
              sx={Styles.IMAGE}
              loading="lazy"/>
          }
          <div sx={Styles.CONTENT}>
            {!!flag && <Badge>{flag}</Badge>}
            <H4 as='h3'>{heading}</H4>
            {!!pills?.length &&
                pills.map((pill) => <Pill key={pill}>{pill}</Pill>)}
            {!!rating && (
              <StarRating
                rating={rating}
                ratingCopy={ratingCopy}
                card={true}
              />
            )}
            {!!tileCopy && <RichText {...tileCopy} checkBulletStyle={!!checkBulletStyle}/>}
            {!!cta?.destination && (
              <div sx={Styles.BUTTON_CONTAINER}>
                <Button
                  href={cta.destination}
                  color={primaryCtaColor}
                  sx={Styles.LINK}
                >
                  {cta.ctaCopy}
                </Button>
              </div>
            )}
          </div>
        </div>
        {!!secondaryCtas?.items.length &&
          <div sx={Styles.SECONDARY_CTA_CONTAINER}>
            {secondaryCtas.items.map((item, key) => (
              <Link
                key={key}
                sx={Styles.SECONDARY_CTA}
                href={item.destination || undefined}
                openInNewTab={item.openInNewTab}
              >
                {item.ctaCopy}
              </Link>
          ))}
          </div>
        }
      </article>
    );
  }
);

export default Card;
