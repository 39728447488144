export const TabbedSection = `#graphql
  fragment TabbedSection on TabbedSection {
    ...Entry
    heading
    superheading
    variant
    primaryCopy
    secondaryCopy
    tabsCollection(limit: 6) {
      items {
        ...Tile
      }
    }
  }
`;
