import { ContentfulQueryResult, ContentfulSiteBanner } from '@lib/types';
import { request } from '../request';

export interface GetSiteBannerQueryResult extends ContentfulQueryResult {
  siteBannerCollection: {
    items: (ContentfulSiteBanner | undefined)[];
  };
}

export const getSiteBanner = async (): Promise<ContentfulSiteBanner | null> => {
  const response = await request<GetSiteBannerQueryResult>(`#graphql
      query getSiteBanner($preview: Boolean) {
        siteBannerCollection(
          where: { bannerActive: true }
          limit: 1
          preview: $preview
        ) {
          items {
            bannerActive
            copy
            cta {
              destination
              openInNewTab
              analyticsEvent {
                area
                location
                eventType
                object
              }
            }
          }
        }
      }
  `);

  if (response.errors) throw response.errors[0];

  return response.siteBannerCollection.items[0] || null;
};
