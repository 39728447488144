import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    ROOT: {
      counterReset: 'section',
      listStyleType: 'none',
      marginInlineStart: '0px !important',
      paddingInlineStart: '0px !important',
      '& li::before': {
        display: 'inline-block',
        content: 'counters(section, ".") ". "',
        counterIncrement: 'section',
        marginInlineEnd: '1em',
      },
      '& li p': {
        display: 'inline',
      },

      ol: {
        listStyleType: 'none',
        textIndent: '6em',
        '& li::before': {
          position: 'absolute',
          display: 'inline-block',
          content: 'counters(section, ".") " "',
          counterIncrement: 'section',
          marginInlineStart: '-10em',
          marginBlockStart: '.15em',
        },
      },
      '& li': {
        display: 'block',
        marginBlockEnd: '.5em',
      },
    } as TSO,
  };

  return Styles;
};
