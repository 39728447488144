export const NavGroup = `#graphql
  fragment NavGroup on NavGroup {
    ...Entry
    heading
    copy
    contentCollection(limit:8) {
      items {
        ...Cta
        ...SecondaryNavPane
      }
    }
  }
`;
