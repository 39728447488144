import type { ThemeUIStyleObject as TSO } from 'theme-ui';
import type { CouponBannerStyles as Props } from './types';

export const useStyles = ({ navPosition }: Props): typeof Styles => {
  const Styles = {
    ROOT: {
      inlineSize: '100%',
      backgroundColor: 'surface.contrast',
      minBlockSize: ['fitContent', '40px'],
      display: 'flex',
      justifyContent: 'center',
      position: 'sticky',
      top: navPosition === 'relative' ? '0px' : ['80px', '90px', '100px'],
      zIndex: 98,

      '& p': {
        color: 'text.contrast',
        fontSize: '.75rem',
        fontWeight: 'bold',
        lineHeight: 1.2,
        paddingBlockStart: 2,
        paddingBlockEnd: 2,
        paddingInlineStart: 6,
        paddingInlineEnd: 4,
        textAlign: 'center',
        letterSpacing: 'tiny',
        inlineSize: '90%',
        marginBlockStart: '0px',
      },
    } as TSO,
  };

  return Styles;
};
