import { Icon } from '#ui';
import { createId } from '#utils';
import { useStyles } from '../nav.styles';

import type { FC } from 'react';
import type { RootButtonProps as Props } from '../types';
import type { NavStyles } from '../nav.styles';

const RootButton: FC<Props> = ({
  heading,
  selected,
  onClick,
  onKeyPress,
}) => {
  if (!heading) return null;

  const Styles: NavStyles = useStyles({});
  const id: string = createId(heading!);

  return (
    <button
      sx={Styles.ROOT_BUTTON}
      id={id}
      className={selected ? '--active' : ''}
      role="tab"
      type="button"
      aria-selected={selected}
      aria-haspopup="true"
      aria-expanded={selected}
      aria-controls={`${id}-pane`}
      data-focus={heading}
      data-related="Main Menu"
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      <span>{heading}</span>
      <Icon as="Right Chevron" />
    </button>
  )
};

export default RootButton;
