import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    ROOT: {
      textAlign: 'left',
      paddingBlockStart: [4, 5],
      paddingBlockEnd: [4, 5],
      minBlockSize: '100%',
      position: 'relative',
      '::before': {
        content: '""',
        position: 'absolute',
        top: '0px',
        insetBlockStart: '0px',
        background: 'linear-gradient(90deg, #3F00FF, #8D57FF 112%)',
        blockSize: '0.5em',
        inlineSize: '100%',
      },
      p: {
        fontSize: 1,
      },
    } as TSO,

    HEADING: {
      color: 'text.secondary',
    } as TSO,
  };

  return Styles;
};
