/**
 * @file Provides a way to get contrast colors.
 */

/**
 * The original TypeScript type definition for 'Array.prototype.includes()' is too narrow.
 * The whole point of this method is to determine "whether an array includes a certain value among
 * its entries, returning true or false as appropriate", regardless of the type of the array.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/includes
 */
declare global {
  interface Array<T> {
    includes(searchElement: unknown, fromIndex?: number): searchElement is T;
  }
}

import { ColorHex, ColorName } from '../constants/colors';

export type ContrastColor =
  | typeof ColorHex.Black
  | typeof ColorHex.White
  | typeof ColorHex.Text
  | typeof ColorHex.Chartreuse
  | typeof ColorHex.NeonBlue
  | typeof ColorHex.NeonLilac
  | typeof ColorHex.Lilac
  | typeof ColorHex.LightLilac
  | typeof ColorHex.DarkGrey;

interface ContrastReturn {
  text: ContrastColor;
  ui: {
    bkg: ContrastColor;
    text: ContrastColor;
    action: {
      bkg: ContrastColor;
    };
  };
  link: {
    text: ContrastColor;
    action: {
      text: ContrastColor;
    };
  };
  select: ContrastColor;
}

export type UseContrast = (x?: unknown) => ContrastReturn;

/**
 * @name useContrast
 * Returns a map of contrast scenarios for a given background color.
 */
export const useContrast: UseContrast = (bkg) => {
  const _bkg = bkg || ColorName.DEFAULT;
  const linkDefault = _bkg === ColorName.DEFAULT || _bkg === ColorName.WHITE;

  const standard = [
    ColorName.DEFAULT,
    ColorName.SECONDARY,
    ColorName.NEUTRAL,
    ColorName.CHARTREUSE,
    ColorName.WHITE,
  ].includes(_bkg);

  const special = [
    ColorName.TEAL,
    ColorName.LILAC,
    ColorName.ERROR,
    ColorName.SUCCESS,
  ].includes(_bkg);

  return {
    text: standard || special ? ColorHex.Text : ColorHex.White,
    ui: {
      bkg: standard ? ColorHex.Black : ColorHex.White,
      text: standard ? ColorHex.White : ColorHex.Black,
      action: {
        bkg: standard
          ? ColorHex.NeonBlue
          : special
          ? ColorHex.NeonLilac
          : ColorHex.Lilac,
      },
    },
    get link() {
      return {
        text: linkDefault ? ColorHex.NeonBlue : this.ui.bkg,
        action: {
          text: linkDefault ? ColorHex.Black : this.ui.action.bkg,
        },
      };
    },
    select: standard ? ColorHex.Chartreuse : ColorHex.NeonLilac,
  };
};
