import {
  ContentfulPageContent,
  ContentfulQueryResult,
  PageBodyContentUnion,
} from '@lib/types';
import { request } from '@lib/utils';
import * as fragments from './bodyContentFragments';

interface PageBodyContentQueryResult extends ContentfulQueryResult {
  pageContent?: Pick<ContentfulPageContent, 'bodyContentCollection'> | null;
}

/**
 * Gets a single page of body content items for a given page content ID
 */
const getNextPageBodyContent = async (
  pageContentId: string,
  limit: number,
  skip = 0
): Promise<PageBodyContentUnion[]> => {
  // Allows dynamic fragments for body content components to facilitate
  // splitting up query
  const getQuery = (
    queryName: string,
    componentFragmentNames: string[],
    fragmentDeclarations: Record<string, string>
  ) => {
    const allFragmentDeclarations = {
      ...fragmentDeclarations,
      ...fragments.dependenciesCommon,
    };

    return `#graphql
      query ${queryName}($preview: Boolean) {
        pageContent (
          id: "${pageContentId}"
          preview: $preview
        ) {
          bodyContentCollection(limit: ${limit}, skip: ${skip}) {
            items {
              ${componentFragmentNames.map((n) => `...${n}`).join('\n')}
            }
          }
        }
      }
      ${Object.values(allFragmentDeclarations).join('\n')}
    `;
  };

  // Split into 2 requests to avoid query complexity limit (a lot of large
  // fragments being used here)
  const responseAtoL = await request<PageBodyContentQueryResult>(
    getQuery('getPageBodyContentAtoL', Object.keys(fragments.AtoL), {
      ...fragments.AtoL,
      ...fragments.dependenciesAtoL,
    })
  );
  const responseMtoZ = await request<PageBodyContentQueryResult>(
    getQuery('getPageBodyContentMtoZ', Object.keys(fragments.MtoZ), {
      ...fragments.MtoZ,
      ...fragments.dependenciesMtoZ,
    })
  );

  if (responseAtoL.errors) throw responseAtoL.errors[0];
  if (responseMtoZ.errors) throw responseMtoZ.errors[0];

  const itemsAtoL =
    responseAtoL.pageContent?.bodyContentCollection?.items || [];
  const itemsMtoZ =
    responseMtoZ.pageContent?.bodyContentCollection?.items || [];

  return itemsAtoL.map((item, i) =>
    Object.keys(item).length ? item : itemsMtoZ[i]
  );
};

const maxPerPage = 12;

/**
 * Fetches all body content items for a given page content ID
 * @param pageContentId ID of the page content to fetch body content for
 * @param bodyContentTotal Total number of body content items for the page (used
 * for pagination)
 * @returns Promise; resolves to array of body content items
 */
export const getPageBodyContent = async (
  pageContentId?: string,
  bodyContentTotal = maxPerPage
): Promise<PageBodyContentUnion[] | undefined> => {
  if (!pageContentId) return [];

  const pageCount =
    bodyContentTotal > maxPerPage
      ? Math.ceil(bodyContentTotal / maxPerPage)
      : 1;

  const bodyContentItemPages = await Promise.all(
    Array(pageCount)
      .fill(null)
      .map(async (_, index) =>
        getNextPageBodyContent(pageContentId, maxPerPage, index * maxPerPage)
      )
  );

  return bodyContentItemPages.flat();
};
