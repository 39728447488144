import Icon from './Icon';
import Testimonial from './Testimonial';
import SimpleTile from './SimpleTile';
import SlideTile from './SlideTile';
import Feature from './Feature';
import ResourceTile from './Resource';
import HorizontalTile from './Horizontal';
import PriceTile from './PriceTile';
import ColorTile from './ColorTile';
import { TileVariant } from '#constants';
import { Card } from '#ui';

import type { FunctionComponent } from 'react';
import type { TileProps as Props } from './types';

const Tile: FunctionComponent<Props> = ({ variant, ...props }) => {
  switch (variant) {
    case TileVariant.ICON: {
      return <Icon {...props} />;
    }
    case TileVariant.TESTIMONIAL: {
      return <Testimonial {...props} />;
    }
    case TileVariant.SIMPLE: {
      return <SimpleTile {...props} />;
    }
    case TileVariant.CARD: {
      return <Card {...props} />;
    }
    case TileVariant.SLIDE: {
      return <SlideTile {...props} />;
    }
    case TileVariant.FEATURE: {
      return <Feature {...props} />;
    }
    case TileVariant.RESOURCE: {
      return <ResourceTile {...props} />;
    }
    case TileVariant.HORIZONTAL: {
      return <HorizontalTile {...props} />;
    }
    case TileVariant.PRICE: {
      return <PriceTile {...props} />;
    }
    case TileVariant.COLOR: {
      return <ColorTile {...props} />;
    }
    default: {
      throw `Prop variant is incorrect, its value is ${variant}`;
    }
  }
};

export default Tile;
