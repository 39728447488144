import { forwardRef } from 'react';
import { Themed, ThemeUIStyleObject } from 'theme-ui';

import type { HeadingProps as Props, HeadingRef as Ref } from './types';

const H4 = forwardRef<Ref, Props>(({
  col,
  children,
  as = 'h4',
  ...props
}, ref) => {
  if (!children) return null;

  const sx: ThemeUIStyleObject = {
    'body &': col && { gridColumn: col },
  };

  return (
    <Themed.h4
      sx={sx}
      as={as}
      ref={ref} 
      {...props}
    >
      {children}
    </Themed.h4>
  );
});

export default H4;
