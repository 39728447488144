export const HorizontalCta = `#graphql
  fragment HorizontalCta on HorizontalCta {
    ...Entry
    heading
    cta{
      ...Cta
    }
    backgroundColor
    variant
    image {
      ...ResponsiveMedia
    }
    tilesCollection(limit:6) {
      items {
        ...Tile
      }
    }
  }
`;
