type CreateId = (str: string) => string;

export const createId: CreateId = (str) => {
  switch (str) {
    case 'Job Function':
      return 'job_function';

    case 'Message':
      return 'form_notes__c';

    case 'Estimated Monthly Order Volume':
      return 'estimated_monthly_order_volume';

    case 'What else Should we Know?':
      return 'message';

    default:
      return str
        .replace(/(?!\s)\W/g, '')
        .replace(/\s+/g, '') // ? Why was this modified to not use dashes?
        .toLowerCase();
  }
};
