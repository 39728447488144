import { ColorHex } from '@lib/constants';
import type { ThemeUICSSObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    ROOT: {
      contain: 'layout',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 2,
      border: 'solid 1px',
      borderColor: 'grey.3',
      overflow: 'hidden',
    } as TSO,

    CARD_TOP: {
      padding: 2,
      paddingBlockStart: 4,
      flexGrow: 1,
    } as TSO,

    HEADING: {
      fontSize: [4, 4],
      fontWeight: ['semiBold', 'semiBold'],
    } as TSO,

    COPY: {
      marginBlockStart: 1,
      p:{
        fontSize: 1,
      },
    } as TSO,
    
    CARD_BTM: {
      backgroundColor: ColorHex.LightGrey,
      padding: 2,
      paddingBlockEnd: 4,
    } as TSO,

    PRICE: {
      color: ColorHex.NeonBlue,
      fontFamily: 'heading',
      fontWeight: 'semiBold',
      fontSize: [5, 4],
      lineHeight: 'button',
      paddingInlineStart: '0px',
      span:{
        fontSize: 0,
        display: 'inline-block'
      }
    } as TSO,

    BUTTON_CONTAINER: {
      marginBlockStart: 3,
      a: {
        inlineSize: '100%',
      }
    } as TSO,
  };

  return Styles;
};