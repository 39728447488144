import { forwardRef } from 'react';
import { Themed, ThemeUIStyleObject } from 'theme-ui';
import { useContrast } from '#hooks';
import { ColorHex } from '#constants';
import { Icon } from '../Icon';

import type { LinkProps as Props, LinkRef as Ref } from './types';

const Link = forwardRef<Ref, Props>(
  (
    {
      href,
      children,
      variant = 'Text',
      openInNewTab = false,
      withIcon = false,
      contrast = 'Default',
      ...props
    },
    ref
  ) => {
    if (!children || !href) return null;

    const { ui, link } = useContrast(contrast);
    const inline: boolean = !withIcon && variant === 'Text';
    const simple: boolean = variant === 'Simple';
    const heading: boolean = !withIcon && variant === 'Heading';
    const nonStdBkgColor = contrast !== 'Default' && contrast !== 'White' && contrast !== 'Neutral';
    const standalone: boolean = variant === 'Standalone' || withIcon;
    const color = standalone ? link.text : 'inherit';

    const underline =
      heading
        ? {
            verticalAlign: 'bottom',
            textDecoration: 'underline',
            textDecorationThickness: '5px',
            textDecorationColor: ColorHex.NeonBlue,
            textUnderlineOffset: '.25em',
            /* Firefox Only */
            '@supports (-moz-appearance: none)': {
              textUnderlineOffset: '.25em',
            },
          }
        : inline
        ? {
            verticalAlign: 'bottom',
            textDecoration: 'underline',
            color: nonStdBkgColor ? 'inherit' : ColorHex.NeonBlue,
            textDecorationThickness: '1px',
            textDecorationColor: nonStdBkgColor ? 'inherit' : 'transparent',
            textUnderlineOffset: '.2rem',
            /* Firefox Only */
            '@supports (-moz-appearance: none)': {
              textUnderlineOffset: '.2rem',
            },
          }
        : {};

    const sx: ThemeUIStyleObject = {
      fontFamily: withIcon && 'heading',
      display: standalone ? 'inline-block' : 'inline',
      marginInlineEnd: withIcon && 4,
      outline: 0,
      color,
      font: (inline || heading) && 'inherit',
      fontSize: simple && 0,
      fontWeight: simple && 'regular',
      lineHeight: simple && 1.4,
      transition: 'all .2s',
      whiteSpace: heading && 'nowrap',
      ...underline,

      '&:hover, &:focus': {
        textDecorationColor: nonStdBkgColor ? ui.action.bkg : ColorHex.NeonBlue,
        color: standalone ? link.action.text : nonStdBkgColor ? ui.action.bkg : ColorHex.NeonBlue,

        '& svg': { transform: 'translateX(25%)' },
      },

      '& > *': { verticalAlign: 'baseline' },

      '& svg': withIcon && {
        verticalAlign: 'middle',
        display: 'inline',
        inlineSize: '1.2rem',
        blockSize: '1.2rem',
        marginBlockStart: '-2px',
        marginInlineStart: 0,
        transition: 'transform .2s',
      },
    };

    return (
      <Themed.a
        ref={ref}
        href={href}
        target={openInNewTab ? '_blank' : undefined}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
        sx={sx}
        {...props}
      >
        {children}
        {withIcon && <Icon as="Right Arrow" />}
      </Themed.a>
    );
  }
);

export default Link;
