import { useStyles } from './styles';
import { HighlightText as Props } from './types';

const HighlightText = ({bkg, children, ...props}: Props): JSX.Element => {
  const Styles = useStyles(bkg);
  return (
    <span sx={Styles.ROOT} {...props}>
      {children}
    </span>
  );
};
export default HighlightText;
