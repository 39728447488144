import { forwardRef } from 'react';
import { Themed, ThemeUIStyleObject } from 'theme-ui';
import { useContrast } from '../../../lib/hooks';

import type { InlineTextProps as Props, InlineRef as Ref } from './types';

const InlineText = forwardRef<Ref, Props>(
  (
    {
      col,
      as = 'span',
      contrast = 'White',
      children,
      ...props
    },
    ref
  ) => {
    if (!children) return null;

    const { text: color } = useContrast(contrast);

    const sx: ThemeUIStyleObject = {
      color,
      'body &': col && { gridColumn: col },
    };

    switch (as) {
      case 'q': {
        return (
          <Themed.i
            sx={{
              '&::before, &::after': { content: 'none' },
              ...sx,
            }}
            as="q"
            ref={ref}
            {...props}
          >
            {children}
          </Themed.i>
        )
      }
      case 'small': {
        return (
          <small 
            sx={sx}
            ref={ref}
            {...props}
          >
            {children}
          </small>
        )
      }
      case 'span':
      default: {
        return (
          <span
            sx={sx}
            ref={ref}
            {...props}
          >
            {children}
          </span>
        )
      }
    };
  }
);

export default InlineText;
