import {
  Asset,
  Entry,
  Cta,
  InPageAnchor,
  InPageNavigation,
  ResponsiveMedia,
  Tile,
  TileGrid,
  CopyImageSection,
  CopyContent,
  FormInput,
  Form,
  TabbedSection,
  AccordionSection,
  StatementSection,
  LargeTextSection,
  ListContentSection,
  VerticalCtaSection,
  VideoSection,
  HorizontalCta,
  LargeCtaSection,
  ThreeFeatureSection,
  MoreDetails,
  StepByStepSection,
  Step,
  TranslationPriceSection,
  ResourceFeatureSection,
  TileCarousel,
  VideoCollageSection,
  StatFeatureSection,
  LogoGroup,
  LogoBar,
  FeaturedAnimationSection,
  Table,
  TableRow,
  GatedContentSection,
  AnalyticsEvent,
  EmbedSection,
  TileBar,
  InPageBanner,
  ToggleContentSection,
} from '@lib/graphql';

// Lower-level fragments that are referenced by component fragments in both
// groups below
export const dependenciesCommon = {
  AnalyticsEvent,
  Cta,
  Entry,
  ResponsiveMedia,
  Tile,
  Asset,
};

// First group of body content component fragments
export const AtoL = {
  AccordionSection,
  CopyContent,
  CopyImageSection,
  EmbedSection,
  FeaturedAnimationSection,
  Form,
  GatedContentSection,
  HorizontalCta,
  InPageAnchor,
  InPageBanner,
  InPageNavigation,
  LargeCtaSection,
  LargeTextSection,
  ListContentSection,
  LogoBar,
};

// Lower-level fragments referenced by component fragments A-L
export const dependenciesAtoL = {
  FormInput,
  LogoGroup,
};

// Second group of body content component fragments
export const MtoZ = {
  MoreDetails,
  ResourceFeatureSection,
  StatFeatureSection,
  StatementSection,
  StepByStepSection,
  TabbedSection,
  Table,
  ThreeFeatureSection,
  TileBar,
  TileCarousel,
  TileGrid,
  ToggleContentSection,
  TranslationPriceSection,
  VerticalCtaSection,
  VideoCollageSection,
  VideoSection,
};

// Lower-level fragments referenced by component fragments M-Z
export const dependenciesMtoZ = {
  InPageBanner,
  Step,
  TableRow,
  CopyContent,
};
