export const InPageNavigation = `#graphql
  fragment InPageNavigation on InPageNavigation {
    ...Entry
    ctaButton {
        ...Cta
    }
    inPageLinksCollection {
      items {
      ...Cta
      }
    }
    topCopy
    topLink {
        ...Cta
    }
    bottomCopy
    bottomLink {
        ...Cta
    }
    phoneNumber
  }
`;
