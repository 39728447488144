import type { Contentful, ContentfulBase } from '#types';
import type { PrimaryContent } from '../types';

type Primary = Contentful['PrimaryPane' | 'CTA'];
type Secondary = Contentful['SecondaryPane'];
type Group = Contentful['NavGroup'];

interface Links {
  items: Primary[];
}

type UsePaneLinks = (links: Links) => [Primary[], Secondary[]];

export const isPrimaryNavPane = (
  item: ContentfulBase<unknown>
): item is Contentful['PrimaryPane'] => {
  return item.__typename === 'PrimaryNavPane';
};

export const usePaneLinks: UsePaneLinks = (links) => {
  const primaryLinks: Primary[] = links.items || [];

  const primaryContent: PrimaryContent = primaryLinks
    .filter(isPrimaryNavPane)
    .flatMap((link) => link.contentCollection.items);

  const topLevelSecondaryLinks = primaryContent.filter(
    (link) => link.__typename === 'SecondaryNavPane'
  ) as Secondary[];

  const groupLevelSecondaryLinks = primaryContent
    .filter((link) => link.__typename === 'NavGroup')
    .flatMap((item) => (item as Group).contentCollection.items)
    .filter((link) => link.__typename === 'SecondaryNavPane') as Secondary[];

  const allSecondaryLinks: Secondary[] = [
    ...topLevelSecondaryLinks,
    ...groupLevelSecondaryLinks,
  ];

  return [primaryLinks, allSecondaryLinks];
};
