/**
 * @file - Creates a function that will query Contentful data
 * with restrictions on content limit. The Nav, SiteBanner, and all of the content from the page of the specified slug are returned.
 */
import {
  ContentfulPage,
  ContentfulNavigation,
  ContentfulSiteBanner,
  ContentfulCouponBanner,
  ContentfulPageContent,
} from '../../types';
import { getPage } from './getPage';
import { getPageContent } from './getPageContent';
import { getNavigation } from './getNavigation';
import { getSiteBanner } from './getSiteBanner';
import { getCouponBanner } from './getCouponBanner';
import { getPageBodyContent } from './getPageBodyContent';

/**
 * All aggregate data needed for a page
 */
export interface PageData extends Omit<ContentfulPage, 'pageContent'> {
  siteBanner?: ContentfulSiteBanner | null;
  couponBanner?: ContentfulCouponBanner | null;
  // All properties optional except navigation; entire page content entry is
  // optional in Contentful, but we always get a Navigation entry due to default
  // ID used below, and navigation is part of page content object
  pageContent: Partial<ContentfulPageContent> & {
    navigation: ContentfulNavigation;
  };
}

type GetPageData = (
  slug: string,
  variationId?: string
) => Promise<Readonly<PageData | undefined>>;

/**
 * Gets all data needed for a page from Contentful
 * @param slug Slug of the page to fetch
 * @param variationId ID of the experiment variation (if applicable)
 * @returns Promise; resolves to all data needed for a page
 */
export const getPageData: GetPageData = async (slug, variationId) => {
  const page = await getPage(slug);
  if (!page) return undefined;

  // ID of the inner pageContent entry; page.pageContent could be an actual
  // pageContent entry or a variation wrapper; in the latter case (i.e. when in
  // an A/B test), use the variation ID provided
  const pageContentId = variationId ?? page.pageContent?.sys.id ?? '';

  // Fetch full page content entry via ID obtained above
  const pageContent = await getPageContent(pageContentId);

  // Use "Site Navigation" entry as default in case Navigation isn't set in Contentful
  const navId = pageContent?.navigation?.sys.id || '484Cc8TGdN1NXAwDaHYf1E';
  const navigation = await getNavigation(navId);

  const bodyContentItems = await getPageBodyContent(
    pageContentId,
    pageContent?.bodyContentCollection?.total
  );

  const siteBanner = await getSiteBanner();
  const couponBanner = await getCouponBanner();

  return {
    ...page,
    siteBanner,
    couponBanner,
    pageContent: {
      ...pageContent,
      navigation,
      bodyContentCollection: {
        items: bodyContentItems,
      },
    },
  };
};
