import { useStyles } from './graphicbutton.styles';
import type { FunctionComponent } from 'react';
import type { GraphicButtonProps as Props } from './types';
import { appleBadge } from './AppleGoogleBadges';
import { googleBadge } from './AppleGoogleBadges';

const GraphicButtonCta: FunctionComponent<Props> = ({
  ctaCopy,
  destination,
  appleButton,
  googleButton,
  onClick,
}) => {
  const Styles = useStyles();

  if (appleButton) {
    return (
      <a
        sx={Styles.BUTTON}
        href={destination || '#'}
        aria-label="Apple Store Link"
        title={ctaCopy || ''}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
      >
        {appleBadge}
      </a>
    );
  }
  if (googleButton) {
    return (
      <a
        sx={Styles.BUTTON}
        href={destination || '#'}
        aria-label="Google Store Link"
        title={ctaCopy || ''}
        target="_blank"
        rel="noopener noreferrer"
      >
        {googleBadge}
      </a>
    );
  }

  return null;
};

export default GraphicButtonCta;
