export const CopyImageSection = `#graphql
  fragment CopyImageSection on CopyImageSection {
    ...Entry
    variant
    responsiveMedia {
      ...ResponsiveMedia
    }
    heading
    superheading
    lineLength
    backgroundColor
    accentColor
    listStyle
    copy {
      json
    }
    checkBulletStyle
    ctaButtonCollection(limit: 2) {
      items {
        ...Cta
      }
    }
    primaryCtaColor
    secondaryCtaButtonCollection(limit: 3) {
      items {
        ...Cta
      }
    }
  }
`;
