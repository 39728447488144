import { Texture, Textures } from '#constants';
import { ComponentPropsWithoutRef } from 'react';

export const useTexture = (
  texture: Textures
): ComponentPropsWithoutRef<'svg'> | null => {
  switch (texture) {
    case Texture.TEXTURE_A:
      return <img src="/cms-assets/textureA.png" alt="" width="100%" />;

    case Texture.TEXTURE_B:
      return <img src="/cms-assets/textureB.png" alt="" width="100%" />;

    case Texture.NO_TEXTURE:
      return null;

    default:
      return Error(`${texture} is not a recognized texture.`);
  }
};
