/**
 * @file Provides a way to produce a an icon's uri.
 */

export const IconUri = {
  'Open Book': '<svg viewBox="0 0 65 65" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 9.6L32.4 16.9L32.5 16.9L32.6 16.9L64.5 9.6V50.2L32.5 57.5L0.5 50.2V9.6Z" stroke="%23000"/><path d="M3 12L32.5 18.4L62 12V47.6L32.5 54L3 47.6V12Z" fill="%239C95FE"/><path d="M4.5 8.6L32.4 15L32.5 15L32.6 15L60.5 8.6V44L32.5 50.5L4.5 44V8.6Z" fill="%23fff" stroke="%23000"/><rect x="32" y="15" width="1" height="35"/><ellipse cx="47" cy="28" rx="8" ry="6" transform="rotate(-7 47 28)" fill="%239C95FE"/><line x1="9.4" y1="19" x2="28" y2="23" stroke="%23000"/><line x1="9.4" y1="24.3" x2="28" y2="28.3" stroke="%23000"/><line x1="9.4" y1="29.6" x2="28" y2="33.6" stroke="%23000"/><line x1="9.4" y1="34.9" x2="28" y2="38.9" stroke="%23000"/></svg>',
  'Show': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><circle cx="12" cy="13" r="3"/><path d="M2,13s4,6,10,6,10-6,10-6S18,7,12,7,2,13,2,13Z" style="fill:none;stroke:%23000;stroke-width:2"/><line x1="12" y1="3.5" x2="12" y2="7" style="fill:none;stroke:%23000;stroke-width:2"/><line x1="21" y1="6" x2="18" y2="9" style="fill:none;stroke:%23000;stroke-width:2"/><line x1="3" y1="6" x2="6" y2="9" style="fill:none;stroke:%23000;stroke-width:2"/></svg>',
  'Hide': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22,13s-4,6-10,6S2,13,2,13" style="fill:none;stroke:%23000;stroke-width:2"/><line x1="12" y1="22.5" x2="12" y2="19" style="fill:none;stroke:%23000;stroke-width:2"/><line x1="21" y1="20" x2="18" y2="17" style="fill:none;stroke:%23000;stroke-width:2"/><line x1="3" y1="20" x2="6" y2="17" style="fill:none;stroke:%23000;stroke-width:2"/></svg>',
  'Chevron': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M3,5 10,13 17,5" stroke="%23292929" stroke-width="2.5" fill="none" stroke-linejoin="round" stroke-linecap="round" /></svg>'
};

export const useIconUri = (name: Icon): string => (
  `'data:image/svg+xml;utf8,${IconUri[name]}'`
);

export type Icon = keyof typeof IconUri;