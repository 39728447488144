import Full from './Full';
import Simple from './Simple';
import { FooterVariant } from '@lib/constants';

import type { FC } from 'react';
import type { FooterProps as Props } from './types';

const Footer: FC<Props> = ({ variant, ...props }) => {
  switch (variant) {
    case FooterVariant.FULL:
      return <Full {...props} />;
    case FooterVariant.SIMPLE:
      return <Simple {...props} />;
    default:
      throw Error('Variant type ${variant} is not a Footer Variant');
  }
};

export default Footer;
