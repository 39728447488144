export const TileGridVariant = Object.freeze({
  DEFAULT: 'default',
  CARDS: 'cards',
  TESTIMONIAL: 'testimonial',
  RESOURCE: 'resource',
} as const);

export const TILE_GRID_VARIANTS = Object.values(TileGridVariant);

export type TileGridVariants = typeof TILE_GRID_VARIANTS[number];

export const GridColumns = ['1', '2', '3'] as const;

export type GridColumn = typeof GridColumns[number];
