import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    ROOT: {
      display: 'flex',
      margin: 'auto',
      inlineSize: '100%',
    } as TSO,

    BUTTON: {
      background: 'transparent',
      border: 'none',
      blockSize: 'auto',
      inlineSize: ['8.5rem', '9.875rem'],
      marginBlockStart: 4,
    } as TSO,
  };

  return Styles;
};
