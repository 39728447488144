export const Entry = `#graphql
  fragment Entry on Entry {
    __typename
    sys {
      id
      publishedAt
    }
    contentfulMetadata {
      tags {
        name
      }
    }
  }
`;
