import { forwardRef, useState } from 'react';
import MarkdownText from '@components/MarkdownText';
import { useStyles } from './readmore.styles';
import { Icon, Block } from '#ui';
import type { ReadMoreProps as Props, ReadMoreRef as Ref } from './types.d';

const ReadMore = forwardRef<Ref, Props>(
  ({ primaryCopy, secondaryCopy, backgroundColor, ...props }, ref) => {
    const Styles = useStyles({ backgroundColor });
    const [isOpen, setOpen] = useState(false);

    return (
      <section sx={Styles.ROOT} ref={ref} {...props}>
        <MarkdownText bkg={backgroundColor}>{primaryCopy}</MarkdownText>
        <Block
          aria-label='details'
          aria-hidden={isOpen ? false : true}
          sx={{
            ...Styles.DETAILS,
            ...(isOpen ? Styles.DETAIL_OPEN : Styles.DETAIL_CLOSED),
          }}
        >
          <MarkdownText bkg={backgroundColor}>{secondaryCopy}</MarkdownText>
        </Block>
        <button
          sx={Styles.BTN}
          className={isOpen ? 'open' : 'closed'}
          onClick={() => setOpen(prev => !prev)}
          aria-label={ isOpen ? 'Hide Details' : 'Expand Details' }>
            { isOpen ? 'Read Less' : 'Read More' }
            <Icon as="Down Chevron" className={isOpen ? 'upArrow' : undefined}/>
        </button>
      </section>
    );
  }
);

export default ReadMore;
