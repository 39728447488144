import type { InteractiveElement, ButtonMap, AreaMap } from '../types';

type UseRefReducers = (props: {
  buttons: (HTMLAnchorElement | HTMLButtonElement)[];
  areas: HTMLElement[];
}) => ({
  buttonCollection: ButtonMap,
  areaCollection: AreaMap,
});

export const useRefReducers: UseRefReducers = ({ buttons, areas, }) => {
  const buttonCollection = buttons.reduce((acc, ref, pos) => ({
    ...acc,
    [ref.dataset.focus || ref.dataset.name || pos]: ref,
  }), {});

  const areaCollection = areas.reduce((acc, ref, pos) => {
    const ctas =
      [...ref.querySelectorAll<InteractiveElement>('a, button:not([data-name])')];
    const [first] = ctas;
    const [last] = ctas.reverse();

    return {
      ...acc,
      [ref.dataset.area || pos]: { ref, first, last },
    }
  }, {});

  return { buttonCollection, areaCollection };
};
