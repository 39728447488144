import { useContrast } from '@lib/hooks';
import type { ThemeUIStyleObject as TSO } from 'theme-ui';


export const useStyles = (bkg: string | undefined): typeof Styles => {
  const { text: color, select,  } = useContrast(bkg);
  const Styles = {
    ROOT: {
      color: color,
      boxShadow: `inset 0 -.8em ${select}`,
      paddingInlineEnd: 10,
      paddingBlockEnd: '2px',
    } as TSO,
  };

  return Styles;
};
