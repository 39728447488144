/**
 * ContentType
 */

export const ContentType = Object.freeze({
  HERO: 'Hero',
  SEO: 'SEO',
  CTA: 'Cta',
  PAGE: 'Page',
  ASSET: 'Asset',
  TILE_GRID: 'TileGrid',
  TILE: 'Tile',
  RES_MEDIA: 'ResponsiveMedia',
  COPY_IMAGE: 'CopyImageSection',
  FORM: 'Form',
  FORM_INPUT: 'FormInput',
  FOOTER: 'Footer',
  NAVIGATION: 'Navigation',
  ACCORDION_SECTION: 'AccordionSection',
  TABBED_SECTION: 'TabbedSection',
  STATEMENT_SECTION: 'StatementSection',
  LARGE_TEXT: 'LargeTextSection',
  LIST_CONTENT: 'ListContentSection',
  VERTICAL_CTA_SECTION: 'VerticalCtaSection',
  VIDEO_SECTION: 'VideoSection',
  INPAGE_ANCHOR: 'InPageAnchor',
  INPAGE_NAV: 'InPageNavigation',
  HORIZONTAL_CTA: 'HorizontalCta',
  LARGE_CTA: 'LargeCtaSection',
  THREE_FEATURE: 'ThreeFeatureSection',
  MORE_DETAILS: 'MoreDetailsSection',
  STEP_BY_STEP_SECTION: 'StepByStepSection',
  TRANSLATION_PRICE_LIST: 'TranslationPriceSection',
  COPY_CONTENT: 'CopyContent',
  RESOURCE_FEATURE_SECTION: 'ResourceFeatureSection',
  TILE_CAROUSEL: 'TileCarousel',
  VIDEO_COLLAGE: 'VideoCollageSection',
  STAT_FEATURE_SECTION: 'StatFeatureSection',
  LOGO_BAR: 'LogoBar',
  GRAPHIC_BUTTON: 'GraphicButton',
  FEATURED_ANIMATION_SECTION: 'FeaturedAnimationSection',
  TABLE_SECTION: 'Table',
  GATED_CONTENT_SECTION: 'GatedContentSection',
  SITE_BANNER: 'SiteBanner',
  FIND_YOUR_SERVICE: 'FindYourService',
  EMBED_SECTION: 'EmbedSection',
  TILE_BAR: 'TileBar',
} as const);

export const CONTENT_TYPES = Object.values(ContentType);

export type ContentTypes = typeof CONTENT_TYPES[number];
