import type { FooterStyles as FS } from './types';
import type { ThemeUIStyleObject as TSO } from 'theme-ui';
import { useContext } from 'react';
import { SpacingContext } from '@lib/hooks';

export const useStyles = ({ colorMode }: FS): typeof Styles => {
  const bkg = colorMode === 'Dark' ? 'grey.9' : 'grey.1';
  const text = colorMode === 'Dark' ? 'white' : 'grey.9';
  const rightLinks = {
    bkg: colorMode === 'Dark' ? 'black' : 'white',
    link: colorMode === 'Dark' ? 'lilac.3' : 'lilac.7',
    hover: colorMode === 'Dark' ? 'lilac.4' : 'black',
  };
  const linkHover = colorMode === 'Dark' ? 'lilac.4' : 'neon.2';

  const { footerSpacing } = useContext(SpacingContext);

  const Styles = {
    ROOT: {
      contain: 'content',
      contentVisibility: 'auto',
      containIntrinsicSize: '0 53rem',
      backgroundColor: bkg,
      color: text,
      paddingInlineStart: [0, 3],
      paddingBlockEnd: [2, 3],
      marginBlockStart: !footerSpacing ? '0px' : 8,
      '& li':{
        listStyle: 'none',
      },
      '& a':{
        transition: 'all .2s',
      },
    } as TSO,

    MAIN_NAVIGATION: {
      paddingBlockStart: [0, null, 6],
      paddingBlockEnd: [2, 3, 5],
    } as TSO,

    LEFT_COLUMN: {
      order: [7, 5, 0],
      marginBlockStart: [5, null, '0px'],
      svg: {
        fill: text,
        inlineSize: ['177px', null, '60px'],
        blockSize: ['auto', null, '60px'],
        '& .logotype': {
          display: ['block', null, 'none'],
        },
      },
      a: {
        lineHeight: '20px',
      },
      'a:hover': {
        color: linkHover,
      },
    } as TSO,

    LEFT_LINK_LIST: {
      display: 'flex',
      flexFlow: ['row wrap', null, 'column nowrap'],
      marginBlockStart: [3, null, 4],
      marginBlockEnd: [4, null],
      fontSize: '.8125rem',
      li: {
        lineHeight: [3, null, '2.3'],
        '&:not(:last-of-type)': {
          marginInlineEnd: [4, 2, '0px'],
        },
      },
    } as TSO,

    LINK_COLUMN: {
      marginBlockStart: [5, null, '2em'],
    } as TSO,

    LINK_LIST: {
      fontSize: '.875rem',
      paddingBlockEnd: 4,
      li: {
        lineHeight: '2em',
        marginBlockEnd: 0,
        textTransform: 'capitalize',
      },
      '.heading': {
        color: text,
        fontFamily: 'heading',
        fontWeight: 'semiBold',
        textTransform: 'uppercase',
        fontSize: 0,
        letterSpacing: 'comfort',
      },
      'a:hover': {
        color: linkHover,
      },
    } as TSO,

    RIGHT_COLUMN: {
      order: [5, 6],
      marginBlockStart: [5, null, '0px'],
    } as TSO,

    RIGHT_DATA_COLUMN: {
      borderTopLeftRadius: 5,
      borderStartStartRadius: 5,
      backgroundColor: rightLinks.bkg,
      color: text,
      paddingBlockStart: '4em',
      paddingBlockEnd: '2.5em',
      paddingInlineStart: '3.5em',
      paddingInlineEnd: '2em',
    } as TSO,

    RIGHT_LINKS_SUPERHEADER: {
      display: 'flex',
      fontSize: '.75rem',
      paddingBlockEnd: 0,
    } as TSO,
    RIGHT_LINKS_HEADER: {
      fontSize: 0,
    } as TSO,
    
    RIGHT_COLUMN_ICON: {
      inlineSize: 1,
      blockSize: 1,
      marginInlineEnd: 0,
      fill: rightLinks.link,
    } as TSO,

    RIGHT_LINKS_COPY: {
      fontSize: 0,
      a: {
        lineHeight: '20px',
        color: text,
      },
      'a:hover': {
        color: linkHover,
      },
    } as TSO,

    RIGHT_LINKS_CTA: {
      marginBlockStart: 4,
      color: rightLinks.link,
      '&:hover': {
        color: rightLinks.hover,
      },
    } as TSO,

    BUTTON: {
      minInlineSize: '100%',
      marginBlockStart: 5,
      fontSize: 0,
    } as TSO,

    SUBLINKS: {
      order: 8,
      paddingBlockStart: [2, 5, null],
      paddingBlockEnd: '2em',
      fontSize: 0,
      'a:hover': {
        color: linkHover,
      },
    } as TSO,

    SUBLINKS_LIST: {
      gridColumn: ['2/-2', null, '1/-1'],
      display: 'flex',
      inlineSize: '100%',
      flexWrap: ['wrap', 'nowrap'],
      fontSize: ['.75rem', null, '.8125rem'],
      li: {
        lineHeight: 3,
        '&:not(:last-of-type)': {
          marginInlineEnd: 4,
        },
      },
    } as TSO,
  };

  return Styles;
};
