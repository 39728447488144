export const InPageBanner = `#graphql
  fragment InPageBanner on InPageBanner {
    ...Entry
    bannerCopy {
      json
    }
    backgroundColor

  }
`;
