export const FormInput = `#graphql
  fragment FormInput on FormInput {
    ...Entry
    required
    label
    variant
    dropdownOptions
    type
    longText
  }
`;
