/**
 * @file sorts all incoming page content from Contenful
 * using the ComponentUnion object in src/lib/constants/content.
 * Every component is passed through and is directed to the correct
 * presentational component to display the data based on the __typename
 */

import { findComponent } from '#utils';

import type { FunctionComponent } from 'react';
import type { ComponentUnion, ModalContentUnion } from '#types';

interface Props {
  content: ComponentUnion | ModalContentUnion;
  order?: number;
}

const BlackboxContent: FunctionComponent<Props> = ({ content, order }) => {
  const Component = findComponent(content.__typename);
  const _order = order || 0;

  if (!Component) {
    throw `${content.__typename} does not have a matching component.`;
  }

  return <Component {...content } order={_order} />;
};

export default BlackboxContent;
