export const StatementSection = `#graphql
  fragment StatementSection on StatementSection {
    ...Entry
    variant
    superheading
    heading
    text: copy
    accentColor
    statsSubheading
    statTilesCollection(limit:4) {
      items {
        ...Tile
      }
    }
  }
`;
