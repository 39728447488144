export const StatFeatureSection = `#graphql
  fragment StatFeatureSection on StatFeatureSection {
    ...Entry
    statistic
    units
    subheading
    statCopy: copy
    image {
      ...ResponsiveMedia
    }
  }
`;