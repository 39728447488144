import { useState, useRef, FC } from 'react';
import { Button } from '#ui';
import Modal from './Modal';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import type { ModalButtonProps } from './types';

const ModalButton: FC<ModalButtonProps> = ({
  modalContent,
  componentStyle,
  contrast,
  ctaCopy,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const modalRef = useRef<HTMLDialogElement>(null);
  const targetRef = modalRef.current;

  const toggleModal = () => {
    if (!isOpen) {
      disableBodyScroll(targetRef);
      document.body.style.overflow = 'hidden';
      setIsOpen(true);
    } else {
      clearAllBodyScrollLocks();
      document.body.style.overflow = 'auto';
      setIsOpen(false);
    }
  };

  return (
    <>
      <Button
        contrast={contrast}
        componentStyle={componentStyle}
        onClick={() => toggleModal()}
      >
        {ctaCopy}
      </Button>
      {isOpen && (
        <Modal
          modalContent={modalContent}
          onClose={() => toggleModal()}
          {...props}
        />
      )}
    </>
  );
};

export default ModalButton;
