import { useContrast } from '#hooks';

import type { ThemeUIStyleObject as TSO } from 'theme-ui';
import type { ReadMoreStyles as Props } from './types';

export const useStyles = ({ backgroundColor }: Props): typeof Styles => {
  const { text, link } = useContrast(backgroundColor);

  const Styles = {
    ROOT: {
      contain: 'content',
      variant: 'styles.p',
      color: text,

      '& p': { fontSize: 1 },
    } as TSO,

    DETAILS: {
      overflow: 'hidden',
      height: 'fit-content',
      transition: 'all ease-in-out .25s',
    } as TSO,

    DETAIL_OPEN: {
      maxHeight: '800px',
      visibility: 'visible',
    } as TSO,

    DETAIL_CLOSED: {
      maxHeight: '0px',
      visibility: 'collapse'
    } as TSO,

    BTN: {
      color: link.text,
      display: 'inline-flex',
      paddingBlockStart: 4,
      paddingBlockEnd: 1,
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: 1,
      fontFamily: 'body',
      transition: 'color .2s',
      backgroundColor: 'transparent',

      '& svg': {
        inlineSize: '24px',
        blockSize: '24px',
        transition: 'transform .25s',
      },

      '&:hover': { color: link.action.text },

      '.upArrow': {
        transform: 'rotate(180deg)',
      } as TSO,
    } as TSO,

  };

  return Styles;
};
