export const Tile = `#graphql
  fragment Tile on Tile {
    ...Entry
    variant
    heading
    pills
    tileCopy: copy {
      json
    }
    secondaryCopy {
      json
    }
    checkBulletStyle
    subtitle
    simpleIcon {
      ...Asset
    }
    description
    cta {
      ...Cta
    }
    primaryCtaColor
    secondaryCtas: secondaryCtasCollection {
      items{
        ...Cta
      }
    }
    responsiveMedia {
      ...ResponsiveMedia
    }
    testimonialJobTitle
    flag
    twitterSource
    resourceType
    rating
    ratingCopy
    price
    backgroundColor
  }
`;
