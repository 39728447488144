export const FormVariant = Object.freeze({
  SECTION: 'Form Section',
  COMPONENT: 'Component Form',
  INLINE: 'Inline Form',
  FLOATING: 'Floating',
  // deprecated; remove when no longer in Contentful
  CONTACT: 'Contact',
  GATED_CONTENT: 'Gated Content'
} as const);

export const FORM_VARIANTS = Object.values(FormVariant);

export type FormVariants = typeof FORM_VARIANTS[number];

export const HUBSPOT_PORTAL_ID = '21451281';
