import { forwardRef } from 'react';
import { Themed } from 'theme-ui';
import { useStyles } from './badge.styles';

import type {
  BadgeProps as Props,
  BadgeRef as Ref,
  FeatureReveal,
} from './types';

const Badge = forwardRef<Ref, Props>(({ children, ...props }, ref) => {
  if (!children) return null;

  const sx = useStyles();

  const featureReveal: FeatureReveal = e => {
    switch (e.type) {
      case 'mouseover':
        e.currentTarget.classList.add('show-feature');
        break;
      case 'mouseleave':
        e.currentTarget.classList.remove('show-feature');
        break;
      case 'keypress':
        e.code === 'Enter' && e.currentTarget.classList.toggle('show-feature');
        break;
      default:
        return undefined;
    }
  };

  return (
    <Themed.p
      sx={sx}
      ref={ref}
      onMouseOver={featureReveal}
      onMouseLeave={featureReveal}
      onKeyPress={featureReveal}
      tabIndex={0}
      {...props}
    >
      <svg viewBox="0 0 26 26" aria-hidden="true" fill="currentColor">
        <title>Feature flag</title>
        <g>
          <path d="M10,5l2.7,7.3L20,15l-7.3,2.7L10,25,7.3,17.7,0,15l7.3-2.7Z" />
          <path d="M20,1l1.4,3.6L25,6,21.4,7.4,20,11,18.6,7.4,15,6l3.6-1.4Z" />
        </g>
      </svg>
      <span>{ children }</span>
    </Themed.p>
  );
});

export default Badge;
