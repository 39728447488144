/* eslint-disable react/jsx-no-target-blank */
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { IconButton } from '#ui';
import { useStyles } from './siteBanner.styles';
import type { SiteBannerProps as Props } from './types';
import MarkdownText from '@components/MarkdownText';
import { propsValidator, getLinkClickHandlerWithTracking } from '#utils';

const SiteBanner: FC<Props> = ({ bannerActive, copy, cta, ...entry }) => {
  const Styles = useStyles();
  const valid = propsValidator(entry, { copy });
  const [showAnnouncement, setShowAnnouncement] = useState<boolean>();
  const [path, setPath] = useState<string | null>(null);

  useEffect(() => {
    setPath(window.location.pathname);
    const announcement = JSON.parse(
      sessionStorage.getItem('announcement') || 'true'
    );
    announcement ? setShowAnnouncement(true) : setShowAnnouncement(false);
  }, []);

  const closeAnnouncement = () => {
    sessionStorage.setItem('announcement', 'false');
    setShowAnnouncement(false);
  };

  if (!valid || !bannerActive) return null;

  return showAnnouncement ? (
    <aside id="rev-site-banner" sx={Styles.ROOT} aria-label="Announcement">
      <div sx={Styles.CONTAINER}>
        {cta?.destination ? (
          <a
            href={cta.destination}
            target={cta.openInNewTab ? '_blank' : undefined}
            rel={cta.openInNewTab ? 'noreferrer noopener' : undefined}
            onClick={getLinkClickHandlerWithTracking(
              cta.analyticsEvent,
              copy,
              path,
              cta.destination
            )}
          >
            <MarkdownText>{copy!}</MarkdownText>
          </a>
        ) : (
          <MarkdownText>{copy!}</MarkdownText>
        )}
      </div>
      <IconButton
        sx={Styles.CLOSE}
        variant="Close Menu"
        onClick={closeAnnouncement}
      >
        Close Banner
      </IconButton>
    </aside>
  ) : null;
};

export default SiteBanner;
