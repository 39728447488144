import { forwardRef } from 'react';
import { Icon } from '../Icon';

import type { IconButtonProps as Props, IconButtonRef as Ref } from './types';

const IconButton = forwardRef<Ref, Props>(({
  children,
  variant = 'Sparkle',
  ...props
}, ref) => {
  if (!children) return null;

  return (
    <button
      className="IconButton"
      type="button"
      ref={ref}
      sx={{
        display: 'inline-flex',
        inlineSize: ['26px', '34px'],
        blockSize: ['26px', '34px'],
        padding: ['10px', '6px'],
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'content-box',
        background: 'none',
        cursor: 'pointer',
      }}
      {...props}
    >
      <span className="global-sr">{children}</span>
      <Icon as={variant} />
    </button>
  );
});

export default IconButton;
