import { ContentfulCouponBanner, ContentfulQueryResult } from '@lib/types';
import { request } from '../request';

interface GetCouponBannerQueryResult extends ContentfulQueryResult {
  couponBannerCollection: {
    items: (ContentfulCouponBanner | undefined)[];
  };
}

export const getCouponBanner =
  async (): Promise<ContentfulCouponBanner | null> => {
    const response = await request<GetCouponBannerQueryResult>(`#graphql
    query getCouponBanner($preview: Boolean) {
      couponBannerCollection(limit: 1, preview: $preview) {
        items {
          copy
        }
      }
    }
  `);

    return response.couponBannerCollection.items[0] || null;
  };
