import type { ThemeUIStyleObject as TSO } from 'theme-ui';
import type { ResponsiveStyles as Props } from './types';

export const useStyles = ({
  desktopAsset,
  mobileAsset,
  fit,
  forceHeight,
}: Props): typeof Styles => {
  const desktopRatio: number =
    (desktopAsset?.height || 1) / (desktopAsset?.width || 1);
  const mobileRatio: number =
    (mobileAsset?.height || 1) / (mobileAsset?.width || 1);
  const desktopPercent: string = (desktopRatio * 100).toFixed(2) + '%';
  const mobilePercent: string = (mobileRatio * 100).toFixed(2) + '%';

  const Styles = {
    IMAGE: {
      display: 'flex',
      position: 'relative',
      overflow: 'hidden',

      '& img': {
        blockSize: '100%',
        position: 'absolute',
        top: '0px',
        insetBlockStart: '0px',
        left: '0px',
        insetInlineStart: '0px',
        objectFit: fit,
      },

      '&::before': {
        content: '""',
        display: 'block',
        inlineSize: '100%',
        paddingBlockStart: forceHeight
          ? forceHeight
          : mobileAsset
          ? [mobilePercent, desktopPercent]
          : desktopPercent,
      },
    } as TSO,

    VIDEO: {
      flex: '1',
      position: 'relative',
      blockSize: '100%',
      inlineSize: '100%',
    } as TSO,

    VIDEO_WRAPPER: {
      paddingBlockStart: '56.25%',
      position: 'relative',
    } as TSO,

    VIDEO_SUBWRAPPER: {
      position: 'absolute',
      blockSize: '100%',
      inlineSize: '100%',
      left: '0px',
      insetInlineStart: '0px',
      top: '0px',
      insetBlockStart: '0px',
    } as TSO,

    EMBED: {
      position: 'relative',
      blockSize: '100%',
      inlineSize: '100%',
    } as TSO,
  };

  return Styles;
};
