export const VideoCollageSection = `#graphql
  fragment VideoCollageSection on VideoCollageSection {
    ...Entry
    header: heading {
      json
    }
    superheading
    videosCollection {
      items {
        ...ResponsiveMedia
      }
    }
    cta {
      ...Cta
    }
  }
`;
