import { theme } from '../../theme';

export const Meta = Object.freeze({
  BASE: 'https://www.rev.com',
  TITLE: 'Rev.com',
  DESCRIPTION:
    'Affordable, accurate, easy-to-use speech-to-text solutions powered by people and A.I working together. Rev offers transcripts, captions, subtitles, APIs and more at low prices',
  OG_SITE_NAME: 'Rev',
  OG_TYPE: 'website',
  TWITTER_CARD: 'summary_large_image',
  TWITTER_SITE: '@rev',
  FB_APP_ID: '199170300134516',
  COLOR: theme.colors!.primary as string,
});
