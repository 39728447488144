
import { useStyles } from './styles';
import {LegalUnorderedList as Props} from './types.d'

const LegalUnorderedList = ({children}: Props): JSX.Element => {

  const Styles = useStyles();
  return (
    <ul sx={Styles.ROOT}>
      {children}
    </ul>
  );
};
export default LegalUnorderedList;
