export const PrimaryNavPane = `#graphql
  fragment PrimaryNavPane on PrimaryNavPane {
    ...Entry
    heading
    contentCollection(limit:6) {
      items {
        ...Cta
        ...SecondaryNavPane
        ...NavGroup
      }
    }
  }
`;
