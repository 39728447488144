import { ColorName } from './colors';

export const STATEMENT_VARIANTS = ['Default', 'With Stats'] as const;

export const STATEMENT_COLORS = [
  ColorName.LILAC,
  ColorName.TEAL,
  ColorName.CHARTREUSE,
] as const;

export type StatementVariant = typeof STATEMENT_VARIANTS[number];
export type StatementColor = typeof STATEMENT_COLORS[number];
