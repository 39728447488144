import type { ThemeUIStyleObject as TSO } from 'theme-ui';
import type { CarouselStyles as SS } from './types';

export const useStyles = ({
  offset,
  col,
  noPadding,
  wrap,
  full,
  numChildren,
}: SS): typeof Styles => {
  const Styles = {
    ROOT: {
      display: !wrap && 'grid',
      maxInlineSize: '1440px',
      paddingInlineStart: !noPadding && [10, '0px'],
      paddingInlineEnd: !noPadding && [10, '0px'],
      grid: !wrap && [
        'auto/repeat(8,1fr)',
        'auto/repeat(10,1fr)',
        'auto/repeat(16,1fr)',
      ],
      columnGap: !wrap && [10, 11],
      '& > *': !wrap && { gridColumn: '1/-1' },
      'body &': col && { gridColumn: col },
    } as TSO,

    TILE_CONTAINER_CAROUSEL_VISIBLE: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
      gridColumn: '1/-1',
      inlineSize: '100%',
      '& article': {
        minInlineSize: !offset ? '100%' : ['calc(90vw - 40px)', null],
        '&:last-of-type': {
          inlineSize: offset && '100vw',
        },
      },
       /* Safari 13 and older spacing */
       '@supports not (not BigInt)': {
        '& > article': {marginInlineEnd: '10px'}
      },
    } as TSO,

    TILE_CONTAINER_NOCAROUSEL: {
      display: full
        ? 'none'
        : offset
        ? ['none', 'flex']
        : ['none', 'none', 'flex'],
      paddingInlineEnd: !offset ? 0 : [0, '0px'],
      justifyContent: 'flex-start',
      alignItems: 'space-between',
      flexFlow: [null, 'row wrap', 'row nowrap'],
      '& article': {
        flexBasis: offset && [
          '100%',
          `calc(50% - 10px)`,
          `calc(100% / ${numChildren})`,
        ],
        minInlineSize: full && 'calc(100% / 6)',
      },

         /* Safari 13 and older spacing */
         '@supports not (not BigInt)': {
          '& > article': {marginInlineEnd: '10px'}
        },
    } as TSO,

    CAROUSEL_VISIBLE: {
      display: full
        ? 'flex'
        : offset
        ? ['block', 'none']
        : ['flex', 'flex', 'none'],
      gridColumn: '1/-1',
      overflow: full && ['hidden', 'hidden', null],
    } as TSO,

    CONTROLS: {
      display: !offset ? 'flex' : ['flex', 'none'],
      gridColumn: ['1/-1', '3/-3', '4/-4'],
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      marginBlockStart: 3,
      marginBlockEnd: 3,
      zIndex: 20,
    } as TSO,

    CONTROL_NAV: {
      display: 'flex',
      inlineSize: '100%',
      blockSize: 2,
      paddingInlineStart: 3,
      paddingInlineEnd: 3,
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      flex: '1 1 auto',

      '& :first-of-type': {
        borderRadius: '10px 0px 0px 10px',
      },

      '& :last-of-type': {
        borderRadius: '0px 10px 10px 0px',
      },
    } as TSO,
  };

  return Styles;
};
