export const TileGrid = `#graphql
  fragment TileGrid on TileGrid {
    ...Entry
    title
    hideTitle
    titleHighlight
    tileGridCopy
    columnsAtDesktop
    superheading
    variant
    leftAlignTitle
    rating
    ratingCopy
    starRatingLink
    stackOnMobile
    showInGroupsOf
    tilesCollection(limit: 6) {
      items {
        ...Tile
      }
    }
  }
`;
