export const setCookie = (
  cookieName: string,
  cookieValue: string,
  daysToExp?: number
): void => {
  // default expiration is one year from current date
  const expiration = daysToExp
    ? new Date(
        new Date().getTime() + 1000 * 60 * 60 * 24 * daysToExp
      ).toUTCString()
    : new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365).toUTCString();
  const cookieString = `${cookieName}=${cookieValue}; expires=${expiration}; path=/`;
  window.document.cookie = cookieString;
};

export const getCookies = (): string[] => {
  const cookies = window.document.cookie;
  const cookiesArr = cookies.split('; ');
  return cookiesArr;
};

export const getNamedCookie = (cookieName: string): string | null => {
  const cookiesArr = getCookies();
  const foundCookie = cookiesArr.find((item) => item.includes(cookieName));
  if (foundCookie) return foundCookie.replace(`${cookieName}=`, '');

  return null;
};

export const clearNamedCookie = (cookieName: string): void => {
  window.document.cookie = `${cookieName}= null; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
};

export const isLoggedIn = (): boolean => {
  const cookiesArr = getCookies();

  const authCookieKey = 'loggedIn';

  const authCookie = cookiesArr.find((cookie) =>
    cookie.startsWith(authCookieKey)
  );

  return typeof authCookie !== 'undefined';
};
