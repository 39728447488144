import { P, Icon } from '#ui';
import { propsValidator, createId } from '#utils';
import { useStyles } from '../nav.styles';

import type { FC } from 'react';
import type { PaneButtonProps as Props } from '../types';
import type { NavStyles } from '../nav.styles';

const PaneButton: FC<Props> = ({
  selected,
  heading,
  ctaStyle,
  copy,
  parent,
  icon,
  superheading,
  onClick,
  onKeyPress,
  ...entry
}) => {
  const valid = propsValidator(entry, { heading });

  if (!valid) return null;

  const Styles: NavStyles = useStyles({ ctaStyle });
  const id: string = createId(heading!);

  return (
    <div
      sx={{ ...Styles.TOP_LEVEL_CTA, ...Styles.PANE_BUTTON }}
      className={selected ? '--active' : ''}
    >
      <div>
        {superheading && (
          <P className="pane__Super">
            {icon && <Icon as={icon} color="NeonViolet" /> }
            <span>{superheading}</span>
          </P>
        )}
        {copy ? (
          <>
            <h3>{heading}</h3>
            <P>
              {copy}
              <button
                className="__hasActiveArea"
                id={id}
                type="button"
                aria-haspopup="true"
                aria-expanded={selected}
                aria-selected={selected}
                aria-controls={`${id}-pane`}
                data-focus={heading}
                data-related={parent}
                data-testid={id}
                onClick={onClick}
                onKeyPress={onKeyPress}
              >
                <span className="global-sr">Continue</span>
              </button>
            </P>
          </>
        ) : (
          <button
            className="__hasActiveArea"
            id={id}
            type="button"
            aria-haspopup="true"
            aria-expanded={selected}
            aria-selected={selected}
            aria-controls={`${id}-pane`}
            data-testid={heading}
            data-focus={heading}
            data-related={parent}
            onClick={onClick}
            onKeyPress={onKeyPress}
          >
            <h3>{heading}</h3>
          </button>
        )}
      </div>
      <Icon as="Right Chevron" />
    </div>
  );
}

export default PaneButton;
