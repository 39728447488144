export const LargeCtaSection = `#graphql
  fragment LargeCtaSection on LargeCtaSection {
    ...Entry
    variant
    upperImage {
      ...ResponsiveMedia
    }
    upperHeading
    upperCopy: copy
    ctaButton {
      ...Cta
    }
    lowerImage {
      ...ResponsiveMedia
    }
    lowerHeading
    video {
      ...ResponsiveMedia
    }
    lowerCtasCollection(limit:3) {
      items {
        ...Cta
      }
    }
  }
`;
