export const ResourceFeatureSection = `#graphql
  fragment ResourceFeatureSection on ResourceFeatureSection {
    ...Entry
    featuredResourcesCollection(limit:3) {
      items {
        ...Tile
      }
    }
  }
`;
