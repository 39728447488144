import ResponsiveMedia from '@components/ResponsiveMedia';
import { propsValidator } from '@lib/utils';
import { Grid, Block, Quote } from '#ui';
import { useStyles } from './testimonial.styles';

import type { FunctionComponent } from 'react';
import type { TestimonialProps as Props } from './types';

const TestimonialTile: FunctionComponent<Props> = ({
  heading,
  responsiveMedia,
  simpleIcon,
  description,
  testimonialJobTitle,
  ...entry
}) => {
  const valid = propsValidator(entry, { heading });

  if (!valid) return null;

  const Styles = useStyles();

  return (
    <Block
      as="article"
      sx={Styles.ROOT}
      aria-label={heading || undefined}
    >
      <Grid subgrid noPadding>
        <Block
          col={[null, '1/4', '2/5']}
          sx={Styles.IMAGE_CONTAINER}
        >
          {responsiveMedia?.desktopAsset?.url && (
            <ResponsiveMedia {...responsiveMedia} sx={Styles.IMAGE} />
          )}
          {simpleIcon?.url && (
            <img
              sx={Styles.ICON}
              src={simpleIcon.url}
              alt={simpleIcon.description || ''}
              loading="lazy"
            />
          )}
        </Block>
        <Block col={[null, '5/8', '6/-2']}>
          <figure sx={Styles.DESCRIPTION_COPY_CONTAINER}>
            {description && <Quote sx={Styles.DESCRIPTION_COPY}>{description}</Quote>}
            <figcaption sx={Styles.JOB_NAME_CONTAINER}>
              {heading && <p>{heading}</p>}
              {testimonialJobTitle && (
                <p>{testimonialJobTitle}</p>
              )}
            </figcaption>
          </figure>
        </Block>
      </Grid>
    </Block>
  );
};

export default TestimonialTile;
