import {
  StrictMode,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { Logo, IconButton, Link } from '#ui';
import RootButton from './components/RootButton';
import PrimaryPane from './components/PrimaryPane';
import SecondaryPane from './components/SecondaryPane';
import { isPrimaryNavPane, usePaneLinks, useRefReducers } from './hooks';
import { useStyles } from './nav.styles';
import {
  getLinkClickHandlerWithTracking,
  getRole,
  isLoggedIn,
} from '@lib/utils';

import type { FC } from 'react';
import type { ContentfulCta } from '#types';
import type {
  NavProps as Props,
  ButtonMap,
  AreaMap,
  InteractiveElement,
  MouseFocus,
  KeyboardFocus,
  CloseNav,
  LoggedInState,
  LoggedInCtas,
} from './types';
import type { NavStyles } from './nav.styles';

const useIsomorphicEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const Navigation: FC<Props> = ({
  primaryLinksCollection,
  loggedOutCtasCollection,
  customerCtasCollection,
  adminCtasCollection,
  revverCtasCollection,
  slug,
  sticky,
  buttonCtas,
}) => {
  /**
   * SECTION - Setup
   */

  // -- States
  const [menuOpen, setMenuOpen] = useState(false);
  const [primaryPane, setPrimaryPane] = useState('');
  const [secondaryPane, setSecondaryPane] = useState('');
  const [isMobile, setIsMobile] = useState(true);
  const [loggedIn, setLoggedIn] = useState<LoggedInState>(null);
  const [path, setPath] = useState<string | null>(null);
  const [siteBanner, setSiteBanner] = useState(false);

  // -- Refs
  const $nav = useRef<HTMLElement>(null);
  const $buttonMap = useRef<ButtonMap>({});
  const $areaMap = useRef<AreaMap>({});
  const $threshold = useRef({ small: false, large: false });
  const $scrollAreas = useRef<HTMLDivElement[]>([]);

  // -- Local variables
  const [primaryLinks, secondaryLinks] = usePaneLinks(primaryLinksCollection);
  const Styles: NavStyles = useStyles({ cta: null, sticky });
  const ctas: ContentfulCta[] = loggedOutCtasCollection.items || [];
  const loggedInCtas: LoggedInCtas = {
    isRevver: revverCtasCollection.items || [],
    isCustomer: customerCtasCollection.items || [],
    isAdminOrSupport: adminCtasCollection.items || [],
  };
  const currentPage = slug?.startsWith('/') ? slug : `/${slug}`;

  /**
   * SECTION - State Actions
   */

  const toggleMobileMenu = (): void => {
    window.requestAnimationFrame(() => {
      setMenuOpen(!menuOpen);

      if (window.innerWidth > 768) {
        setPrimaryPane((state) => (state ? '' : state));
        setSecondaryPane((state) => (state ? '' : state));
      }
    });
  };

  const togglePrimaryPane: MouseFocus = (e) => {
    const section: string = e.currentTarget.dataset.focus || '';

    if (section === primaryPane) e.currentTarget.blur();

    if (!sticky) {
      $areaMap.current[section].ref.firstElementChild!.scrollTo(0, 0);
    }

    window.requestAnimationFrame(() => {
      setPrimaryPane((state) => (section === state ? '' : section));
      setSecondaryPane((state) => (state ? '' : state));
    });
  };

  // Close one pane at a time
  const stateCascade = (): void => {
    if (secondaryPane) setSecondaryPane('');
    else if (primaryPane) setPrimaryPane('');
    else if (menuOpen) setMenuOpen(false);
  };

  // Close ALL panes
  const closeNav: CloseNav = (override) => () => {
    if (override || window.innerWidth > 768) {
      window.requestAnimationFrame(() => {
        setMenuOpen((state) => (state ? false : state));
        setPrimaryPane((state) => (state ? '' : state));
        setSecondaryPane((state) => (state ? '' : state));
      });
    }
  };

  /**
   * SECTION - User Interactions
   */

  const setFocus: KeyboardFocus = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      const target = e.currentTarget;
      const section: string = target.dataset.focus || '';

      const cta: HTMLElement = section
        ? $areaMap.current[section].first
        : $buttonMap.current[secondaryPane || primaryPane || 'Main Menu'];

      const awaitFocus = window.setInterval(() => {
        cta.focus();

        if (document.activeElement === cta) {
          clearInterval(awaitFocus);

          if (!section) stateCascade();
        }
      });
    }
  };

  const keyboardNavigation: KeyboardFocus = (e) => {
    // We want `setFocus` to handle Enter and Space
    if (e.key === 'Enter' || e.key === ' ') return undefined;

    const buttons = $buttonMap.current;
    const areas = $areaMap.current;
    const state: string = secondaryPane || primaryPane || 'Main Menu';

    // Back out
    if (e.key === 'Escape') {
      e.preventDefault();
      stateCascade();
      buttons[state].focus();

      return undefined;
    }

    const target = document.activeElement as InteractiveElement;
    const area: string = target.dataset.related || '';
    const firstEl: HTMLElement = areas[area]?.first;
    const lastEl: HTMLElement = areas[area]?.last;
    const tabBack = e.key === 'Tab' && e.shiftKey;
    const tabForward = e.key === 'Tab' && !e.shiftKey;
    const firstFocusNext: boolean = target === firstEl && tabBack;
    const lastFocusNext: boolean = target === lastEl && tabForward;

    // Desktop 'Escaping'
    // -->
    if (!menuOpen) {
      if (primaryPane && !secondaryPane) {
        if (firstFocusNext || lastFocusNext) {
          e.preventDefault();
          buttons[primaryPane].focus();
          setPrimaryPane('');
        }
      }

      if (secondaryPane) {
        if (firstFocusNext || lastFocusNext) {
          e.preventDefault();
          buttons[secondaryPane].focus();
          setSecondaryPane('');
        }
      }
    }
    // <--

    // Mobile 'Trapping'
    // -->
    if (menuOpen) {
      if (area !== 'User Menu') {
        if (firstFocusNext) {
          e.preventDefault();
          buttons[`${area} Back`].focus();
        }

        // Skip to User Menu
        if (lastFocusNext) {
          e.preventDefault();
          areas['User Menu'].first.focus();
        }
      }

      if (area === 'User Menu') {
        if (firstFocusNext) {
          e.preventDefault();
          areas[state].last.focus();
        }

        // Skip to Close/Back button
        if (lastFocusNext) {
          e.preventDefault();
          buttons[`${state} Back`].focus();
        }
      }

      if (target.dataset.name?.includes('Back')) {
        if (tabBack) {
          e.preventDefault();
          areas['User Menu'].last.focus();
        }

        // Start over
        if (tabForward) {
          e.preventDefault();
          areas[state].first.focus();
        }
      }
    }
    // <--
  };

  /**
   * SECTION - Side effects
   */

  /** GROUP - User Events */

  useEffect(() => {
    setPath(window.location.pathname);
    const links = [
      ...($nav.current?.querySelectorAll<HTMLAnchorElement>('a') || []),
    ];

    const loadNextPage: EventListener = (e) => {
      e.preventDefault();

      const target = e.currentTarget as HTMLAnchorElement;
      const url = new URL(target.href);
      const newTab = target.target.includes('_blank');

      closeNav(true)();

      newTab
        ? window.open(url.href)
        : setTimeout(() => window.location.assign(url.href), 100);
    };

    const documentBlur = (): void => {
      if (document.visibilityState === 'hidden') closeNav()();
    };

    // creating a waypoint to resolve async
    const getLoggedInRole = async () => {
      const role = await getRole();
      setLoggedIn(role);
    };

    // dont do this if they are not logged in
    if (isLoggedIn()) {
      getLoggedInRole();
    }

    document.addEventListener('click', closeNav(), { passive: true });
    document.addEventListener('visibilitychange', documentBlur);
    links.forEach((link) => link.addEventListener('click', loadNextPage));

    return () => {
      document.removeEventListener('click', closeNav());
      document.removeEventListener('visibilitychange', documentBlur);
      links.forEach((link) => link.removeEventListener('click', loadNextPage));
    };
  }, []);

  /** GROUP - Body Scroll */

  useEffect(() => {
    if (menuOpen || primaryPane) {
      $scrollAreas.current.forEach((area) => disableBodyScroll(area));
    } else $scrollAreas.current.forEach((area) => enableBodyScroll(area));

    return () => clearAllBodyScrollLocks();
  }, [primaryPane, menuOpen]);

  /** GROUP - Build Refs */

  useEffect(() => {
    const nav = $nav.current;

    if (nav) {
      const buttons = [
        ...nav.querySelectorAll<InteractiveElement>('[aria-controls]'),
      ];
      const areas = [
        ...nav.querySelectorAll<HTMLElement>(
          '[role=tabpanel], [role=tablist], [role=group]'
        ),
      ];

      const { buttonCollection, areaCollection } = useRefReducers({
        buttons,
        areas,
      });

      $buttonMap.current = buttonCollection;
      $areaMap.current = areaCollection;
      $scrollAreas.current = [
        ...nav.querySelectorAll<HTMLDivElement>('[data-scroll]'),
      ];
    }
  }, []);

  /** GROUP - Navbar and Banner Height */

  useIsomorphicEffect(() => {
    const threshold = $threshold.current;

    let banner: HTMLElement | null = null;
    let bannerBtn: HTMLButtonElement | null = null;
    let height = 0;

    setSiteBanner(!!banner);

    /**
     * We want the functions in windowResize to fire only once per breakpoint,
     * not on every resize event.
     */
    threshold.large = window.innerWidth < 1280;
    threshold.small = window.innerWidth < 768;

    const setBannerHeight = (collapse = false): void => {
      const bannerHeight = collapse ? 0 : banner?.offsetHeight || 0;

      if (bannerHeight !== height) {
        $nav.current?.style.setProperty('--banner-height', `${bannerHeight}px`);

        height = bannerHeight;
      }
    };

    const windowResize = (): void => {
      if (window.innerWidth >= 768) {
        setBannerHeight();

        // Nav management for smaller screens
        // -->
        if (!threshold.small) {
          closeNav(true)();
          threshold.small = true;
        }
      } else {
        if (threshold.small) {
          closeNav(true)();
          threshold.small = false;
        }
        // <--
      }

      // Mobile state and nav management for larger screens
      // -->
      if (window.innerWidth >= 1280 && !threshold.large) {
        setIsMobile(false);
        closeNav()();
        threshold.large = true;
      }

      if (window.innerWidth < 1280 && threshold.large) {
        setIsMobile(true);
        closeNav()();
        threshold.large = false;
      }
      // <--
    };

    /**
     * SiteBanner is rendered from a React state, so we need to wait a bit for
     * it "mount" to query the element.
     */
    window.setTimeout(() => {
      banner = document.querySelector('#rev-site-banner');
      bannerBtn = banner?.querySelector('button') || null;

      bannerBtn?.addEventListener(
        'click',
        () => {
          setBannerHeight(true);
          setSiteBanner(false);
        },
        {
          once: true,
        }
      );
      setBannerHeight();
      setSiteBanner(!!banner);
    }, 100);

    window.addEventListener('resize', windowResize, { passive: true });
    windowResize();

    return () => window.removeEventListener('resize', windowResize);
  }, []);

  /** GROUP - Scroll Position */

  useIsomorphicEffect(() => {
    const scrollPosition = (): void => {
      const pos = window.scrollY;

      if (sticky && siteBanner && pos <= 47) {
        $nav.current?.style.setProperty('--scroll-pos', `${pos}px`);
      }

      if (sticky && siteBanner && pos > 47) {
        $nav.current?.style.setProperty('--scroll-pos', `46px`);
      }

      if (sticky && !siteBanner) {
        $nav.current?.style.setProperty('--scroll-pos', `0px`);
      }

      if (!sticky && pos < 200) {
        $nav.current?.style.setProperty('--scroll-pos', `${pos}px`);
      }

      return undefined;
    };

    window.addEventListener('scroll', scrollPosition, { passive: true });

    return () => window.removeEventListener('scroll', scrollPosition);
  }, [siteBanner]);

  /**
   * SECTION - Render
   */

  return (
    <StrictMode>
      <nav
        sx={Styles.ROOT}
        className={primaryPane || menuOpen ? '--open' : ''}
        aria-label="Site Navigation"
        id="nav"
        ref={$nav}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={keyboardNavigation}
      >
        {/*
         * GROUP - Logo
         */}
        <a
          sx={Styles.LOGO}
          href={loggedIn ? '/app' : '/'}
          aria-current={currentPage === '/' ? 'page' : undefined}
        >
          <Logo />
        </a>
        {/*
         * GROUP - Open Menu Button
         */}
        {!!primaryLinks.length && (
          <>
            <IconButton
              sx={Styles.OPEN_MENU}
              variant="Open Menu"
              aria-haspopup="true"
              aria-expanded={menuOpen}
              aria-controls="nav-main-menu, nav-user-menu"
              data-focus="Main Menu"
              data-related="Nav"
              onClick={toggleMobileMenu}
              onKeyPress={setFocus}
            >
              Open Navigation Menu
            </IconButton>

            <menu
              sx={Styles.PANE}
              id="nav-main-menu"
              className={`__menu ${menuOpen ? '--open' : ''}`}
              role="tablist"
              aria-label="Main navigation tabs"
              aria-hidden={isMobile ? !menuOpen : false}
              aria-orientation={isMobile ? 'vertical' : 'horizontal'}
              data-area="Main Menu"
            >
              <div sx={Styles.PANE_SCROLL_AREA} tabIndex={-1} data-scroll>
                <div sx={Styles.PANE_CONTENT}>
                  {/*
                   * GROUP - Main Menu Buttons
                   */}
                  {!!primaryLinks.length &&
                    primaryLinks.map((link) =>
                      isPrimaryNavPane(link) ? (
                        <RootButton
                          {...link}
                          selected={primaryPane === link.heading}
                          key={link.sys.id}
                          onClick={togglePrimaryPane}
                          onKeyPress={setFocus}
                        />
                      ) : (
                        <Link
                          key={link.sys.id}
                          variant="Simple"
                          sx={Styles.ROOT_BUTTON}
                          href={link.destination || '/'}
                          openInNewTab={link.openInNewTab}
                          onClick={getLinkClickHandlerWithTracking(
                            link.analyticsEvent,
                            link.ctaCopy,
                            path,
                            link.destination
                          )}
                        >
                          {link.ctaCopy}
                        </Link>
                      )
                    )}
                  {/*
                   * GROUP - Close Menu Button
                   */}
                  <IconButton
                    sx={Styles.CLOSE_MENU}
                    variant="Close Menu"
                    aria-controls="nav-main-menu, nav-user-menu"
                    data-related="Main Menu"
                    data-name="Main Menu Back"
                    onClick={toggleMobileMenu}
                    onKeyPress={setFocus}
                  >
                    Close Navigation Menu
                  </IconButton>
                </div>
              </div>
            </menu>
          </>
        )}
        {!!primaryLinks.length && (
          <div
            sx={Styles.USER_MENU}
            id="nav-user-menu"
            role="group"
            aria-label="User Menu"
            data-area="User Menu"
          >
            <div>
              {/*
               * GROUP - User Menu
               */}
              {loggedIn === null &&
                ctas.map((cta, i) => {
                  if (!cta.destination) return null;

                  return (
                    <a
                      sx={
                        ctas.length > 1 && i === ctas.length - 1 && buttonCtas
                          ? Styles.SECOND_CTA
                          : Styles.FIRST_CTA
                      }
                      href={cta.destination}
                      data-related="User Menu"
                      key={cta.sys.id}
                      onClick={getLinkClickHandlerWithTracking(
                        cta.analyticsEvent,
                        cta.ctaCopy,
                        path,
                        cta.destination
                      )}
                    >
                      <span>{cta.ctaCopy}</span>
                    </a>
                  );
                })}
              {loggedIn !== null &&
                //use state value to get correct cta collection
                loggedInCtas[loggedIn].map((cta, i) => {
                  if (!cta.destination) return null;

                  return (
                    <a
                      sx={
                        loggedInCtas[loggedIn].length > 1 &&
                        i === loggedInCtas[loggedIn].length - 1 &&
                        buttonCtas
                          ? Styles.SECOND_CTA
                          : Styles.FIRST_CTA
                      }
                      href={cta.destination}
                      data-related="User Menu"
                      key={cta.sys.id}
                      onClick={getLinkClickHandlerWithTracking(
                        cta.analyticsEvent,
                        cta.ctaCopy,
                        path,
                        cta.destination
                      )}
                    >
                      <span>{cta.ctaCopy}</span>
                    </a>
                  );
                })}
            </div>
          </div>
        )}
        {/*
         * GROUP - Simple User Menu
         */}
        {primaryLinks.length === 0 && (
          <div
            sx={Styles.SIMPLE_USER_MENU}
            id="nav-user-menu"
            role="group"
            aria-label="User Menu"
            data-area="User Menu"
          >
            <div>
              {/*
               * GROUP - User Menu
               */}
              {loggedIn === null &&
                ctas.map((cta, i) => {
                  if (!cta.destination) return null;

                  return (
                    <a
                      sx={
                        ctas.length > 1 && i === ctas.length - 1 && buttonCtas
                          ? Styles.SIMPLE_SECOND_CTA
                          : Styles.SIMPLE_FIRST_CTA
                      }
                      href={cta.destination}
                      data-related="User Menu"
                      key={cta.sys.id}
                      onClick={getLinkClickHandlerWithTracking(
                        cta.analyticsEvent,
                        cta.ctaCopy,
                        path,
                        cta.destination
                      )}
                    >
                      <span>{cta.ctaCopy}</span>
                    </a>
                  );
                })}
              {loggedIn !== null &&
                //use state value to get correct cta collection
                loggedInCtas[loggedIn].map((cta, i) => {
                  if (!cta.destination) return null;

                  return (
                    <a
                      sx={
                        loggedInCtas[loggedIn].length > 1 &&
                        i === loggedInCtas[loggedIn].length - 1 &&
                        buttonCtas
                          ? Styles.SIMPLE_SECOND_CTA
                          : Styles.SIMPLE_FIRST_CTA
                      }
                      href={cta.destination}
                      data-related="User Menu"
                      key={cta.sys.id}
                      onClick={getLinkClickHandlerWithTracking(
                        cta.analyticsEvent,
                        cta.ctaCopy,
                        path,
                        cta.destination
                      )}
                    >
                      <span>{cta.ctaCopy}</span>
                    </a>
                  );
                })}
            </div>
          </div>
        )}
        {!!primaryLinks.length &&
          primaryLinks
            .filter(isPrimaryNavPane)
            .map((link) => (
              <PrimaryPane
                {...link}
                currentPage={currentPage}
                open={primaryPane === link.heading}
                backFunc={setPrimaryPane}
                secondaryState={[secondaryPane, setSecondaryPane]}
                areas={$areaMap.current}
                setFocus={setFocus}
                key={link.sys.id}
              />
            ))}
        {!!secondaryLinks.length &&
          secondaryLinks.map((link) => (
            <SecondaryPane
              {...link}
              currentPage={currentPage}
              backTitle={primaryPane}
              open={secondaryPane === link.heading}
              backFunc={setSecondaryPane}
              setFocus={setFocus}
              key={link.sys.id}
            />
          ))}
      </nav>
    </StrictMode>
  );
};

export default Navigation;
