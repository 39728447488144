import type { FooterStyles as FS } from './types';
import type { ThemeUIStyleObject as TSO } from 'theme-ui';
import { useContext } from 'react';
import { SpacingContext } from '@lib/hooks';

export const useStyles = ({ colorMode }: FS): typeof Styles => {
  const bkg = colorMode === 'Dark' ? 'grey.9' : 'grey.1';
  const links = colorMode === 'Dark' ? 'grey.1' : 'grey.9';
  const linkHover = colorMode === 'Dark' ? 'lilac.3' : 'violet.7';

  const { footerSpacing } = useContext(SpacingContext);

  const Styles = {
    ROOT: {
      contain: 'content',
      backgroundColor: bkg,
      color: links,
      paddingInlineStart: [0, 3],
      paddingInlineEnd: [0, 3],
      marginBlockStart: !footerSpacing ? '0px' : 8,
    } as TSO,

    FOOTER_CONTAINER: {
      paddingBlockStart: 6,
      paddingBlockEnd: '2em',
      fontSize: 0,
    } as TSO,

    LINKS_LIST: {
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      li: {
        transition: 'color .2s',
        '&:hover':{
          color: linkHover,
        },
        '&:not(:last-of-type)': {
          marginInlineEnd: 3,
        },
      },
    } as TSO,
  };

  return Styles;
};
