import { forwardRef } from 'react';
import { useStyles } from './button.styles';
import { useContrast, useSanitizer } from '#hooks';

import type { ButtonProps as Props, ButtonRef as Ref } from './types';

const Button = forwardRef<Ref, Props>(
  (
    {
      href,
      children,
      componentStyle,
      variant = 'dark',
      type = 'button',
      contrast = 'Default',
      color,
      withArrow = false,
      openInNewTab = false,
      ...props
    },
    ref
  ) => {
    if (!children) return null;
    if (color === 'White') variant = 'light';
    if (color === 'Violet') variant = 'gradient';

    //if no color passed useContrast hook to determine best button color
    const { ui } = useContrast(contrast);
    if (!color && ui.bkg === '#FFF') variant = 'light';

    const styles = useStyles({ componentStyle });
    const attrs = useSanitizer(props);


    if (href) {
      return (
        // sigh... this plugin can't interpret the statement for the rel prop
        /*  eslint-disable-next-line react/jsx-no-target-blank */
        <a
          href={href}
          // @ts-expect-error - Figuring out how to type this properly
          ref={ref}
          rel={openInNewTab ? 'noreferrer noopener' : undefined}
          target={openInNewTab ? '_blank' : undefined}
          sx={styles[variant]}
          role="button" // !!! this needs to be removed ASAP
          {...attrs}
        >
          <span>{children}</span>
        </a>
      );
    }

    return (
      <button
        type={type}
        // @ts-expect-error - Figuring out how to type this properly
        ref={ref}
        sx={{ ...styles[variant] }}
        {...attrs}
      >
        {withArrow ? (
          <span
            sx={{
              display: 'inline-flex',
              MozPaddingStart: ['2em', 0],
              MozPaddingEnd: ['2em', 0],
              '&:after': {
                content: '" "',
                marginInlineStart: '0.5rem',
                inlineSize: '28px',
                background: `url("data:image/svg+xml,%0A%3Csvg width='28' height='16' viewBox='0 0 28 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.7071 8.7071C28.0976 8.31658 28.0976 7.68342 27.7071 7.29289L21.3431 0.92893C20.9526 0.538406 20.3195 0.538406 19.9289 0.92893C19.5384 1.31945 19.5384 1.95262 19.9289 2.34314L25.5858 8L19.9289 13.6569C19.5384 14.0474 19.5384 14.6805 19.9289 15.0711C20.3195 15.4616 20.9526 15.4616 21.3431 15.0711L27.7071 8.7071ZM8.74228e-08 9L27 9L27 7L-8.74228e-08 7L8.74228e-08 9Z' fill='white'/%3E%3C/svg%3E%0A")`,
              },
            }}
          >
            {children}
          </span>
        ) : (
          <span>{children}</span>
        )}
      </button>
    );
  }
);

export default Button;
