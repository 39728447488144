export const AccordionSection = `#graphql
  fragment AccordionSection on AccordionSection {
    ...Entry
    heading
    itemsCollection(limit: 12) {
      items {
        ...Tile
      }
    }
    cta {
      ...Cta
    }
  }
`;
