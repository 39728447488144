export const isContentfulPreview = (): boolean => {
  if (
    process.env.NEXT_PUBLIC_VERCEL_ENV &&
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
  ) {
    // Need to handle the preview deployment for the pre-staging environment separately.
    // We want any deployments with the "release" branch name to be as close to production
    // as possible meaning we want to set the preview variable to false.
    const vercelContentfulEnv =
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF?.replace(
        'refs/heads/',
        ''
      ).replace('/', '_');
    const releaseKeywords = ['release', 'Release', 'RELEASE'];
    if (releaseKeywords.some((s) => vercelContentfulEnv?.includes(s))) {
      return false;
    }
  }
  return process.env.CONTENTFUL_PREVIEW === 'true';
};

/** This function will return the environment ID specified in your `.env` file.
 * If you include any of the develop keywords in your branch name, during deployment to github it will use the current develop environment in Contentful to build.
 * If you use any of the release keywords in your branch name, it will use the staging environment in Contentful. Otherwise, if you do not use either of those keywords, it will use the environment ID specified in your `.env` file to build.
 * The contentful environment ID is used in the @see {@function request} to query the correct environment.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getContentfulEnvironmentId = () => {
  if (
    process.env.NEXT_PUBLIC_VERCEL_ENV &&
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
  ) {
    // Assuming all branches will follow {category}/{issue-number} naming convention.
    // branches that include any if the develop keywords e.g. feature/develop/CMS-123
    // will return the develop environment
    const vercelContentfulEnv =
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF?.replace(
        'refs/heads/',
        ''
      ).replace('/', '_');
    const developKeywords = [
      'develop',
      'Develop',
      'DEVELOP',
      'dev',
      'Dev',
      'DEV',
    ];
    const releaseKeywords = ['release', 'Release', 'RELEASE'];
    if (developKeywords.some((s) => vercelContentfulEnv?.includes(s))) {
      return 'develop' as string;
    } else if (releaseKeywords.some((s) => vercelContentfulEnv?.includes(s))) {
      return 'staging' as string;
    } else {
      return vercelContentfulEnv;
    }
  }
  return process.env.CONTENTFUL_ENVIRONMENT_ID;
};
