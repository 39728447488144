import type { FC } from 'react';
import type { CarouselButtonsProps as Props } from './types';
import { useStyles } from './carouselButton.styles';

const ProgressButton: FC<Props> = ({ selected, onClick }) => {
  const Styles = useStyles();
  const isSelected = selected ? 'selected' : '';

  return (
    <button
      title="select slide"
      sx={isSelected ? Styles.PROGRESS_DOTS_SELECTED : Styles.PROGRESS_DOTS}
      type="button"
      onClick={onClick}
    />
  );
};

export default ProgressButton;
