import { ColorHex } from '@lib/constants';
import type { ThemeUIStyleObject } from 'theme-ui';
import type { ButtonBase, ButtonVariant } from './types';

type ButtonStyles = Record<ButtonVariant, ThemeUIStyleObject>;

const useStyles = ({
  componentStyle,
}: Partial<ButtonBase>): ButtonStyles => {
  const base: ThemeUIStyleObject = {
    variant: 'buttons.root',
    display: 'inline-flex',
    inlineSize: ['100%', 'auto'],
    minBlockSize: 2,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    border: '0px',
    borderRadius: 2,
    paddingBlockStart: 3,
    paddingBlockEnd: 3,

    '& span': {
      paddingInlineStart: 5,
      paddingInlineEnd: 5,
      whiteSpace: 'nowrap',
    },
  };

  return {
    gradient: {
      ...base,
      color: ColorHex.White,
      backgroundImage: 'linear-gradient(90deg, #3F00FF, #8D57FF 60%)',
      backgroundSize: '200% 100%',
      backgroundPositionX: '0%',
      transition: 'background-position-x .2s',
      ...componentStyle,

      '&:hover, &:focus': {
        backgroundPositionX: '40%',
      },
    },

    dark: {
      ...base,
      color: ColorHex.White,
      backgroundColor: ColorHex.Black,
      transition: 'background-color .2s',
      ...componentStyle,

      '&:hover, &:focus': {
        backgroundColor: ColorHex.NeonBlue,
      },
    },

    light: {
      ...base,
      color: ColorHex.Black,
      backgroundColor: ColorHex.White,
      transition: 'all .2s',
      ...componentStyle,

      '&:hover, &:focus': {
        color: ColorHex.White,
        backgroundColor: ColorHex.Black,
      },
    },
  };
};

export { useStyles };
