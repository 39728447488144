export const SecondaryNavPane = `#graphql
  fragment SecondaryNavPane on SecondaryNavPane {
    ...Entry
    heading
    ctaStyle
    icon
    superheading
    copy
    contentCollection(limit:8) {
      items {
        ...Cta
        ...Tile
      }
    }
    anchoredCta {
      ...Cta
    }
   
  }
`;
