import type { ThemeUIJSX } from '@theme-ui/core'

export const FillIcon = [
  'Sparkle',
  'Twitter',
  'Open Quotes',
] as const;

export const LineIcon = [
  'Open Menu',
  'Close Menu',
  'Left Arrow',
  'Right Arrow',
  'Left Chevron',
  'Right Chevron',
  'Down Chevron',
  'Circle',
] as const;

export const Icons = [...FillIcon, ...LineIcon] as const;

export type IconType = typeof Icons[number];

export const IconMap: Partial<Record<IconType, ThemeUIJSX.Element>> = {
  'Open Menu': (
    <>
      <path d="M2,8h22" />
      <path d="M2,13h22" />
      <path d="M2,18h22" />
    </>
  ),
  'Close Menu': (
    <>
      <path d="M4,4 22,22" />
      <path d="M22,4 4,22" />
    </>
  ),
  'Sparkle': (
    <>
      <path d="M10,5l2.7,7.3L20,15l-7.3,2.7L10,25,7.3,17.7,0,15l7.3-2.7Z" />
      <path d="M20,1l1.4,3.6L25,6,21.4,7.4,20,11,18.6,7.4,15,6l3.6-1.4Z" />
    </>
  ),
  'Right Chevron': (
    <path d="M9,3 19,13 9,23" />
  ),
  'Down Chevron': (
    <path d="M6,10 13,17 20,10" />
  ),
  'Left Chevron': (
    <path d="M17,3 7,13 17,23" />
  ),
  'Right Arrow': (
    <>
      <path d="M12,3 22,13 12,23" />
      <path d="M4,13 22,13" />
    </>
  ),
  'Left Arrow': (
    <>
      <path d="M14,3 4,13 14,23" />
      <path d="M4,13 22,13" />
    </>
  ),
  'Twitter': (
    <path d="M25,5.6c-.9,.4-1.8,.7-2.8,.8,1-.6,1.8-1.6,2.2-2.7-1,.6-2,1-3.1,1.2-.9-1-2.2-1.6-3.6-1.6-2.7,0-4.9,2.2-4.9,4.9s0,.8,.1,1.1c-4.1-.2-7.7-2.2-10.1-5.1-.4,.7-.7,1.6-.7,2.5,0,1.7,.9,3.2,2.2,4.1-.8,0-1.6-.2-2.2-.6h0c0,2.4,1.7,4.4,3.9,4.9-.4,.1-.8,.2-1.3,.2s-.6,0-.9,0c.6,2,2.4,3.4,4.6,3.4-1.7,1.3-3.8,2.1-6.1,2.1s-.8,0-1.2,0c2.2,1.4,4.8,2.2,7.5,2.2,9.1,0,14-7.5,14-14s0-.4,0-.6c1-.7,1.8-1.6,2.5-2.5Z"/>
  ),
  'Open Quotes': (
    <path d="M10,6l-1,6h3v8H2V12l2-6h6Zm12,0l-1,6h3v8H14V12l2-6h6Z" />
  ),
  'Circle': (
    <circle cx="13" cy="13" r="10" />
  ),
} as const;
