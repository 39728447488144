/**
 * FooterVariant
 */

export const FooterVariant = {
  FULL: 'Full',
  SIMPLE: 'Simple',
} as const;

export const FOOTER_VARIANTS = Object.values(FooterVariant);

export type FooterVariants = typeof FOOTER_VARIANTS[number];

/**
 * FooterColorMode
 */

export const FooterColorMode = {
  DARK: 'Dark',
  LIGHT: 'Light',
} as const;

export const FOOTER_COLORMODE = Object.values(FooterColorMode);

export type FooterColorModes = typeof FOOTER_COLORMODE[number];
