import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    ROOT: {
      display: 'flex',
      flexFlow: ['column wrap', 'row nowrap'],
      inlineSize: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      marginBlockStart: '0.5em',
      paddingBlockStart: [4, 5],
      paddingBlockEnd: [4, 5],
      backgroundColor: 'surface.primary',
    } as TSO,

    IMAGE_CONTAINER: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: ['flex-start', 'flex-end', 'center'],
    } as TSO,

    IMAGE: {
      inlineSize: '14rem',
      blockSize: '14rem',
    } as TSO,

    ICON: {
      zIndex: 10,
      position: 'absolute',
      maxInlineSize: '5.625rem',
      transform: [
        'translateX(215%) translateY(40%)',
        'translateX(65%) translateY(40%)',
        'translateX(140%) translateY(40%)',
      ],
    } as TSO,

    DESCRIPTION_COPY_CONTAINER: {
      display: 'flex',
      flexFlow: 'column wrap',
      flex: '1',
      marginBlockStart: [4, 5, 0],
    } as TSO,

    DESCRIPTION_COPY: {
      marginBlockStart: [4, 0],
    } as TSO,

    JOB_NAME_CONTAINER: {
      fontSize: '.875rem',
      fontWeight: 'body',
      marginBlockStart: 2,
    } as TSO,
  };

  return Styles;
};
