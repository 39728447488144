import { useState } from 'react';
import ResponsiveMedia from '@components/ResponsiveMedia';
import { propsValidator } from '@lib/utils';
import { Link, H5, H4 } from '#ui';
import { useStyles } from './feature.styles';

import type { FC } from 'react';
import type { FeatureProps as Props } from './types';

// TODO: Create an 'imageHeight' prop for the 'forceHeight' prop of ResponsiveMedia
const Tile: FC<Props> = ({
  heading,
  subtitle,
  responsiveMedia,
  description,
  cta,
  className,
  parent,
  // imageHeight
  ...entry
}) => {
  const valid = propsValidator(entry, { heading });

  if (!valid) return null;

  const [focused, setFocused] = useState(false);
  const Styles = useStyles();

  const toggleFocusedState = (): void => setFocused(!focused);

  return (
    <section
      sx={Styles.ROOT}
      aria-label={heading!}
      className={`${className} ${focused ? '--focused' : ''}`}
    >
      {responsiveMedia?.desktopAsset?.url && 
        <ResponsiveMedia
          fit="cover"
          // forceHeight={imageHeight}
          {...responsiveMedia}
        />
      }
      <div sx={Styles.TEXT_SECTION}>
        {!!subtitle?.length && (
          <H5 as='small'>
            {subtitle}
          </H5>
        )}
        <H4 as='h3'>{heading}</H4>
        {description && <p sx={Styles.COPY}>{description}</p>}
        {cta?.destination && (
          <Link
            sx={Styles.CTA}
            href={cta.destination}
            withIcon
            data-related={parent || undefined}
            onMouseOver={toggleFocusedState}
            onMouseOut={toggleFocusedState}
            openInNewTab={cta.openInNewTab}
          >
            {cta.ctaCopy}
          </Link>
        )}
      </div>
    </section>
  );
};

export default Tile;
