import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    ROOT: { 
      contain: 'content',
      overflow: 'hidden',

      '&:focus-within, &.--focused': {
        
        '& img, & p, & small': { opacity: .6 }
      },

      // Thanks safari...
      '@supports not (-webkit-alt:unset)': {
        '& img, & p, & small': { transition: 'opacity .2s' },
      },
    } as TSO,

    TEXT_SECTION: {
      marginBlockStart: 3,
    } as TSO,

    COPY: {
      fontSize: 0,
      marginBlockStart: 0,
      color: 'inherit',
    } as TSO,

    CTA: { marginBlockStart: 0 } as TSO,
  };

  return Styles;
};
