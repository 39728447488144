export const LargeTextSection = `#graphql
  fragment LargeTextSection on LargeTextSection {
    ...Entry
    mdCopy
    cta{
      ...Cta
    }
    simpleIcon {
      ...Asset
    }
  }
`;
