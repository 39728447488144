export const ResponsiveMedia = `#graphql
  fragment ResponsiveMedia on ResponsiveMedia {
    ...Entry
    wistiaVideoUrl
    youtubeId
    vimeoId
    vimeoHash
    desktopAsset {
      ...Asset
    }
    mobileAsset {
      ...Asset
    }
    altText
    closedCaptionsCollection(limit:1) {
      items {
        ...Asset
      }
    }
    posterImage {
      ...Asset
    }
    showVideoControls
  }
`;
