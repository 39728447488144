/* eslint-disable */
import type { Theme } from 'theme-ui';

export const theme: Theme = {
  config: {
    initialColorModeName: 'light',
    useBorderBox: false,
    useColorSchemeMediaQuery: false,
    useLocalStorage: false,
  },
  space: [
    8, // 0
    13, // 1
    16, // 2
    20, // 3
    25, // 4
    45, // 5
    70, // 6
    100, // 7
    125, // 8
    250, // 9

    // Gaps
    10, // 10
    20, // 11
  ],
  sizes: [
    10, // 0
    20, // 1
    46, // 2
    50, // 3
    80, // 4
    180, // 5
    750, // 6
    1440, // 7

    // Icon
    65, // 8

    // Heading
    855, // 9

    // Subheading
    870, // 10
  ],
  breakpoints: ['768px', '1280px', '1680px'],

  // -- Fonts -- //

  fonts: {
    body: '"Open Sans", sans-serif',
    heading: 'Raleway, sans-serif',
    quote: '"Lora", serif',
  },
  fontSizes: [
    '0.9375rem', // 15px -- 0
    '1rem', // 16px -- 1
    '1.125rem', // 18px -- 2
    '1.25rem', // 20px -- 3
    '1.5rem', // 24px -- 4
    '1.75rem', // 28px -- 5
    '2.5rem', // 40px -- 6
    '3rem', // 48px -- 7
    '4.25rem', // 68px -- 8
  ],
  fontWeights: {
    body: 400,
    regular: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    heading: 900,
  },
  lineHeights: {
    body: 1.8,
    heading: 1.3,
    button: 1,
  },
  letterSpacings: {
    button: '.02ch',
    comfort: '.03ch',
    heading: '-.01ch',
    tiny: '.05ch',
  },

  // -- Colors -- //

  colors: {
    // Light theme (initial)
    primary: '#2E1A69',
    secondary: '#F4EBDB',
    accent: {
      primary: '#9C95FE',
      secondary: '#5BC6A4',
      tertiary: '#DFFF00',
    },
    surface: {
      primary: '#FFF',
      neutral: '#F4EBDB',
      contrast: '#292929',
      highContrast: '#171717',
      ui: '#000',
    },
    text: {
      primary: '#292929',
      secondary: '#000',
      contrast: '#FFF',
      link: '#6237E2',
      highlight: '#EEFF77',
      ui: '#FFF',
    },
    error: '#AD1D1D',
    success: '#10AC61',
    gradient: 'linear-gradient(99.41deg, #3F00FF 3.15%, #8D57FF 112.44%)',
    border: '#C5C5C5',
    focus: '#5A2EDF',
    black: '#000',
    white: '#FFF',
    actions: {
      rest: '#FFF',
      hover: '#FAFAFA',
    },

    // NOTE: Most ranges on hold for now
    grey: {
      1: '#FAFAFA',
      2: '#EBEBEB',
      3: '#C5C5C5',
      4: '#A2A2A2',
      5: '#8B8B8B',
      6: '#707070',
      7: '#575757',
      8: '#3D3D3D',
      9: '#292929',
      10: '#171717',
    },

    violet: {
      1: '#FAFAFE',
      2: '#ECEAFA',
      3: '#C7C0F1',
      4: '#A598E9',
      5: '#8F7DE2',
      6: '#755AD8',
      7: '#5D3AC7',
      8: '#422790',
      9: '#2C1966',
      10: '#190C40',
    },

    lilac: {
      1: '#FAFAFF',
      2: '#EBEAFF',
      3: '#C3BFFE',
      4: '#9D97FE',
      5: '#8B81FE',
      6: '#6553FD',
      7: '#4521FC',
      8: '#2D04C4',
      9: '#1E028C',
      10: '#0F0159',
    },

    neon: {
      1: '#7D45FF',
      2: '#4507FF',
    },

    // Dark theme
    modes: {
      dark: {
        surface: {
          primary: '#171717',
          contrast: '#FAFAFA',
          highContrast: '#FFF',
          ui: '#FFF',
        },
        text: {
          primary: '#FFF',
          secondary: '#FAFAFA',
          contrast: '#000',
          link: '#9998F7',
          ui: '#000',
        },
      },
    },
  },

  // -- Misc. -- //

  borders: {
    cta: '3px solid currentColor',
    section: '25px solid #DFFF00',
    phone: '20px solid #EBEBEB',
    input: {
      default: '1px solid #A2A2A2',
      focus: '2px solid #5A2EDF',
      error: '2px solid #AD1D1D',
      valid: '1px solid',
    },
    pane: '1px solid #C5C5C5',
  },
  borderWidths: [3, 20, 25],
  radii: [
    '999px', // 0
    '100%', // 1
    '4px', // 2
    '12px', // 3
    '58px', // 4
    '100px', // 5
    '150px', // 6
    '200px', // 7
  ],
  shadows: [
    '0px 0px 22px rgba(0, 0, 0, 0.42)',
    '0px 0px 30px rgba(0, 0, 0, 0.09)',
    '0px 0px 32px rgba(0, 0, 0, 0.25)',
  ],
  zIndices: {
    subBkg: -1,
    bkg: 1,
    surface: 2,
    nav: 9,
    modal: 10,
  },

  // -- Variants -- //

  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'heading',
      // color: 'text.primary',
    },
    input: {
      color: 'black',
      fontFamily: 'body',
      fontWeight: 'regular',
      lineHeight: '1.4',
      fontSize: 2,
    },
  },

  buttons: {
    root: {
      fontFamily: 'heading',
      fontWeight: 'extraBold',
      fontSize: 1,
      lineHeight: 'button',
      letterSpacing: 'button',
    },
  },

  links: {
    root: {
      fontFamily: 'body',
      fontWeight: 'bold',
      fontSize: 1,
      lineHeight: 'body',
    },
    inline: { font: 'inherit' },
  },

  // -- Themed Styles -- //

  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      fontSize: 1,
      lineHeight: 'body',
      m: '0px',
      color: 'text.primary',
      backgroundColor: 'surface.primary',
    },
    h1: {
      variant: 'text.heading',
      fontWeight: 'heading',
      fontSize: [7, 8],
      '&:not(:first-of-type)': { marginBlockStart: 2 },
    },
    h2: {
      variant: 'text.heading',
      fontWeight: ['heading', 'extraBold'],
      fontSize: [5, 7],
      '&:not(:first-of-type)': { marginBlockStart: 6 },
    },
    h3: {
      variant: 'text.heading',
      fontWeight: ['extraBold', 'regular'],
      fontSize: [4, 6],
      '&:not(:first-of-type)': { marginBlockStart: 5 },
    },
    h4: {
      variant: 'text.heading',
      fontWeight: 'heading',
      fontSize: [3, 4],
      letterSpacing: 'comfort',
      '&:not(:first-of-type)': { marginBlockStart: 5 },
    },
    h5: {
      fontFamily: 'heading',
      fontWeight: 'semiBold',
      textTransform: 'uppercase',
      fontSize: 0,
      letterSpacing: 'comfort',
    },
    p: {
      marginBlockStart: 1,
      fontSize: [null, 2],
      letterSpacing: 'comfort',
    },
    blockquote: {
      fontFamily: 'quote',
      fontWeight: 'regular',
      fontStyle: 'italic',
      lineHeight: 'heading',
      '& p': { fontSize: 5 },
    },
    i: {
      fontFamily: 'quote',
      fontWeight: 'body',
      fontStyle: 'italic',
      fontSize: 2,
      lineHeight: 'heading',
    },
    a: { variant: 'links.root' },
    tr: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'body',
      letterSpacing: 'comfort',
      fontSize: [4, 2],
    },
    ol: {
      marginInlineStart: 4,
      marginBlockStart: 1,
    },
    ul: {
      marginInlineStart: 4,
      marginBlockStart: 1,
    },
    li: {
      letterSpacing: 'comfort',
      '&:not(:first-of-type)': { marginBlockStart: 0 },
      '& p:first-of-type': { marginBlockStart: '0px' },
    },
    em: {
      variant: 'text.heading',
      fontWeight: 'heading',
      fontSize: [7, '6.875rem'],
    },
  },
};
