/**
 * @file - A collection of functions to check types.
 */

import type { Contentful } from '#types';

/**
 * Generic Type Checker
 */

export const isType = <T>(arg: unknown, prop: keyof T): arg is T =>
  typeof arg === 'object' && arg !== null && prop in arg;

/**
 * More Specific Type Checkers
 */

export const isCta = (arg: unknown): arg is Contentful['CTA'] =>
  (arg as Contentful['CTA']).ctaCopy !== undefined;

export const isString = (arg: unknown): arg is string =>
  typeof arg === 'string';

export const isPromiseFulfilled = <T>(
  res: PromiseSettledResult<T>
): res is PromiseFulfilledResult<T> => res.status === 'fulfilled';

/**
 * Deprecated
 */
// export const isNavSection = (arg: unknown): arg is Contentful['NavSection'] =>
//   (arg as Contentful['NavSection']).heading !== undefined;

/**
 * Array checking
 */

export const includes = <T extends U, U>(x: ReadonlyArray<T>, y: U): y is T =>
  x.includes(y as T);
