import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Themed } from 'theme-ui';
import {
  Link,
  LegalOrderedList,
  LegalUnorderedList,
  CheckmarkUnorderedList,
} from '#ui';

import type { FunctionComponent } from 'react';
import type { RichTextProps as Props } from './types';

const RichText: FunctionComponent<Props> = ({
  json,
  linkVariant,
  checkBulletStyle,
  smallCheckBullets = false,
  theme = 'Default',
  bkg = 'White',
  renderNodeOverrides = {},
}) => {
  if (!json) {
    throw `RichText cannot render json that is ${json}`;
  }
  const linkStyle = linkVariant ? linkVariant : 'Text';

  const options: Options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (_, children) => (
        <Themed.h2 as="h3">{children}</Themed.h2>
      ),
      [BLOCKS.HEADING_3]: (_, children) => <Themed.h3>{children}</Themed.h3>,
      [BLOCKS.HEADING_4]: (_, children) => (
        <Themed.h4 as="h3">{children}</Themed.h4>
      ),
      [BLOCKS.PARAGRAPH]: (_, children) => {
        if (!children?.toString()) return null;
        return <Themed.p>{children}</Themed.p>;
      },
      [BLOCKS.LIST_ITEM]: (_, children) => <Themed.li>{children}</Themed.li>,
      [BLOCKS.UL_LIST]: (_, children) =>
        theme === 'Legal' ? (
          <LegalUnorderedList>{children}</LegalUnorderedList>
        ) : checkBulletStyle ? (
          <CheckmarkUnorderedList smallIcons={smallCheckBullets}>
            {children}
          </CheckmarkUnorderedList>
        ) : (
          <Themed.ul>{children}</Themed.ul>
        ),
      [BLOCKS.OL_LIST]: (_, children) =>
        theme === 'Legal' ? (
          <LegalOrderedList>{children}</LegalOrderedList>
        ) : (
          <Themed.ol>{children}</Themed.ol>
        ),
      [BLOCKS.QUOTE]: (_, children) => (
        <Themed.blockquote>{children}</Themed.blockquote>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Link href={node.data.uri} variant={linkStyle} contrast={bkg}>
          {children}
        </Link>
      ),
      ...renderNodeOverrides.renderNode,
    },
  };

  return <>{documentToReactComponents(json, options)}</>;
};

export default RichText;
