import { forwardRef } from 'react';
import { Themed } from 'theme-ui';
import { useContrast } from '#hooks';

import type { PillProps as Props, PillRef as Ref } from './types';

const Pill = forwardRef<Ref, Props>(({ children, ...props }, ref) => {
  if (!children) return null;

  const { text } = useContrast('Secondary');

  return (
    <Themed.p
      sx={{
        display: 'inline',
        p: 0,
        color: text,
        lineHeight: 'button',
        backgroundColor: 'secondary',
        width:'fit-content',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        '&:not(:last-of-type)': {
          marginInlineEnd: 1,
        },
      }}
      ref={ref}
      {...props}
    >
      {children}
    </Themed.p>
  );
});

export default Pill;
