import RichText from '@components/RichText';
import type { FunctionComponent } from 'react';
import { Link, H3 } from '#ui';
import type { ColorTileProps as Props } from './types';
import { useStyles } from './colortile.styles';

const ColorTile: FunctionComponent<Props> = ({
  heading,
  simpleIcon,
  tileCopy,
  cta,
  backgroundColor
}) => {
  const Styles = useStyles({backgroundColor});
  const linkContrast = backgroundColor === 'Violet' ? backgroundColor : 'Default';

  return (
    <article
      aria-label={heading || undefined}
      sx={Styles.ROOT}
    >
      {simpleIcon?.url && 
        <img
          sx={Styles.ICON}
          src={simpleIcon.url}
          alt={simpleIcon.description || ''}
          loading="lazy"
        />
      }
      {(cta?.destination && heading) ? 
        <H3 sx={Styles.HEADING}>
          <Link
            href={cta.destination}
            contrast={linkContrast}
          >
            {heading}
          </Link>
        </H3>
        : (heading && 
          <H3 sx={Styles.HEADING}>
            {heading}
          </H3>
      )}
      {tileCopy && 
        <RichText {...tileCopy}/>
      }
    </article>
  );
};

export default ColorTile;