export const Footer = `#graphql
  fragment Footer on Footer {
    ...Entry
    variant
    colorMode
    leftLinksCollection(limit: 12) {
      items { 
        ...Cta
      }
    }
    columnOneCollection(limit: 5){
      items{
        contentCollection(limit: 10){
          items{
            ...Cta
          }
        }
      }
    }
    columnTwoCollection(limit: 5){
      items{
        contentCollection(limit: 10){
          items{
            ...Cta
          }
        }
      }
    }
    columnThreeCollection(limit: 5){
      items{
        contentCollection(limit: 10){
          items{
            ...Cta
          }
        }
      }
    }
    columnFourCollection(limit: 5){
      items{
        contentCollection(limit: 10){
          items{
            ...Cta
          }
        }
      }
    }
    rightPaneContent {
      ...CopyContent
    }
    rightLinkCta {
      ...Cta
    }
    rightButtonCta {
      ...Cta
    }
    subfooterLinksCollection(limit: 12) {
      items {
        ...Cta
      }
    }
  }
`;
