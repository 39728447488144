import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';
import type { FC } from 'react';
import { Themed } from 'theme-ui';
import { Link } from '#ui';
import type { MarkdownTextProps as Props } from './types';

const MarkdownText: FC<Props> = ({
  styles,
  linkVariant = 'Text',
  overrides = {},
  options = {},
  bkg = 'White',
  linkIcon = false,
  children,
}) => {
  if (!children) return null;

  const componentOptions: MarkdownToJSX.Options = {
    forceBlock: true,
    overrides: {
      h2: { component: Themed.h2 },
      h3: { component: Themed.h3 },
      h4: { component: Themed.h4 },
      h5: { component: Themed.h5 },
      a: {
        component: Link,
        props: { variant: linkVariant, contrast: bkg, withIcon: linkIcon },
      },
      blockquote: { component: Themed.blockquote },
      p: { component: Themed.p },
      ol: {component: Themed.ol },
      ul: {component: Themed.ul },
      li: { component: Themed.li },
      ...overrides,
    },
    ...options,
  };

    return (
      <Markdown
        options={componentOptions}
        sx={styles && { ...styles}}
      >
        {children}
      </Markdown>
    )

};

export default MarkdownText;
 