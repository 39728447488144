/**
 * @file creates the spacing context values and types for the Spacing Context
 */

import { createContext } from 'react';
import { DefaultSpace } from '../constants/page';

export interface Spacing {
  readonly pageSpacing: string;
  readonly heroSpacing: boolean;
  readonly footerSpacing: boolean;
}

const SpacingContext = createContext<Spacing>({
  pageSpacing: DefaultSpace.PAGE,
  heroSpacing: DefaultSpace.HERO,
  footerSpacing: DefaultSpace.FOOTER,
});

export { SpacingContext };
