import PaneButton from './PaneButton';
import NavCta from './CTA';
import { useStyles } from '../nav.styles';

import type { FC } from 'react';
import type { GroupProps as Props, GroupContent } from '../types';
import type { NavStyles } from '../nav.styles';

const Group: FC<Props> = ({
  heading,
  copy,
  contentCollection,
  toggleFunc,
  parent,
  currentPage,
}) => {
  const Styles: NavStyles = useStyles({});
  const links: GroupContent = contentCollection.items || [];

  return (
    <div sx={Styles.GROUP}>
      <h4>
        <span>{heading}</span>
      </h4>
      {copy && <p>{copy}</p>}
      {!!links.length &&
        links.map((link) => {
          switch (link.__typename) {
            case 'Cta': {
              return (
                <NavCta
                  {...link}
                  currentPage={currentPage}
                  parent={parent}
                  group
                  key={link.sys.id}
                />
              );
            }
            case 'SecondaryNavPane': {
              return (
                <PaneButton
                  {...link}
                  parent={parent}
                  key={link.sys.id}
                  onClick={toggleFunc}
                />
              );
            }
            default:
              null;
          }
        })}
    </div>
  );
};

export default Group;
