import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = (): typeof Styles => {
  const Styles = {
    ROOT: {
      counterReset: 'section',
      listStyleType: 'decimal',
      '& li': {
        listStyle: 'none',
        position: 'relative',
      },
      '& li::before': {
        display: 'inline-block',
        content: 'counter(section, decimal) ". "',
        fontWeight: 'bold',
        counterIncrement: 'section',
        position: 'absolute',
        left: '-1.4em',
        marginBlockStart: '.15em',
      },
      ul: {
        listStyleType: 'lower-alpha',
        '& li': {
          listStyle: 'none',
          position: 'relative',
        },
        '& li::before': {
          display: 'inline-block',
          content: '"("counter(section, lower-alpha) ") "',
          fontWeight: 'regular',
          counterIncrement: 'section',
          position: 'absolute',
          left: '-1.4em',
          marginBlockStart: '.15em',
        },
      },
    } as TSO,
  };

  return Styles;
};
