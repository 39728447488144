/**
 * @file - Creates a function that will remove the listed non-valid
 * HTML attributes for spreading props.
 */

interface MutableBase {
  __typename?: unknown;
  sys?: unknown;
  contentfulMetadata?: unknown;
  appleButton?: unknown;
  googleButton?: unknown;
}

const useSanitizer = <T>(
  props: T & MutableBase
): Omit<T, keyof MutableBase> => {
  const attrs = { ...props };

  delete attrs.__typename;
  delete attrs.sys;
  delete attrs.contentfulMetadata;
  delete attrs.appleButton;
  delete attrs.googleButton;

  return attrs;
};

export { useSanitizer };
