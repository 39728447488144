export const Texture = Object.freeze({
  TEXTURE_A: 'textureA',
  TEXTURE_B: 'textureB',
  NO_TEXTURE: 'No Texture',
} as const);

export const TextureBottom = Object.freeze({
  'textureA': ['-22px', '-39px'],
  'textureB': ['-10px','-13px'],
  'No Texture': '0px',
} as const);

export const TextureWidth = Object.freeze({
  'textureA': ['290px', '513px'],
  'textureB': ['375px', '513px'],
  'No Texture': '0px',
} as const);

export const TEXTURE_VARIANTS = Object.values(Texture);

export type Textures = typeof TEXTURE_VARIANTS[number];
